import * as angular from 'angular';

import { NutrientService } from '@services/nutrients/nutrient.service';
import { AccountConstants } from '../AccountConstants';
import { PermissionService } from '@services/permission.service';
import { overrideOption } from '@common/enums';
import { BaseController } from 'src/app/base.controller';

export class ApplyFertiliserDialogController extends BaseController {
  private _mdDialog: angular.material.IDialogService;
  private _nutrientService: NutrientService;

  public selectedSiteWeeks: fuse.siteWeek[];
  public siteWeekCount: number;
  public weekCount: number;
  public type: string;
  public applyFertiliserMethods = AccountConstants.applyFertiliserMethods;
  public applyMethod: string;
  private _overrideDict = {
    Retained: overrideOption.retain,
    Overwritten: overrideOption.override,
    Added: overrideOption.add,
  };

  constructor(
    $mdDialog: angular.material.IDialogService,
    $scope: angular.IScope,
    NutrientService: NutrientService,
    PermissionService: PermissionService,
    siteWeekCount: number,
    weekCount: number,
    type: string,
    selectedSiteWeeks: fuse.siteWeek[],
  ) {
    super(
      $scope,
      PermissionService,
    );

    this._mdDialog = $mdDialog;
    this._nutrientService = NutrientService;

    this.siteWeekCount = siteWeekCount;
    this.weekCount = weekCount;
    this.type = type;
    this.selectedSiteWeeks = selectedSiteWeeks;
  }

  public closeDialog() {
    this._mdDialog.hide();
  }

  public apply(): void {
    this._nutrientService.applyPlannedToApplied(this.selectedSiteWeeks, this._overrideDict[this.applyMethod]).then(() => {
      this._mdDialog.hide(true);
    });
  }
}

angular.module('app.account').controller('AppplyFertiliserController', ApplyFertiliserDialogController);
