import * as angular from 'angular';
import { ColourSchemeService } from '@services/colour-scheme.service';
import { LanguageService } from '@services/language.service';
import { PermissionService } from '@services/permission.service';
import { IDropListItem, healthIndexColourScheme } from '@common/models/interfaces';
import { BaseController } from 'src/app/base.controller';

export class ColourSchemeDialogController extends BaseController {
  private _mdDialog: angular.material.IDialogService;
  private _colourSchemeService: ColourSchemeService;
  private _languageService: LanguageService;

  private _indexList: fuse.dashboardDataInputDto[];
  public _selectedIndex: string;
  public frmColourSchemes: angular.IFormController;

  private hasChanges: boolean = false;

  public colourOptions: IDropListItem[];

  constructor(
    $mdDialog: angular.material.IDialogService,
    $scope: angular.IScope,
    PermissionService: PermissionService,
    ColourSchemeService: ColourSchemeService,
    LanguageService: LanguageService,
    indexList: fuse.dashboardDataInputDto[],
    selectedIndex: string,
  ) {
    super(
      $scope,
      PermissionService,
    );

    this._mdDialog = $mdDialog;
    this._colourSchemeService = ColourSchemeService;
    this._languageService = LanguageService;
    this._indexList = indexList;
    this._selectedIndex = selectedIndex;
    this.colourOptions = [
      { id: 0, name: this._languageService.instant('COMMON.DEFAULT') },
      { id: 3, name: '3' },
      { id: 4, name: '4' },
      { id: 5, name: '5' },
      { id: 6, name: '6' },
      { id: 7, name: '7' },
      { id: 8, name: '8' },
      { id: 9, name: '9' },
      { id: 10, name: '10' },
    ];
  }

  public rebuildColourSchemes() {
    this._colourSchemeService.createHealthThemeCSS();
  }

  public paddingChanged(item: healthIndexColourScheme) {
    this.setChanged(item);
    this.rebuildColourThemes();
  }

  public reverseChanged(item: healthIndexColourScheme) {
    this.setChanged(item);
    this.rebuildColourThemes();
  }

  public numberOfColoursChanged(item: healthIndexColourScheme) {
    if (!isNaN(item.numberOfColours)) {
      item.numberOfColours = Number(item.numberOfColours);
    }
    this.setChanged(item);
    this.rebuildColourThemes();
  }

  public colourPaletteChanged(item: healthIndexColourScheme, palette: string) {
    item.palette = palette;
    this.setChanged(item);
    this.rebuildColourThemes();
  }

  public resetColourScheme(item: healthIndexColourScheme) {
    this._colourSchemeService.resetHealthTheme(item.healthIndex);
    this.rebuildColourThemes();
    this.hasChanges = true;
  }

  private setChanged(item: healthIndexColourScheme) {
    item.useDefault = false;
    this.hasChanges = true;
  }

  private rebuildColourThemes() {
    this._colourSchemeService.createHealthThemeCSS();
  }

  public closeDialog(applyChanges?: boolean) {
    if (applyChanges) {
      this._colourSchemeService.saveHealthThemes();
      this._mdDialog.hide(this.hasChanges);
    } else {
      if (this.hasChanges) {
        this._colourSchemeService.initialiseHealthThemes(this._indexList.map((i) => i.dataType));
        this._colourSchemeService.createHealthThemeCSS();
      }
      this._mdDialog.hide();
    }
  }
}

angular.module('app.account').controller('ColourSchemeDialogController', ColourSchemeDialogController);
