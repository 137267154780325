import * as angular from 'angular';
import * as moment from 'moment';
import * as $ from 'jquery';
import { StatusEnum } from '@indicina/swan-shared/enums/StatusEnum';
import { ArrayUtils } from '@indicina/swan-shared/utils/ArrayUtils';
import { DateUtils } from '@indicina/swan-shared/utils/DateUtils';
import { NumberUtils } from '@indicina/swan-shared/utils/NumberUtils';
import { PerformanceUtils } from '@indicina/swan-shared/utils/PerformanceUtils';
import { LocalStorageUtils } from '@indicina/swan-shared/utils/LocalStorageUtils';
import { SWANConstants } from '@common/SWANConstants';
import { UnitTypes, unitSizes } from '@common/enums';
import { CommonHelper } from '@common/helpers/CommonHelper';
import { UiHelper } from '@common/helpers/UiHelper';
import {
  IDashboardParams,
  IOverlayOpacity,
  healthIndexColourScheme,
  NutrientDashboard,
} from '@common/models/interfaces';
import { ColourSetService } from '@services/colour-set.service';
import { ColourSchemeService } from '@services/colour-scheme.service';
import { DayNumberService } from '@services/day-number.service';
import { EquipmentService } from '@services/equipment.service';
import { HealthIndexService, ImageInfo } from '@services/health-index.service';
import { LanguageService } from '@services/language.service';
import { LocalStorageService } from '@services/local-storage.service';
import { MapService } from '@services/map.service';
import { PermissionService, AccountModel } from '@services/permission.service';
import { UnitOfMeasureService, uomUnit } from '@services/unit-of-measure.service';
import { BaseController } from 'src/app/base.controller';
import { ColourSchemeDialogController } from './colour-scheme-dialog.controller';
import { DashboardHelpDialogController } from './dashboard-help-dialog.controller';

interface ISiteShape {
  autoClosePromise?: number;
  polygon: google.maps.Polygon;
  infoWindow: google.maps.InfoWindow;
  siteId: number;
  siteName: string;
}

interface ISiteMarker {
  siteId: number;
  siteName: string;
  status: string;
  latitude: number;
  longitude: number;
  cropType: string;
  irrigationPlanId: number;
  waterRunningBudget: number;
  waterRunningActual: number;
  soilMoistureStates: string[];
  marker: google.maps.Marker;
}

interface ISensorShape {
  autoClosePromise?: number;
  polygon: google.maps.Polygon;
  infoWindow: google.maps.InfoWindow;
  sensorId: number;
  sensorName: string;
  siteId: number;
}

interface ISensorMarker {
  assetClass: string;
  date: Date;
  latitude: number;
  longitude: number;
  marker: google.maps.Marker;
  reading: number;
  readingQuality: number;
  sensorId: number;
  sensorName: string;
  siteId: number;
  timezoneId: string;
}

interface IHealthChartDataSets {
  Healths: fuse.dashboardHealthChartItem[];
  FilteredHealths: fuse.dashboardHealthChartItem[];
}

interface IHealthIndexCsv {
  date: string;
  Provider: string;
  value: number;
  variability: number;
  couldCover: number;
}

interface IHealthIndexReadings {
  healthIndex: string;
  startDayNumber: number;
  endDayNumber: number;
  healthReadings: fuse.dashboardAssetObsHealthIndexDto[];
}

interface ISiteAndSensor {
  assetClassName: string;
  assetId: number;
  assetName: string;
  infoWindow: google.maps.InfoWindow;
  latitude: number;
  longitude: number;
  status: string;
}

interface IImageDate {
  provider: string;
  date: Date;
  imageDate: Date;
}

export class DashboardController extends BaseController {
  private _http: angular.IHttpService;
  private _mdDialog: angular.material.IDialogService;
  private _mdSidenav: angular.material.ISidenavService;
  private _q: angular.IQService;
  private _rootScope: angular.IRootScopeService;
  private _sce: angular.ISCEService;
  private _state: angular.ui.IStateService;
  private _timeout: angular.ITimeoutService;
  private _colourSchemeService: ColourSchemeService;
  private _colourSetService: ColourSetService;
  private _equipmentService: EquipmentService;
  private _dayNumberService: DayNumberService;
  private _healthIndexService: HealthIndexService;
  private _languageService: LanguageService;
  private _localStorageService: LocalStorageService;
  private _mapService: MapService;
  private _unitOfMeasureService: UnitOfMeasureService;

  private ngisDashboardSummary: fuse.dashboardModel;
  public siteSummaryInfo: fuse.dashboardSiteCardInfo;
  public groupSummaryInfo: fuse.dashboardGroupInfo;
  public healthSummaryInfo: fuse.dashboardHealthInfo;
  public waterUsageSummaryInfo: fuse.dashboardWaterUsageInfo;
  private soilMoistureChart: AmCharts.AmSerialChart;
  private soilMoistureChartOption: AmCharts.AmSerialChart;
  private healthChartOption: AmCharts.AmStockChart;
  public siteIrrigationInfos: fuse.siteIrrigationInfoDto[];
  public dashboardAccountInfo: fuse.dashboardAccountInfoDto;
  private httpCanceller: angular.IDeferred<any>;

  private map: google.maps.Map;
  private drawingManager: google.maps.drawing.DrawingManager;
  private drawings: (
    | google.maps.Marker
    | google.maps.Polygon
    | google.maps.Polyline
    | google.maps.Rectangle
    | google.maps.Circle
  )[] = [];
  private infoWindow: google.maps.InfoWindow;
  private drawing: boolean;
  private healthMarkers = [] as ISensorMarker[];
  private healthShapes = [] as ISensorShape[];
  private siteShapes = [] as ISiteShape[];
  public healthChartSensorName: string;
  private siteMarkers = [] as ISiteMarker[];
  public accounts: AccountModel[];

  public infoModal: fuse.dashboardInfoModal;
  public maximumCloudCover: number;
  public overlayTypes = {
    None: { value: 'N', description: 'PROJ.MAP.OVERLAY_NONE' },
    SiteHealth: { value: 'H', description: 'COMMON.SITE_HEALTH' },
    TemporalVariation: { value: 'T', description: 'PROJ.MAP.OVERLAY_TEMPORAL' },
    ProviderRGB: { value: 'R', description: 'PROJ.MAP.OVERLAY_VISIBLE' },
  };

  private eeLayer: google.maps.ImageMapType = null;
  public dashboardParams: IDashboardParams;
  public ndviLayerInfo: string;
  public imageInfo: string;
  public provider: string;
  public overlayType: string;
  public overlayOpacity: number;

  public siteHealthDate: Date;
  public maxSiteHealthDate: Date;
  public minSiteHealthDate: Date;
  public healthIndexMaximumAge: number = 12;
  public siteHealthCompareDate: Date;
  private dateSearchDuration: number = 90;
  public healthIconDate: Date;

  private _datePickerPanes: NodeListOf<Element>;
  private mapHealthReadings: IHealthIndexReadings[] = [];

  private soilMoistureStartDayNumber: number;
  private soilMoistureEndDayNumber: number;
  private soilMoisture: fuse.dashboardAssetSoilMoistureDto[] = [];
  public totalArea: number;
  public total_irrigated_area: number;
  private healthLayoutTimer: angular.IPromise<void>;

  private soilMoistureDataProvider: fuse.dashboardSoilMoistureItem[];
  public yesterdaySoilMoistureStatus = {} as fuse.dashboardSoilMoistureItem;
  public weatherSummary: fuse.dashboardWeatherItem[];
  private waterUsageSummary: fuse.dashboardWaterUsageItem[];
  private healthChartDataSets: IHealthChartDataSets;
  private accountSites: fuse.dashboardSiteInfoDto[];
  public filteredSites: fuse.dashboardSiteInfoDto[];
  private accountSensors: fuse.dashboardEquipmentDto[];
  public filteredSensors: fuse.dashboardEquipmentDto[];
  public dashboardWaterUsages: fuse.dashboardWaterUsageDto[];

  public isSiteHealthShown: boolean;
  public healthChartStartDate: Date;
  private healthChartStartDayNumber: number;
  public healthChartEndDate: Date;
  private healthChartEndDayNumber: number;
  public healthChartMaxDate: Date;
  private assetObsHealthIndexes: fuse.dashboardAssetObsHealthIndexDto[];
  private currentYear: number;
  private healthChart: AmCharts.AmStockChart;
  private healthChartReadingMax: number;
  private healthChartVariabilityMax: number;
  public healthChartSubscriptionIndexes = [] as fuse.dashboardSubscriptionYearDto[];
  private healthChartSubscriptionIndexesCopy = [] as fuse.dashboardSubscriptionYearDto[];
  public isSubscriptionYearListboxOpen = false;
  public isSearchSiteListboxOpen = false;
  public currentSensorId: number;
  private healthChartZoomStartDate: Date;
  private healthChartZoomEndDate: Date;
  public healthIndexCsv = [] as IHealthIndexCsv[];
  public healthIndexCsvFileName: string;
  public dashboardDataInputs: fuse.dashboardAssetIndexYearDto[];
  public dashboardHealthIndexSubscriptions: fuse.dashboardDataProviderSubscriptionDto[];
  private indexYears: fuse.dashboardAssetIndexYearDto[];
  private lastInfoWindowId: number;

  public adjustedTodayDayNumber: number;
  public siteAndSensors = [] as ISiteAndSensor[];
  public currentAsset: ISiteAndSensor;
  public healthIndexTooltip: string;
  public isHealthIndexTableShown = false;
  public isSingleHealthSource = false;
  public healthIndexTableDataSource = [] as fuse.dashboardAssetObsHealthIndexDto[];
  public isTableHealthIndexChanged = false;
  public isNdmiAvailable = false;
  public areaNormalUnit: uomUnit;
  public weightAreaUnit: uomUnit;
  public acknowledgementMsg = '';

  private imageDates: IImageDate[] = [];
  private loadedProvider = '';
  private loadedImageDateString = '';
  private isHealthIndexInitialView = false;

  public refreshCount = 0;
  public waterUsageChart: any;
  public healthReadings: any[];
  public nutrients: any; // NutrientDashboard;
  public currentAccountName: string;

  constructor(
    $http: angular.IHttpService,
    $mdDialog: angular.material.IDialogService,
    $mdSidenav: angular.material.ISidenavService,
    $q: angular.IQService,
    $rootScope: angular.IRootScopeService,
    $sce: angular.ISCEService,
    $scope: angular.IScope,
    $state: angular.ui.IStateService,
    $timeout: angular.ITimeoutService,
    ColourSchemeService: ColourSchemeService,
    ColourSetService: ColourSetService,
    DayNumberService: DayNumberService,
    EquipmentService: EquipmentService,
    HealthIndexService: HealthIndexService,
    LanguageService: LanguageService,
    LocalStorageService: LocalStorageService,
    MapService: MapService,
    PermissionService: PermissionService,
    UnitOfMeasureService: UnitOfMeasureService,
  ) {
    super(
      $scope,
      PermissionService,
    );

    this._http = $http;
    this._mdDialog = $mdDialog;
    this._mdSidenav = $mdSidenav;
    this._q = $q;
    this._rootScope = $rootScope;
    this._sce = $sce;
    this._state = $state;
    this._timeout = $timeout;
    this._colourSchemeService = ColourSchemeService;
    this._colourSetService = ColourSetService;
    this._dayNumberService = DayNumberService;
    this._equipmentService = EquipmentService;
    this._healthIndexService = HealthIndexService;
    this._languageService = LanguageService;
    this._localStorageService = LocalStorageService;
    this._mapService = MapService;
    this._unitOfMeasureService = UnitOfMeasureService;

    angular.element(window).on('click', (event) => {
      const $target = $(event.target);

      if ($target.closest('.select-dropdown-button').length) {
        //if click happed on the drop down button, do nothing
        return;
      }

      if ($target.closest('.subscription-year-listbox').length) {
        // click happened inside list box, do not clost list box
      } else {
        //if list box is opened and an click happened outside, close list box
        if (this.isSubscriptionYearListboxOpen) {
          this.isSubscriptionYearListboxOpen = false;
          $scope.$apply();
        } else {
          // list box is NOT open, do nothing
        }
      }
    });

    $scope.$on('accountChanged', this.loadAccount.bind(this));

    $scope.$on('$destroy', () => {
      angular.element(window).off('click');

      if (this.httpCanceller) {
        this.httpCanceller.resolve('User left \'Dashboard\' page');
      }
    });
  }

  private get googleMapContainer() {
    return document.getElementById('dashboard-google-map');
  }

  $onInit() {
    this.areaNormalUnit = this._unitOfMeasureService.getUnits('Area');
    this.weightAreaUnit = this._unitOfMeasureService.getUnits(UnitTypes.WeightArea);

    this.getDashboardParams();
    this.setHealthInitialViewBool();

    this.loadAccount();
  }

  public getHealthIndexCsvHdr() {
    return [
      this._languageService.instant('COMMON.DATE'),
      this._languageService.instant('COMMON.PROVIDER'),
      this._languageService.instant('COMMON.VALUE'),
      this._languageService.instant('COMMON.VARIABILITY'),
      this._languageService.instant('COMMON.CLOUD_COVER_PCT'),
    ];
  }

  // Used by GroupsListComponent on changing parent group
  public loadGroupData() {
    this.siteAndSensors = [];
    this.scope.$emit('whatAccount');
  }

  private getDashboardParams() {
    this.dashboardParams = this._localStorageService.get('dashboardParams') as IDashboardParams;
    const stuff = SWANConstants.dataInputs;
    const indexNDVI = stuff.filter((rows: { dataType: string }) => rows.dataType === 'NDVI')[0].id;

    if (!this.dashboardParams) {
      this.dashboardParams = {
        archivedSites: 'Hide',
        siteShapeType: 'Outline',
        siteHealthShapeType: 'Hide',
        healthQuality: 0,
        overlayOpacity: [],
        overlayType: this.overlayTypes.None.value,
        minimumHealthQuality: 0.8,
        mapHealthIndex: 'NDVI',
        healthIndexCloudValue: 100,
        healthIndexProvider: 'Sentinel-2',
        healthIndexColourSchemes: [],
        chartHealthIndex: indexNDVI,
        baseMapType: 'Terrain',
        clipToShape: 'No',
        weatherFormat: 'chart',
        waterUsageAsRate: false,
      } as IDashboardParams;

      this._localStorageService.set('dashboardParams', this.dashboardParams);
    } else {
      if (!this.dashboardParams.archivedSites) {
        this.dashboardParams.archivedSites = 'Hide';
      }

      if (!this.dashboardParams.siteShapeType) {
        this.dashboardParams.siteShapeType = 'Outline';
      }

      if (!this.dashboardParams.siteHealthShapeType) {
        this.dashboardParams.siteHealthShapeType = 'Hide';
      }

      if (this.dashboardParams.healthIndexCloudValue == null) {
        this.dashboardParams.healthIndexCloudValue = 100;
      }

      if (!this.dashboardParams.healthIndexColourSchemes) {
        this.dashboardParams.healthIndexColourSchemes = [];
      }

      if (!this.dashboardParams.healthIndexProvider) {
        this.dashboardParams.healthIndexProvider = 'Sentinel-2';
      }

      if (!this.dashboardParams.overlayOpacity) {
        this.dashboardParams.overlayOpacity = [];
      }

      if (!this.dashboardParams.overlayType) {
        this.dashboardParams.overlayType = this.overlayTypes.None.value;
      }

      if (this.dashboardParams.minimumHealthQuality == null) {
        this.dashboardParams.minimumHealthQuality = 0.8;
      }

      if (!this.dashboardParams.mapHealthIndex) {
        this.dashboardParams.mapHealthIndex = 'NDVI';
      }

      if (this.dashboardParams.chartHealthIndex == null) {
        this.dashboardParams.chartHealthIndex = indexNDVI;
      }

      if (!this.dashboardParams.baseMapType) {
        this.dashboardParams.baseMapType = 'Terrain';
      }

      if (!this.dashboardParams.clipToShape) {
        this.dashboardParams.clipToShape = 'No';
      }

      if (!this.dashboardParams.weatherFormat) {
        this.dashboardParams.weatherFormat = 'chart';
      }

      this.dashboardParams.waterUsageAsRate = this.dashboardParams.waterUsageAsRate ?? false;
      this._localStorageService.set('dashboardParams', this.dashboardParams);
    }

    if (angular.isNumber(this.dashboardParams.overlayOpacity)) {
      this.dashboardParams.overlayOpacity = [];
    }

    this.maximumCloudCover = 1 - this.dashboardParams.minimumHealthQuality;
    this.overlayType = this.dashboardParams.overlayType;
    this.overlayOpacity = this.getOverlayOpactity();
    this.overlayTypes.ProviderRGB.description = this.dashboardParams.healthIndexProvider + ' ' + this._languageService.instant('COMMON.VISIBLE');
    this.overlayTypes.None.description =
      this.dashboardParams.siteShapeType == 'Outline'
        ? this._languageService.instant('COMMON.SOIL_MOISTURE_BALANCE')
        : this._languageService.instant('PROJ.MAP.OVERLAY_NONE');
    this.overlayTypes.SiteHealth.description = this._languageService.instant(this.overlayTypes.SiteHealth.description);
    this.overlayTypes.TemporalVariation.description = this._languageService.instant(this.overlayTypes.TemporalVariation.description);   
    this.dashboardParams.siteShapeType;
  }

  private resetOverlay(resetHealthDates: boolean) {
    this.imageInfo = '';
    this.ndviLayerInfo = '';
    this.provider = null;

    if (resetHealthDates) {
      this.siteHealthDate = this.accountYesterday();
    }

    this.siteHealthCompareDate = moment(this.siteHealthDate).add(-1, 'month').toDate();
    this.soilMoistureStartDayNumber = null;
    this.soilMoistureEndDayNumber = null;

    this.clearHealthIndexLayer();
    this.setHealthDate();
  }

  private accountYesterday(): Date {
    const account = this.permissionService.currentAccount;
    const todayNumber = this._dayNumberService.convertDateToLocaleDayNumber(new Date(), account.timezoneId);
    const yesterdayDate = this._dayNumberService.convertDayNumberToLocaleDate(todayNumber - 1);

    return yesterdayDate;
  }

  public mapHealthIndexChanged() {
    this._localStorageService.set('dashboardParams', this.dashboardParams);
    this.soilMoistureStartDayNumber = null;
    this.soilMoistureEndDayNumber = null;
    this.setHealthDate();

    if (this.dashboardParams.mapHealthIndex == 'NDMI' && this.dashboardParams.healthIndexProvider == 'Planet') {
      this._languageService.warning('PROJ.IMAGERY_NO_DATA', 'COMMON.NO_DATA_AVAILABLE', {
        provider: this.dashboardParams.healthIndexProvider,
        index: this.dashboardParams.mapHealthIndex,
      });
    }

    this.healthMarkers.forEach((marker) => {
      marker.marker.setTitle(`${this.dashboardParams.mapHealthIndex}: ${marker.sensorName}`);
    });

    this.healthShapes.forEach((shape) => {
      shape.infoWindow.setContent(`${this.dashboardParams.mapHealthIndex}: ${shape.sensorName}`);
    });

    this.healthIndexTooltip = this.getHealthIndexTooltip(this.dashboardParams.mapHealthIndex);
  }

  public healthProviderChanged() {
    this.provider = null;
    this.mapHealthReadings = [];
    this.overlayTypes.ProviderRGB.description = this.dashboardParams.healthIndexProvider + ' ' + this._languageService.instant('COMMON.VISIBLE');

    this.mapHealthIndexChanged();
  }

  public setHealthIndexLayer() {
    const mapZoom = this.map.getZoom();

    try {
      if (
        this.overlayType == this.overlayTypes.SiteHealth.value ||
        this.overlayType == this.overlayTypes.TemporalVariation.value ||
        this.overlayType == this.overlayTypes.ProviderRGB.value
      ) {
        if (this.map.getCenter()) {
          let requestedProvider = this.dashboardParams.healthIndexProvider;
          if (requestedProvider != 'Landsat-8' && mapZoom < 11) {
            requestedProvider = 'Landsat-8';
            if (this.provider != requestedProvider) {
              this._languageService.warning('PROJ.IMAGERY_SWITCH', 'PROJ.IMAGERY_SWITCH_TITLE', {
                provider: this.dashboardParams.healthIndexProvider,
              });
            }
          }
          this.provider = requestedProvider;
          this.displayHealthIndexMapLayer(requestedProvider);
        }
      } else if (this.overlayType == this.overlayTypes.None.value) {
        this.imageInfo = this.dashboardParams.healthIndexProvider + ' | ' + this.dashboardParams.mapHealthIndex;
      }
    } catch (err) {
      console.log('token expired?');
    }
  }

  public openMapSettingsModal() {
    this.closeHealthChartModal();
    this._mdSidenav('infoModal').close();
    this.closeMapToolsModal();
    this._mdSidenav('mapSettingsModal').toggle();
  }

  public openMapToolsModal() {
    this.closeHealthChartModal();
    this._mdSidenav('infoModal').close();
    this._mdSidenav('mapSettingsModal').close();
    this._mdSidenav('mapToolsModal').toggle();
  }

  public openThemeSettingsModal(event) {
    let index = this.dashboardParams.mapHealthIndex;

    if (this.overlayType == this.overlayTypes.TemporalVariation.value) {
      index = 'TEMPORALVARIATION';
    }

    this._mdDialog.show({
      controller: ColourSchemeDialogController,
      controllerAs: 'vm',
      templateUrl: 'src/app/pages/account/views/dashboard/colour-scheme-dialog.html',
      targetEvent: event,
      locals: { indexList: this.dashboardAccountInfo.dashboardDataInputs, selectedIndex: index },
      parent: this.googleMapContainer.children[0],
      clickOutsideToClose: false,
      escapeToClose: false,
    }).then((refresh: boolean) => {
      if (refresh) {
        const params = this._localStorageService.get('dashboardParams') as IDashboardParams;

        if (params) {
          this.dashboardParams = params;
          this.setNdviTheme();
        }
      }
    });
  }

  public closeMapSettingsModal() {
    this._mdSidenav('mapSettingsModal').close();
  }

  public closeMapToolsModal() {
    this.drawingManager.setMap(null);
    this.drawing = false;
    this.clearMapToolShapes();
    this._mdSidenav('mapToolsModal').close();
  }

  public clearMapToolShapes() {
    this.drawings.forEach((d) => {
      d.setMap(null);
    });

    this.infoWindow.close();
    this.drawings = [];
  }

  private drawShape(selection: string) {
    this.drawingManager.setMap(this.map);
    this.drawing = true;

    switch (selection) {
      case 'CIRCLE':
        this.drawingManager.setDrawingMode(google.maps.drawing.OverlayType.CIRCLE);
        break;

      case 'LINE':
        this.drawingManager.setDrawingMode(google.maps.drawing.OverlayType.POLYLINE);
        break;

      case 'POLYGON':
        this.drawingManager.setDrawingMode(google.maps.drawing.OverlayType.POLYGON);
        break;
    }
  }

  private drawingComplete(overlayCompleteEvent: google.maps.drawing.OverlayCompleteEvent) {
    this.drawing = false;
    this.drawingManager.setDrawingMode(null);

    const mapOverlay = overlayCompleteEvent.overlay;

    switch (overlayCompleteEvent.type) {
      case google.maps.drawing.OverlayType.POLYGON:
        const polygon = mapOverlay as google.maps.Polygon;
        polygon.setEditable(true);
        break;
      case google.maps.drawing.OverlayType.CIRCLE:
        const circle = mapOverlay as google.maps.Circle;
        circle.setEditable(true);
        break;
      case google.maps.drawing.OverlayType.POLYLINE:
        const polyline = mapOverlay as google.maps.Polyline;
        polyline.setEditable(true);
        break;
    }

    google.maps.event.addListener(mapOverlay, 'mouseover', (mouseOverEvent) => {
      this.showDrawingInfo(mouseOverEvent, overlayCompleteEvent);
    });

    google.maps.event.addListener(mapOverlay, 'mouseout', () => {
      this.infoWindow.close();
    });

    this.drawings.push(mapOverlay);
  }

  private showDrawingInfo(
    mouseOverEvent: google.maps.MapMouseEvent,
    overlayCompleteEvent: google.maps.drawing.OverlayCompleteEvent,
  ) {
    const drawingType = overlayCompleteEvent.type;
    const drawing = overlayCompleteEvent.overlay;

    let content = '';

    switch (drawingType) {
      case google.maps.drawing.OverlayType.POLYGON:
        const polygon = drawing as google.maps.Polygon;
        const path = polygon.getPath();
        const polygonArea = google.maps.geometry.spherical.computeArea(path.getArray());
        const polygonAreaInBase = polygonArea / 10000; // in ha

        content =
          this._languageService.instant('COMMON.AREA') +
            `:<br>
              ${this._unitOfMeasureService.convertFromBase(
                'Area',
                unitSizes.normal,
                polygonAreaInBase,
              )} ${this._unitOfMeasureService.getUnitLabel('Area', unitSizes.normal)}
              <br>
              ${this._unitOfMeasureService.convertFromBase(
                'Area',
                unitSizes.small,
                polygonAreaInBase,
              )} ${this._unitOfMeasureService.getUnitLabel('Area', unitSizes.small)}`;
        break;

      case google.maps.drawing.OverlayType.POLYLINE:
        const polyline = drawing as google.maps.Polyline;
        const length = google.maps.geometry.spherical.computeLength(polyline.getPath().getArray());
        const lengthInBase = length / 1000; // in km

        content =
          this._languageService.instant('PROJ.MAP.LENGTH') +
            `:<br>
            ${this._unitOfMeasureService.convertFromBase(
              'Length',
              unitSizes.large,
              lengthInBase,
            )} ${this._unitOfMeasureService.getUnitLabel('Length', unitSizes.large)}
            <br>
            ${this._unitOfMeasureService.convertFromBase(
              'Length',
              unitSizes.normal,
              lengthInBase,
            )} ${this._unitOfMeasureService.getUnitLabel('Length', unitSizes.normal)}`;
        break;

      case google.maps.drawing.OverlayType.CIRCLE:
        const circle = drawing as google.maps.Circle;
        const rad = circle.getRadius();
        const circleArea = rad * rad * Math.PI;
        const circleAreaInBase = circleArea / 10000; // in ha

        content =
          this._languageService.instant('COMMON.AREA') +
            `:<br>
                ${this._unitOfMeasureService.convertFromBase(
                  'Area',
                  unitSizes.normal,
                  circleAreaInBase,
                )} ${this._unitOfMeasureService.getUnitLabel('Area', unitSizes.normal)}
                <br>
                ${this._unitOfMeasureService.convertFromBase(
                  'Area',
                  unitSizes.small,
                  circleAreaInBase,
                )} ${this._unitOfMeasureService.getUnitLabel('Area', unitSizes.small)}`;
        break;
    }

    this.infoWindow.setContent(content);
    this.infoWindow.setPosition(mouseOverEvent.latLng);
    this.infoWindow.open(this.map);
  }

  private showInfoModal() {
    this._mdSidenav('infoModal').open();
  }

  public closeInfoModal() {
    this._mdSidenav('infoModal').close();
  }

  private initMap() {
    const configureCustomMapControls = (): void => {
      const addControl = (position: google.maps.ControlPosition, elementId: string): void => {
        const element = document.getElementById(elementId);

        if (element) {
          this.map.controls[position].push(element);
        }
      };

      addControl(google.maps.ControlPosition.RIGHT_TOP, 'btnMapSettings');
      addControl(google.maps.ControlPosition.RIGHT_TOP, 'btnMapTools');
      addControl(google.maps.ControlPosition.RIGHT_TOP, 'btnSearch');
      addControl(google.maps.ControlPosition.RIGHT_TOP, 'btnZoomToAccount');
      addControl(google.maps.ControlPosition.RIGHT_TOP, 'mapSearchSiteModal');

      addControl(google.maps.ControlPosition.TOP_LEFT, 'healthChartModal');
      addControl(google.maps.ControlPosition.TOP_LEFT, 'infoModal');
      addControl(google.maps.ControlPosition.TOP_LEFT, 'mapSettingsModal');
      addControl(google.maps.ControlPosition.TOP_LEFT, 'mapToolsModal');

      addControl(google.maps.ControlPosition.TOP_RIGHT, 'mapInfo');
      addControl(google.maps.ControlPosition.TOP_RIGHT, 'temporalInfo');
    };

    const configureDrawingManager = (): void => {
      this.drawingManager = new google.maps.drawing.DrawingManager({
        drawingControl: false,
        drawingControlOptions: {
          position: google.maps.ControlPosition.TOP_CENTER,
          drawingModes: [
            google.maps.drawing.OverlayType.MARKER,
            google.maps.drawing.OverlayType.POLYGON,
            google.maps.drawing.OverlayType.CIRCLE,
            google.maps.drawing.OverlayType.POLYLINE,
          ],
        },
      });
    };

    const configureInfoWindow = (): void => {
      const labelOptions = {
        boxStyle: {
          textAlign: 'center',
          fontSize: '8pt',
          width: '80px',
        },
        closeBoxURL: '',
        content: '',
        disableAutoPan: false,
        enableEventPropagation: true,
        isHidden: false,
        pane: 'mapPane',
        pixelOffset: new google.maps.Size(0, -10),
      } as google.maps.InfoWindowOptions;

      this.infoWindow = new google.maps.InfoWindow(labelOptions);
    };

    if (!this.map) {
      const mapOptions = {
        cameraControl: false,
        mapTypeControl: false,
        mapTypeId: google.maps.MapTypeId.HYBRID,
        rotateControl: false,
        streetViewControl: false,
        tilt: 0,
        zoom: 2, // If no site, it should display the world view.
        zoomControl: true,
      } as google.maps.MapOptions;

      this.map = new google.maps.Map(this.googleMapContainer, mapOptions);

      configureCustomMapControls();
      configureDrawingManager();
      configureInfoWindow();

      this._datePickerPanes = UiHelper.getMdDatePickerCalendarPanes();
    } else {
      //Force clear layers, to clean the map (e.g., NDIV imagery, etc.).
      this.map.overlayMapTypes.clear();
    }

    this._timeout(() => {
      this.createSiteMarkerAndShapes();
      this.createHealthShapeAndMarkers();
      this.setMapExtentsAndZoom();
      this.setSiteShapes();
      this.setBaseMapType();
      this.setSiteHealthMarkerAndShapes();
      this.addDashboardMapListeners();
      this.overlayTypeChanged(); //This will call all other sitehealth initialisation related functions
      this.mapInitialised();
    }, 100);
  }

  private mapInitialised() {
    if (this._state.params.viewSiteHealth && this._state.params.sensorId && this.filteredSensors) {
      // Open site health pop up.
      if (this.filteredSensors.find((f) => f.id === this._state.params.sensorId)) {
        this.clickHealthMarker(this._state.params.sensorId);
      }
    }
  }

  private setBaseMapType() {
    this._localStorageService.set('dashboardParams', this.dashboardParams);

    switch (this.dashboardParams.baseMapType) {
      case 'Terrain':
        this.map.setMapTypeId(google.maps.MapTypeId.TERRAIN);
        break;
      case 'Satellite':
        this.map.setMapTypeId(google.maps.MapTypeId.HYBRID);
        break;
      case 'Street Map':
        this.map.setMapTypeId(google.maps.MapTypeId.ROADMAP);
        break;
      default:
        this.map.setMapTypeId(google.maps.MapTypeId.SATELLITE);
        break;
    }
  }

  private closeInfoWindows(): void {
    this.siteAndSensors?.forEach((asset) => {
      asset.infoWindow?.close();
    });
  }

  private createHealthShapeAndMarkers() {
    this.healthShapes.forEach((s) => s.polygon.setMap(null));
    this.healthMarkers.forEach((s) => s.marker.setMap(null));

    this.healthShapes = [];
    this.healthMarkers = [];
    this.filteredSensors = this.accountSensors.filter(
      (a) => a.status == StatusEnum.Active && (a.assetclassid == 12 || a.assetclassid == 3),
    );
    this.filteredSensors.forEach((sensor) => {
      const sensorLatlng = new google.maps.LatLng(sensor.latitude, sensor.longitude);
      const marker = new google.maps.Marker({
        position: sensorLatlng,
        title: `${this.dashboardParams.mapHealthIndex}: ${sensor.name}`,
      });
      const sensorMarker = {
        assetClass: sensor.assetClassName,
        latitude: sensor.latitude,
        longitude: sensor.longitude,
        marker: marker,
        sensorId: sensor.id,
        sensorName: sensor.name,
        siteId: sensor.siteId,
        timezoneId: sensor.timezoneId,
      } as ISensorMarker;

      this.healthMarkers.push(sensorMarker);

      if (sensor.shape) {
        const path = [];

        for (let i = 0; i < sensor.shape.length; i++) {
          const item = sensor.shape[i];
          path.push({ lat: item[1], lng: item[0] });
        }

        const polygon = new google.maps.Polygon({
          paths: path,
          strokeWeight: 2,
          strokeColor: 'grey',
          strokeOpacity: 1,
          zIndex: 2,
        });

        const infoWindowOptions = {
          content: `${this.dashboardParams.mapHealthIndex}: ${sensor.name}`,
          boxStyle: {
            textAlign: 'center',
            fontSize: '8pt',
            width: '50px',
          },
          disableAutoPan: true,
          pixelOffset: new google.maps.Size(0, -10),
          position: new google.maps.LatLng(sensor.latitude, sensor.longitude),
          closeBoxURL: '',
          isHidden: false,
          pane: 'mapPane',
          enableEventPropagation: true,
        } as google.maps.InfoWindowOptions;

        const polygonInfoWindow = new google.maps.InfoWindow(infoWindowOptions);
        const sensorShape = {
          sensorId: sensor.id,
          sensorName: sensor.name,
          polygon: polygon,
          infoWindow: polygonInfoWindow,
          siteId: sensor.siteId,
        } as ISensorShape;

        this.healthShapes.push(sensorShape);
        this.siteAndSensors.push({
          assetClassName: 'sensor',
          assetId: sensor.id,
          assetName: sensor.name,
          infoWindow: polygonInfoWindow,
          latitude: sensor.latitude,
          longitude: sensor.longitude,
          status: sensor.status,
        } as ISiteAndSensor);
      } else {
        const labelOptions = {
          content: sensor.name,
          boxStyle: {
            textAlign: 'center',
            fontSize: '8pt',
            width: '50px',
          },
          disableAutoPan: true,
          pixelOffset: new google.maps.Size(0, -10),
          position: new google.maps.LatLng(sensor.latitude, sensor.longitude),
          closeBoxURL: '',
          isHidden: false,
          pane: 'mapPane',
          enableEventPropagation: true,
        } as google.maps.InfoWindowOptions;

        const polygonLabel = new google.maps.InfoWindow(labelOptions);

        this.siteAndSensors.push({
          assetClassName: 'sensor',
          assetId: sensor.id,
          assetName: sensor.name,
          infoWindow: polygonLabel,
          latitude: sensor.latitude,
          longitude: sensor.longitude,
          status: sensor.status,
        } as ISiteAndSensor);
      }
    });
  }

  private addDashboardMapListeners() {
    this.siteShapes.forEach((siteShape) => {
      const siteId = siteShape.siteId;

      google.maps.event.addListener(siteShape.polygon, 'click', () => {
        this.closeInfoWindows();

        this.currentAsset = this.siteAndSensors.find((a) => a.assetId == siteShape.siteId);

        if (!this.drawing) {
          this.siteShapeClick(siteId);
        }
      });

      google.maps.event.addListener(siteShape.polygon, 'mouseover', (event) => {
        this.closeInfoWindows();

        if (this.lastInfoWindowId != siteShape.siteId) {
          this.lastInfoWindowId = null;

          siteShape.infoWindow.setPosition(event.latLng);
          siteShape.infoWindow.open(this.map);

          siteShape.autoClosePromise = window.setTimeout(() => {
            siteShape.autoClosePromise = null;
            siteShape.infoWindow.close();
          }, 2000);
        }
      });

      google.maps.event.addListener(siteShape.polygon, 'mouseout', () => {
        siteShape.infoWindow.close();

        if (siteShape.autoClosePromise) {
          window.clearTimeout(siteShape.autoClosePromise);
          siteShape.autoClosePromise = null;
        }
      });
    });

    this.siteMarkers.forEach((siteMarker) => {
      const siteId = siteMarker.siteId;

      google.maps.event.addListener(siteMarker.marker, 'click', () => {
        this.closeInfoWindows();

        this.currentAsset = this.siteAndSensors.find((a) => a.assetId == siteMarker.siteId);

        if (!this.drawing) {
          this.siteMarkerClick(siteId);
        }
      });
    });

    google.maps.event.addListener(this.map, 'idle', () => {
      const mapZoom = this.map.getZoom();

      if (!this.isHealthIndexInitialView) {
        const accountList = this._localStorageService.accountList;
        const account = accountList.find((x) => x.accountId === this.dashboardAccountInfo.accountId);
        const center = this.map.getCenter();

        account.mapLatitude = center.lat();
        account.mapLongitude = center.lng();
        account.mapZoom = mapZoom;

        this._localStorageService.set('accountList', accountList);
      }

      this.isHealthIndexInitialView = false;

      if (this.eeLayer) {
        let requestedProvider = this.dashboardParams.healthIndexProvider;

        if (requestedProvider != 'Landsat-8' && mapZoom < 11) {
          requestedProvider = 'Landsat-8';

          if (this.provider != requestedProvider) {
            this._languageService.warning('PROJ.IMAGERY_SWITCH', 'PROJ.IMAGERY_SWITCH_TITLE', { provider: this.provider });
          }
        }

        if (this.provider != requestedProvider) {
          if (this.dashboardParams.healthIndexProvider == requestedProvider) {
            this._languageService.success('PROJ.IMAGERY_SWITCHING', 'COMMON.HEALTH_INDEX_PROVIDER', {
              provider: requestedProvider,
            });
          }

          //We need to regenerate the layer
          this.provider = requestedProvider;
          window.setTimeout(() => {
            this.displayHealthIndexMapLayer(requestedProvider);
          }, 500); // window timeout so we don;t lock the map
        }

        this.getHealthOverlayImageInfo(requestedProvider);
      }
    });

    google.maps.event.addListener(this.drawingManager, 'overlaycomplete', (event) => {
      this.drawingComplete(event);
    });

    this.healthMarkers.forEach((healthMarker) => {
      google.maps.event.addListener(healthMarker.marker, 'click', () => {
        if (this.isTableHealthIndexChanged) return;

        this.closeInfoWindows();

        this.currentAsset = this.siteAndSensors.find((a) => a.assetId == healthMarker.sensorId);

        if (!this.drawing) {
          this.clickHealthMarker(healthMarker.sensorId);
        }
      });
    });

    this.healthShapes.forEach((shape) => {
      google.maps.event.addListener(shape.polygon, 'click', () => {
        if (this.isTableHealthIndexChanged) return;

        this.closeInfoWindows();

        this.currentAsset = this.siteAndSensors.find((a) => a.assetId == shape.sensorId);
        if (!this.drawing) {
          this.clickHealthShape(shape.sensorId);
        }
      });

      google.maps.event.addListener(shape.polygon, 'mouseover', (event) => {
        this.closeInfoWindows();

        shape.infoWindow.setPosition(event.latLng);
        shape.infoWindow.open(this.map);
        shape.autoClosePromise = window.setTimeout(() => {
          shape.autoClosePromise = null;
          shape.infoWindow.close();
        }, 2000);
      });

      google.maps.event.addListener(shape.polygon, 'mouseout', () => {
        shape.infoWindow.close();

        if (shape.autoClosePromise) {
          window.clearTimeout(shape.autoClosePromise);
          shape.autoClosePromise = null;
        }
      });
    });
  }

  private loadMapHealthReadings(): angular.IPromise<any> {
    const defer = this._q.defer();
    let loadData = false;

    if (this.dashboardAccountInfo.dashboardDataProviderSubscriptions && this.siteHealthDate) {
      let provider = this.dashboardAccountInfo.dashboardDataProviderSubscriptions.find(
        (s) => s.dataProviderName == this.dashboardParams.healthIndexProvider,
      );

      if (!provider) {
        provider = this.dashboardAccountInfo.dashboardDataProviderSubscriptions.find((s) => s.dataProviderName == 'Sentinel-2');
      }

      let healthIndexReadings = this.mapHealthReadings.find((h) => h.healthIndex == this.dashboardParams.mapHealthIndex);

      if (!healthIndexReadings) {
        healthIndexReadings = {
          healthIndex: this.dashboardParams.mapHealthIndex,
          healthReadings: [],
        } as IHealthIndexReadings;

        this.mapHealthReadings.push(healthIndexReadings);
      }

      const targetDayNumber = this._dayNumberService.convertLocaleDateToLocaleDayNumber(this.siteHealthDate);
      let startDayNumber = healthIndexReadings.startDayNumber;
      let endDayNumber = healthIndexReadings.endDayNumber;
      let fetchFrom: number = 0;
      let fetchTo: number = 0;

      if (startDayNumber == null) {
        //By default load previous 90 days either side (to try and make sure we get at least one reading)
        startDayNumber = targetDayNumber - 90;
        endDayNumber = targetDayNumber + 90;
        fetchFrom = startDayNumber;
        fetchTo = endDayNumber;
        loadData = true;
      } else if (targetDayNumber <= startDayNumber) {
        //If we get within 90 days of our current boundary we should load more data
        //Load 90 Days Previous and 90 Days Ahead of reading either side of the selected date
        fetchTo = targetDayNumber + 90;
        endDayNumber = fetchTo;
        startDayNumber = targetDayNumber - 90;
        fetchFrom = startDayNumber;
        loadData = true;
      } else if (targetDayNumber >= endDayNumber) {
        fetchFrom = targetDayNumber - 90;
        startDayNumber = fetchFrom;
        endDayNumber = targetDayNumber + 90;
        fetchTo = endDayNumber;
        loadData = true;
      }

      if (loadData) {
        this.getHealthIndexReadings(
          fetchFrom,
          fetchTo,
          null,
          provider ? provider.dataProviderId : null,
          healthIndexReadings.healthIndex,
        ).then((response) => {
          const result = response as fuse.dashboardAssetObsHealthIndexDto[];

          result.forEach((a) => {
            a.dateTime = this._dayNumberService.convertDayNumberToLocaleDate(a.dayNumber);
          });

          healthIndexReadings.healthReadings = ArrayUtils.sortByNumber(result, (h) => h.dayNumber, 'desc');
          healthIndexReadings.startDayNumber = startDayNumber;
          healthIndexReadings.endDayNumber = endDayNumber;
          defer.resolve();
        },
        (err) => {
          defer.reject(err);
        });
      } else {
        defer.resolve();
      }
    } else {
      defer.reject('Not all required parameters have been set.');
    }

    return defer.promise;
  }

  private setHealthIconAndShapeColour() {
    this.healthSummaryInfo = { low: 0, onTarget: 0, high: 0, unknown: 0 };

    const daynumber = this._dayNumberService.convertLocaleDateToLocaleDayNumber(this.siteHealthDate);
    const healthIndexReadings = this.mapHealthReadings.find((h) => h.healthIndex == this.dashboardParams.mapHealthIndex);

    if (!healthIndexReadings) {
      return;
    }

    const diHealthReadings = ArrayUtils.sortByNumber(
      healthIndexReadings.healthReadings.filter(
        (h) =>
          this.filteredSensors.find((a) => a.id == h.sensorId && a.status != StatusEnum.Archived) &&
          h.dataProviderName == this.dashboardParams.healthIndexProvider &&
          h.qualityFactor >= 100 - this.dashboardParams.healthIndexCloudValue &&
          h.dayNumber <= daynumber,
      ),
      (h) => h.dayNumber,
      'desc'
    );

    this.healthIconDate = diHealthReadings.length
      ? this._dayNumberService.convertDayNumberToLocaleDate(diHealthReadings[0].dayNumber)
      : null;

    this.healthMarkers.forEach((healthMarker) => {
      const healthSensor = this.filteredSensors.find((a) => a.id == healthMarker.sensorId);

      if (healthSensor != null && healthSensor.status != StatusEnum.Archived) {
        // obsHealthreadings is descending ordered by effectiveTime
        const latestSensorReading = diHealthReadings.find((h) => h.sensorId == healthSensor.id);
        const healthShape = this.healthShapes.find((s) => s.sensorId == healthSensor.id);
        let healthColour = '#FCDA86'; //Yellow
        let icon: string;

        if (!latestSensorReading || latestSensorReading.dateTime < this.minSiteHealthDate) {
          icon = 'assets/icons/mapicons/iGreyUnknown.svg';
          this.healthSummaryInfo.unknown++;
        } else if (latestSensorReading.healthStatus == 1) {
          switch (latestSensorReading.variabilityStatus) {
            case 1:
              icon = 'assets/icons/mapicons/iHighHighVariance.svg';
              break;

            case 0:
              icon = 'assets/icons/mapicons/iHighMediumVariance.svg';
              break;

            default:
              icon = 'assets/icons/mapicons/iHigh.svg';
              break;
          }

          healthColour = '#2A6ABD'; //Blue
          this.healthSummaryInfo.high++;
        } else if (latestSensorReading.healthStatus == -1) {
          switch (latestSensorReading.variabilityStatus) {
            case 1:
              icon = 'assets/icons/mapicons/iLowHighVariance.svg';
              break;
            case 0:
              icon = 'assets/icons/mapicons/iLowMediumVariance.svg';
              break;
            default:
              icon = 'assets/icons/mapicons/iLow.svg';
              break;
          }

          healthColour = '#F7961E'; //Orange
          this.healthSummaryInfo.low++;
        } else {
          // its in Range
          switch (latestSensorReading.variabilityStatus) {
            case 1:
              icon = 'assets/icons/mapicons/iOkHighVariance.svg';
              break;
            case 0:
              icon = 'assets/icons/mapicons/iOkMediumVariance.svg';
              break;
            default:
              icon = 'assets/icons/mapicons/iOk.svg';
              break;
          }

          healthColour = '#3FC92C'; //Green
          this.healthSummaryInfo.onTarget++;
        }

        if (healthShape && healthShape.polygon) {
          healthShape.polygon.setOptions({ fillColor: healthColour });
        }

        if (healthShape && healthShape.infoWindow) {
          //healthShape.polygonLabel.setContent("");
        }

        healthMarker.marker.setIcon(icon);

        if (latestSensorReading) {
          healthMarker.reading = latestSensorReading.reading;
          healthMarker.date = this._dayNumberService.convertDayNumberToDate(latestSensorReading.dayNumber);
          healthMarker.readingQuality = latestSensorReading.qualityFactor;
        }
      }
    });
  }

  private loadSoilMoisture(): angular.IPromise<any> {
    const defer = this._q.defer();
    const targetDayNumber = this._dayNumberService.convertLocaleDateToLocaleDayNumber(this.siteHealthDate);
    let startDayNumber = this.soilMoistureStartDayNumber;
    let endDayNumber = this.soilMoistureEndDayNumber;
    let fetchFrom: number = 0;
    let fetchTo: number = 0;
    let loadData: boolean = false;

    if (startDayNumber == null) {
      //By default, load today and next 6 days...
      startDayNumber = targetDayNumber;
      endDayNumber = targetDayNumber + 6;

      fetchFrom = startDayNumber;
      fetchTo = endDayNumber;
      loadData = true;
    } else if (targetDayNumber <= startDayNumber) {
      //Need to load more readings (6 days forward / 30 days back)
      fetchTo = targetDayNumber + 6;
      endDayNumber = fetchTo;
      startDayNumber = targetDayNumber - 30;
      fetchFrom = startDayNumber;
      loadData = true;
    } else if (targetDayNumber >= endDayNumber) {
      fetchFrom = targetDayNumber - 30;
      startDayNumber = fetchFrom;
      endDayNumber = targetDayNumber + 6;
      fetchTo = endDayNumber;
      loadData = true;
    }

    if (loadData) {
      this.getSoilMoisture(fetchFrom, fetchTo).then((result) => {
        this.soilMoisture = ArrayUtils.sortByNumber(result, (s) => s.dayNumber);
        this.soilMoistureStartDayNumber = startDayNumber;
        this.soilMoistureEndDayNumber = endDayNumber;
        defer.resolve();
      },
      (err) => {
        defer.reject(err);
      });
    } else {
      defer.resolve();
    }

    return defer.promise;
  }

  public setSoilMoistureIconAndShapeColour() {
    const dayNumber = this._dayNumberService.convertLocaleDateToLocaleDayNumber(this.siteHealthDate);

    this.siteMarkers.forEach((siteMarker) => {
      const site = this.accountSites.find((s) => s.siteId == siteMarker.siteId);

      if (site && site.status == StatusEnum.Active) {
        const sm = this.soilMoisture.find((s) => s.assetId == siteMarker.siteId && s.dayNumber == dayNumber);

        let siteColour: string = 'grey';
        let icon: string = 'assets/icons/mapicons/siteActiveUndefined.svg';

        if (sm) {
          switch (sm.soilMoistureStatus) {
            case 'High':
              icon = 'assets/icons/mapicons/siteActiveHigh.svg';
              siteColour = '#90CAF9';
              break;
            case 'Low':
              icon = 'assets/icons/mapicons/siteActiveLow.svg';
              siteColour = '#FFC107';
              break;
            case 'Ok':
              icon = 'assets/icons/mapicons/siteActiveOk.svg';
              siteColour = '#00C853';
              break;
          }
        }

        const siteShape = this.siteShapes.find((s) => s.siteId == siteMarker.siteId);

        if (siteShape && siteShape.polygon) {
          siteShape.polygon.setOptions({ fillColor: siteColour });
        }

        if (siteShape?.infoWindow) {
          //healthShape.polygonLabel.setContent("");
        }

        if (icon != '') {
          siteMarker.marker.setIcon(icon);
        }
      }
    });
  }

  public overlayOpacityChanged() {
    this.setOverlayOpacity();
    this._localStorageService.set('dashboardParams', this.dashboardParams);
    this.setHealthLayerOpacity();
    this.setSiteShapeOpacity();
    this.setSiteHealthShapeOpacity();
  }

  public setSiteShapeOpacity() {
    this.siteShapes.forEach((siteShape) => {
      const site = this.accountSites.find((a) => a.siteName == siteShape.siteName);

      if (site) {
        if (this.overlayType == this.overlayTypes.None.value) {
          siteShape.polygon.setOptions({ fillOpacity: this.overlayOpacity });
        } else {
          siteShape.polygon.setOptions({ fillOpacity: 0 });
        }
      }
    });
  }

  public getOverlayOpactity(): number {
    const overlayOpacity = this.dashboardParams.overlayOpacity.find((o) => o.overlay == this.overlayType);

    if (overlayOpacity == null) {
      return 0.9; //default
    } else {
      return overlayOpacity.opacity;
    }
  }

  public setOverlayOpacity() {
    const overlayOpacity = this.dashboardParams.overlayOpacity.find((o) => o.overlay == this.overlayType);

    if (overlayOpacity == null) {
      this.dashboardParams.overlayOpacity.push({ overlay: this.overlayType, opacity: this.overlayOpacity } as IOverlayOpacity);
    } else {
      overlayOpacity.opacity = this.overlayOpacity;
    }
  }

  public setSiteHealthShapeOpacity() {
    this.healthShapes.forEach((healthShape) => {
      if (this.overlayType == this.overlayTypes.None.value) {
        healthShape.polygon.setOptions({ fillOpacity: this.overlayOpacity });
      } else {
        healthShape.polygon.setOptions({ fillOpacity: 0 });
      }
    });
  }

  public overlayTypeChanged() {
    if (this.overlayType != this.overlayTypes.None.value) {
      this.dashboardParams.overlayType = this.overlayTypes.None.value;
    } else {
      this.dashboardParams.overlayType = this.overlayType;
    }
    this.overlayOpacity = this.getOverlayOpactity();
    this._localStorageService.set('dashboardParams', this.dashboardParams);
    this.resetOverlay(this.overlayType == this.overlayTypes.None.value);
    this.setSiteShapeOpacity();
    this.setSiteHealthShapeOpacity();
  }

  public toggleSiteShapes() {
    this._localStorageService.set('dashboardParams', this.dashboardParams);
    this.overlayTypes.None.description =
      this.dashboardParams.siteShapeType == 'Outline'
        ? this._languageService.instant('COMMON.SOIL_MOISTURE_BALANCE')
        : this._languageService.instant('PROJ.MAP.OVERLAY_NONE');

    this.setSiteShapes();
  }

  public toggleSiteHealthShapes() {
    this._localStorageService.set('dashboardParams', this.dashboardParams);
    this.setSiteHealthMarkerAndShapes();
  }

  private setSiteShapes() {
    if (!this.accountSites) {
      return;
    }

    this.accountSites.forEach((site) => {
      const siteShape = this.siteShapes.find((a) => a.siteId == site.siteId);

      if (siteShape?.polygon) {
        if (this.dashboardParams.siteShapeType == 'Outline') {
          if (
            site.status == StatusEnum.Active ||
            (site.status == StatusEnum.Archived && this.dashboardParams.archivedSites == 'Show')
          ) {
            siteShape.polygon.setMap(this.map);
          } else {
            siteShape.polygon.setMap(null);
          }
        } else {
          siteShape.polygon.setMap(null);
        }
      }

      const siteMarker = this.siteMarkers.find((a) => a.siteId == site.siteId);

      if (siteMarker?.marker) {
        if (this.dashboardParams.siteShapeType == 'Icon') {
          if (
            site.status == StatusEnum.Active ||
            (site.status == StatusEnum.Archived && this.dashboardParams.archivedSites == 'Show')
          ) {
            siteMarker.marker.setMap(this.map);
          } else {
            siteMarker.marker.setMap(null);
          }
        } else {
          siteMarker.marker.setMap(null);
        }
      }
    });
  }

  private setSiteHealthMarkerAndShapes() {
    if (this.dashboardParams.siteHealthShapeType == 'Icon') {
      this.healthShapes.forEach((shape) => {
        if (shape.polygon) {
          shape.polygon.setMap(null);
        }
      });

      this.healthMarkers.forEach((marker) => {
        if (marker.marker) {
          const sensor = this.filteredSensors.find((s) => s.id == marker.sensorId);
          const parentSites = this.accountSites.filter((s) => sensor.parentAssets.find((a) => s.siteId == a));
          const isVisible = parentSites?.some((site) => site.status == StatusEnum.Active || (site.status == StatusEnum.Archived && this.dashboardParams.archivedSites == 'Show'));

          marker.marker.setMap(isVisible ? this.map : null);
        }
      });
    } else if (this.dashboardParams.siteHealthShapeType == 'Outline') {
      this.healthShapes.forEach((shape) => {
        const sensor = this.filteredSensors.find((s) => s.id == shape.sensorId);
        const parentSites = this.accountSites.filter((s) => sensor.parentAssets.find((a) => s.siteId == a));
        const isVisible = parentSites?.some((site) => site.status == StatusEnum.Active || (site.status == StatusEnum.Archived && this.dashboardParams.archivedSites == 'Show'));

        shape.polygon.setMap(shape.polygon && isVisible ? this.map : null);
      });

      //for healthpoint, still display icon
      this.healthMarkers.forEach((marker) => {
        if (this.healthShapes.some((a) => a.sensorId == marker.sensorId)) {
          marker.marker.setMap(null);
        } else {
          const sensor = this.filteredSensors.find((s) => s.id == marker.sensorId);
          const parentSites = this.accountSites.filter((s) => sensor.parentAssets.find((a) => s.siteId == a));
          const isVisible = parentSites?.some((site) => site.status == StatusEnum.Active || (site.status == StatusEnum.Archived && this.dashboardParams.archivedSites == 'Show'))

          marker.marker.setMap(isVisible ? this.map : null);
        }
      });
    } else {
      this.healthShapes.forEach((shape) => {
        if (shape.polygon) {
          shape.polygon.setMap(null);
        }
      });

      this.healthMarkers.forEach((marker) => {
        if (marker.marker) {
          marker.marker.setMap(null);
        }
      });
    }
  }

  public toggleArchivedSites() {
    this._localStorageService.set('dashboardParams', this.dashboardParams);

    // all we want to do is show / hide the required markers
    this.filteredSites = this.accountSites.filter(
      (a) =>
        a.status == StatusEnum.Active ||
        (this.dashboardParams.archivedSites == 'Show' && a.status == StatusEnum.Archived),
    );

    this.setSiteShapes();
    this.setMapExtentsAndZoom();
    this.setHealthIndexLayer();
  }

  public zoomToSite(siteId: number) {
    if (siteId == this.infoModal.assetId) {
      this.setMapCenter(this.infoModal.latitude, this.infoModal.longitude);
      return;
    }

    const site = this.accountSites.find((a) => a.siteId == siteId);

    if (site) {
      this.siteMarkerClick(siteId);
    }
  }

  public setSoilMoistureIcon(state: string): string {
    if (state == 'low') {
      return 'icon-arrow-down-bold-circle soilTargetDry';
    } else if (state == 'high') {
      return 'icon-arrow-up-bold-circle soilTargetWet';
    } else if (state == 'ok') {
      return 'icon-checkbox-marked-circle soilTargetOk';
    } else {
      return 'icon-minus-circle-outline soilTargetNone';
    }
  }

  private setDefaultNav(): void {
    const swanLogo = document.getElementById('swanLogo');
    const swanLabel = document.getElementById('swanLabel');
    const customLogo = document.getElementById('customLogo');

    if (this.accountCustomStyling?.NavigationLogoURL) {
      swanLogo.style.display = 'none';
      swanLabel.style.display = 'none';
      customLogo.style.display = 'block';
      const imgLogo: HTMLImageElement = document.getElementById('NavLogo') as HTMLImageElement;

      if (imgLogo) {
        imgLogo.src = this.accountCustomStyling.NavigationLogoURL;
      }
    } else {
      customLogo.style.display = 'none';
      swanLogo.style.display = 'block';
      swanLabel.style.display = 'block';
    }
  }

  public changeWaterUnits() {
    this._localStorageService.set('dashboardParams', this.dashboardParams);
  }

  public loadAccount() {
    this._localStorageService.initAccountList();
    this.refreshCount++;

    const account = this.permissionService.currentAccount;

    if (this.httpCanceller) {
      this.httpCanceller.resolve('New Account Selected');
    }

    this.httpCanceller = this._q.defer();
    this.isSiteHealthShown = false;

    this.dashboardParams = this._localStorageService.get('dashboardParams') as IDashboardParams;
    this.mapHealthReadings = [];

    const resetAccountSpecificData = () => {
      this.siteAndSensors = [];

      if (this.siteMarkers) {
        this.siteMarkers.forEach((siteMarker: ISiteMarker) => {
          siteMarker.marker.setMap(null);
        });
        this.siteMarkers = [];
      }

      if (this.siteShapes) {
        this.siteShapes.forEach((siteShape: ISiteShape) => {
          siteShape.polygon.setMap(null);
          siteShape.infoWindow?.close();
        });

        this.siteShapes = [];
      }

      if (this.healthShapes) {
        this.healthShapes.forEach((shape: ISensorShape) => {
          shape.polygon.setMap(null);
          shape.infoWindow?.close();
        });

        this.healthShapes = [];
      }

      if (this.healthMarkers) {
        this.healthMarkers.forEach((healthMarker: ISensorMarker) => {
          healthMarker.marker.setMap(null);
        });

        this.healthMarkers = [];
      }

      if (this.drawings) {
        this.drawings.forEach((d) => {
          d.setMap(null);
        });

        this.drawings = [];
      }

      LocalStorageUtils.updateContextData((context) => {
        context.groupId = this._localStorageService.getGroupId();
      });

      this._equipmentService.clearFilters();
    };

    resetAccountSpecificData();

    this.adjustedTodayDayNumber = this._dayNumberService.convertBrowserDateTimeToLocaleDayNumber();
    this.currentAccountName = account.name;

    this.ndviLayerInfo = '';
    this.permissionService.setAccountPermissions();

    this.setDefaultNav();

    this._rootScope.$broadcast('refreshAlertCount');

    this.siteSummaryInfo = {} as fuse.dashboardSiteCardInfo;
    this.groupSummaryInfo = {} as fuse.dashboardGroupInfo;
    this.healthSummaryInfo = {} as fuse.dashboardHealthInfo;
    this.waterUsageSummaryInfo = {} as fuse.dashboardWaterUsageInfo;
    this.yesterdaySoilMoistureStatus = {} as fuse.dashboardSoilMoistureItem;

    if (this.soilMoistureChart) {
      this.soilMoistureChart.clear();
      this.soilMoistureChart = null;
    }

    if (this.waterUsageChart) {
      this.waterUsageChart.clear();
      this.waterUsageChart = null;
    }

    if (this.healthChart) {
      (this.healthChart as any).legend = null;
      this.healthChart.clear();
      this.healthChart = null;
    }

    this.weatherSummary = null;
    this.nutrients = null;
    this.total_irrigated_area = null;
    this.waterUsageSummary = null;

    this._timeout(() => {
      this._mdSidenav('healthChartModal').close();
      this._mdSidenav('infoModal').close();
      this._mdSidenav('mapToolsModal').close();
      this._mdSidenav('mapSearchSiteModal').close();
    }, 100);

    const getPromises = (): angular.IPromise<unknown>[] => {
      const promises = [] as angular.IPromise<unknown>[];

      promises.push(this._mapService.isGoogleMapsJSApiLoaded);
      promises.push(this.getNgisDashboardSummary());
      promises.push(this.getNgisDashboardEquipmentSummary());
      promises.push(this.getNgisDashboardSiteSummary());
      promises.push(this.getAccountDetail(account.accountId));

      return promises;
    }

    const processPromises = (): void => {
      this.siteHealthDate = this.accountYesterday();
      this.maxSiteHealthDate = this.siteHealthDate.clone().addDays(6);
      this.setHealthMinDate();
      this.siteHealthCompareDate = this.siteHealthDate.clone().addMonths(-1);
      this.soilMoistureStartDayNumber = null;
      this.soilMoistureEndDayNumber = null;
      this.overlayType = this.overlayTypes.None.value;
      this.healthReadings = [];
      this.soilMoisture = [];

      this.updateNgisAccountSitesWithWaterInfo();

      if (this.googleMapContainer) {
        this.initMap();

        this.createNgisSoilMoistureDataProvider();
        this.createNgisWeatherSummary(); //need current site id
        this.createNgisWaterUsageSummary();
        this.createNgisNutrients();

        this._timeout(() => {
          this.setHealthIndexLayer();
          this.defineSoilMoistureChart();
          this.displaySoilMoistureChart();
        }, 1000);
      }
    };

    this._q.all(getPromises()).then(
      () => {
        processPromises();
      },
      (e) => {
        if (e?.status === -1) {
          return;
        }

        // Retry one more time.
        this._q.all(getPromises()).then(
          () => {
            processPromises();
          },
          (e) => {
            if (e?.status === -1) {
              return;
            }

            this._languageService.whoops();
          },
        );
      },
    );
  }

  public getDataInputName(typ: string) {
    return this.dashboardAccountInfo.dashboardDataInputs.find((i) => i.dataType == typ).name;
  }

  public getAccountDetail(accountId: number): angular.IPromise<void> {
    const defer = this._q.defer<void>();
    const params = { accountId: accountId };

    this._http.get(CommonHelper.getApiUrl('dashboard/getAccountInfo'), { params: params, timeout: this.httpCanceller.promise }).then((response) => {
      if (!response.data) {
        this._languageService.whoops();
      } else {
        this.dashboardAccountInfo = response.data as fuse.dashboardAccountInfoDto;
        this.healthIndexMaximumAge = this.dashboardAccountInfo.healthIndexMaximumAge;
        this._colourSchemeService.initialiseHealthThemes(this.dashboardAccountInfo.dashboardDataInputs.map((i) => i.dataType));

        if (
          !this.dashboardParams.mapHealthIndex ||
          !this.dashboardAccountInfo.dashboardDataInputs.find((i) => i.dataType == this.dashboardParams.mapHealthIndex)
        ) {
          this.dashboardParams.mapHealthIndex = this.dashboardAccountInfo.dashboardDataInputs[0].dataType;
        }

        if (
          !this.dashboardParams.healthIndexProvider ||
          !this.dashboardAccountInfo.dashboardDataProviderSubscriptions.find((s) => s.dataProviderName == this.dashboardParams.healthIndexProvider)
        ) {
          this.dashboardParams.healthIndexProvider = this.dashboardAccountInfo.dashboardHealthIndexSubscriptions[0].dataProviderName;
          this.overlayTypes.ProviderRGB.description = this.dashboardParams.healthIndexProvider + ' ' + this._languageService.instant('COMMON.VISIBLE');
        }

        this.currentYear = new Date().getFullYear();

        const currentMonth = new Date().getMonth();

        if (currentMonth < this.dashboardAccountInfo.irrigationSeasonStartMonth - 1) {
          this.currentYear -= 1;
        }

        this.healthChartStartDate = new Date(this.currentYear, this.dashboardAccountInfo.irrigationSeasonStartMonth - 1);
        this.healthChartZoomStartDate = this.healthChartStartDate.clone();
        this.healthChartStartDayNumber = this._dayNumberService.convertLocaleDateToLocaleDayNumber(this.healthChartStartDate);
        this.healthChartEndDate = this.healthChartStartDate.clone().addMonths(12).addDays(-1);
        this.healthChartZoomEndDate = this.healthChartEndDate.clone();
        this.healthChartEndDayNumber = this._dayNumberService.convertLocaleDateToLocaleDayNumber(this.healthChartEndDate);
        this.healthChartMaxDate = new Date();
        this.healthIndexTooltip = this.getHealthIndexTooltip(this.dashboardParams.mapHealthIndex);
      }
      defer.resolve();
    },
    (e) => {
      defer.reject(e);
    });

    return defer.promise;
  }

  private getNgisDashboardSummary(): angular.IPromise<void> {
    const defer = this._q.defer<void>();
    const params = { accountId: this.accountId };

    this._http.get(CommonHelper.getApiUrl('user/Dashboard'), { params: params, timeout: this.httpCanceller.promise }).then((response) => {
      if (!response.data) {
        this._languageService.whoops();
      } else {
        this.ngisDashboardSummary = response.data as fuse.dashboardModel;
        this.groupSummaryInfo.active = this.ngisDashboardSummary.data.total_groups_active;
        this.groupSummaryInfo.archived = this.ngisDashboardSummary.data.total_groups_archived;
        this.groupSummaryInfo.suspended = this.ngisDashboardSummary.data.total_groups_suspended;
        this.healthSummaryInfo.high = this.ngisDashboardSummary.data.health_high;
        this.healthSummaryInfo.low = this.ngisDashboardSummary.data.health_low;
        this.healthSummaryInfo.onTarget = this.ngisDashboardSummary.data.health_ok;
        this.waterUsageSummaryInfo.onTarget = this.ngisDashboardSummary.data.waterusage_ok;
        this.waterUsageSummaryInfo.over = this.ngisDashboardSummary.data.waterusage_high;
        this.waterUsageSummaryInfo.under = this.ngisDashboardSummary.data.waterusage_low;

        this.dashboardWaterUsages = this.ngisDashboardSummary.data.waterusage?.map((wu) => {
          return {
            waterActual: wu.actual,
            waterBudget: wu.budget,
            runningActual: wu.actualRunning,
            runningBudget: wu.budgetRunning,
          } as fuse.dashboardWaterUsageDto;
        }) ?? [];

        // Acknowledgements.
        const acknowledgments = this._localStorageService.get('acknowledgements');

        this.acknowledgementMsg = '';

        if (!(acknowledgments && acknowledgments.account === this.accountId)) {
          const data = {
            account: this.accountId,
            count: 0,
            messages: this.ngisDashboardSummary.data.messages,
          };

          if (data.messages?.length) {
            this.acknowledgementMsg = this._sce.trustAsHtml(data.messages[0]);
          }

          this._localStorageService.set('acknowledgements', data);
        } else if (acknowledgments.count + 1 < this.ngisDashboardSummary?.data?.messages?.length) {
          acknowledgments.count = acknowledgments.count + 1;
          acknowledgments.messages = this.ngisDashboardSummary.data.messages;
          this.acknowledgementMsg = this._sce.trustAsHtml(acknowledgments.messages[acknowledgments.count]);
          this._localStorageService.set('acknowledgements', acknowledgments);
        } else {
          acknowledgments.count = 0;

          if (this.ngisDashboardSummary.data.messages) {
            acknowledgments.messages = this.ngisDashboardSummary.data.messages;
            this.acknowledgementMsg = this._sce.trustAsHtml(acknowledgments.messages[0]);
            this._localStorageService.set('acknowledgements', acknowledgments);
          }
        }
      }

      return defer.resolve();
    },
    (e) => {
      return defer.reject(e);
    });

    return defer.promise;
  }

  private updateNgisAccountSitesWithWaterInfo() {
    this.accountSites.forEach((site) => {
      const waterUsages = this.dashboardWaterUsages.filter((a) => a.siteId == site.siteId);

      if (waterUsages?.length) {
        const latestWaterUsage = waterUsages[waterUsages.length - 1];
        site.waterRunningBudget = latestWaterUsage.runningBudget;
        site.waterRunningActual = latestWaterUsage.runningActual;
      }
    });
  }

  private createNgisWaterUsageSummary() {
    this.waterUsageSummary = [];

    if (this.ngisDashboardSummary.data.waterusage?.length) {
      this.ngisDashboardSummary.data.waterusage.forEach((waterUsage) => {
        const dataItem = {
          localeDate: new Date(waterUsage.localeDate),
          waterActual: waterUsage.actual,
          waterBudget: waterUsage.budget,
          waterRunningActual: waterUsage.actualRunning,
          waterRunningBudget: waterUsage.budgetRunning,
        } as fuse.dashboardWaterUsageItem;

        this.waterUsageSummary.push(dataItem);
      });
    }
  }

  private createNgisNutrients() {
    const nutrientUsage = this.ngisDashboardSummary.data.nutrient_readings;
    const nutrients = new NutrientDashboard(this._languageService.instant('PROJ.ACCOUNT_NUTRIENTS'));

    nutrients.reInit(nutrientUsage as any, this.weightAreaUnit);

    this.nutrients = angular.copy(nutrients);
  }

  private createNgisWeatherSummary() {
    this.weatherSummary = [];

    if (this.ngisDashboardSummary.data.soilmoisture?.length) {
      this.ngisDashboardSummary.data.soilmoisture.forEach((weather) => {
        const dataItem = {} as fuse.dashboardWeatherItem;
        dataItem.dayNumber = weather.dayNumber;
        dataItem.localeDate = this._dayNumberService.convertDayNumberToLocaleDate(weather.dayNumber);
        dataItem.eto = weather.eTo;
        dataItem.maxTemp = weather.tempMax;
        dataItem.minTemp = weather.tempMin;
        dataItem.maxHumidity = weather.humidityMax;
        dataItem.minHumidity = weather.humidityMin;
        dataItem.rainfall = weather.rainfall;
        dataItem.windSpeed = weather.wsMean;
        this.weatherSummary.push(dataItem);
      });
    }
  }

  private getNgisDashboardSiteSummary(): angular.IPromise<void> {
    if (!this.apf.hasSiteView) {
      return;
    }

    const defer = this._q.defer<void>();
    const params = { accountId: this.accountId };

    this._http.get(CommonHelper.getApiUrl('dashboard/dashboardSiteSummaries'), {
      params: params,
      timeout: this.httpCanceller.promise,
    }).then((response) => {
      if (!response.data) {
        this._languageService.whoops();
      } else {
        this.totalArea = 0;
        this.accountSites = response.data as fuse.dashboardSiteInfoDto[];
        this.accountSites.forEach((site) => {
          site.soilMoistureStates.forEach((soilMoisture) => {
            soilMoisture.state =
              soilMoisture.low > 0 ? 'low' : soilMoisture.ok > 0 ? 'ok' : soilMoisture.high > 0 ? 'high' : 'unknown';
            const localDate = moment(soilMoisture.dayDisplayYMD, 'YYYY-MM-DD').toDate();
            soilMoisture.dayNumber = this._dayNumberService.convertLocaleDateToLocaleDayNumber(localDate);
            soilMoisture.localeDate = localDate;
          });
          if (site.area != null && site.status == StatusEnum.Active) {
            this.totalArea = this.totalArea + site.area;
          }
        });
        this.siteSummaryInfo.active = this.accountSites.filter((a) => a.status == StatusEnum.Active).length;
        this.siteSummaryInfo.archived = this.accountSites.filter((a) => a.status == StatusEnum.Archived).length;
        this.siteSummaryInfo.suspended = this.accountSites.filter((a) => a.status == StatusEnum.Suspended).length;
        this.filteredSites = this.accountSites.filter(
          (a) =>
            a.status == StatusEnum.Active ||
            (this.dashboardParams.archivedSites == 'Show' && a.status == StatusEnum.Archived),
        );
      }
      return defer.resolve();
    },
    (e) => {
      defer.reject(e);
    });

    return defer.promise;
  }

  private createNgisSoilMoistureDataProvider() {
    this.soilMoistureDataProvider = [];

    this.accountSites.forEach((site) => {
      site.soilMoistureStates.forEach((soilMoistureState) => {
        let dataItem = this.soilMoistureDataProvider.find((a) => a.dayNumber == soilMoistureState.dayNumber);

        if (!dataItem) {
          dataItem = {
            dateDisplay: DateUtils.Locale.asDateDayWithWeekday(soilMoistureState.localeDate),
            localeDate: soilMoistureState.localeDate,
            dayNumber: soilMoistureState.dayNumber,
            dry: 0,
            onTarget: 0,
            over: 0,
          } as fuse.dashboardSoilMoistureItem;

          this.soilMoistureDataProvider.push(dataItem);
        }

        if (soilMoistureState.state == 'low') {
          dataItem.dry++;
        } else if (soilMoistureState.state == 'ok') {
          dataItem.onTarget++;
        } else if (soilMoistureState.state == 'high') {
          dataItem.over++;
        }
      });
    });

    this.yesterdaySoilMoistureStatus = this.soilMoistureDataProvider.find((a) => a.dayNumber == this.adjustedTodayDayNumber - 1);
  }

  private createSiteMarkerAndShapes() {
    // Clear any existing shapes
    this.siteShapes?.forEach((shape) => {
      shape.polygon.setMap(null);
    });

    // Clear any existing shapes
    this.siteMarkers?.forEach((marker) => {
      marker.marker.setMap(null);
    });

    this.siteShapes = [];
    this.siteMarkers = [];
    this.accountSites.forEach((site) => {
      const siteSoilMoistureStates = [] as string[];
      let markerIcon = 'assets/icons/mapicons/siteActiveUndefined.svg';
      let shapeFillColor = 'black';

      if (site.soilMoistureStates) {
        site.soilMoistureStates.forEach((soilMoistureState) => {
          siteSoilMoistureStates.push(soilMoistureState.state);
        });
      }

      if (site.status == StatusEnum.Archived) {
        markerIcon = 'assets/icons/mapicons/1Archived.svg';
        shapeFillColor = 'red';
      } else if (site.status == StatusEnum.Suspended) {
        markerIcon = 'assets/icons/mapicons/1Suspended.svg';
        shapeFillColor = 'black';
      } else if (site.status == StatusEnum.Active) {
        // the icon and shape will be changed by setSoilMoistureIconAndShapeColour
        markerIcon = 'assets/icons/mapicons/siteActiveUndefined.svg';
        shapeFillColor = 'grey';
      }

      let siteLabel = site.siteName;

      if (site.area) {
        siteLabel += ` (${this._unitOfMeasureService.convertFromBase(
          'Area',
          unitSizes.normal,
          site.area,
        )} ${this._unitOfMeasureService.getUnitLabel('Area', unitSizes.normal)})`;
      }

      const siteInfoWindow = new google.maps.InfoWindow({
        content: siteLabel,
        boxStyle: {
          textAlign: 'center',
          fontSize: '8pt',
          width: '50px',
        },
        disableAutoPan: true,
        pixelOffset: new google.maps.Size(0, 0),
        position: new google.maps.LatLng(site.latitude, site.longitude),
        closeBoxURL: '',
        isHidden: false,
        pane: 'mapPane',
        enableEventPropagation: true,
        zIndex: 0,
      } as google.maps.InfoWindowOptions);

      if (site.shape) {
        const path = [];

        for (let i = 0; i < site.shape.length; i++) {
          const item = site.shape[i];

          path.push({ lat: item[1], lng: item[0] });
        }

        const polygon = new google.maps.Polygon({
          paths: path,
          fillColor: shapeFillColor,
          strokeWeight: 2,
          strokeColor: 'black',
          strokeOpacity: 0.5,
        });

        google.maps.event.addListener(siteInfoWindow, 'closeclick', () => {
          this.lastInfoWindowId = site.siteId;
        });

        const siteShape = {
          siteId: site.siteId,
          siteName: site.siteName,
          polygon: polygon,
          infoWindow: siteInfoWindow,
        } as ISiteShape;

        this.siteShapes.push(siteShape);
      }

      const siteLatlng = new google.maps.LatLng(site.latitude, site.longitude);
      const marker = new google.maps.Marker({
        position: siteLatlng,
        icon: markerIcon,
        title: siteLabel,
      });

      const siteMarker = {
        siteId: site.siteId,
        siteName: site.siteName,
        status: site.status,
        latitude: site.latitude,
        longitude: site.longitude,
        cropType: site.cropType,
        irrigationPlanId: site.irrigationPlanId,
        waterRunningBudget: site.waterRunningBudget,
        waterRunningActual: site.waterRunningActual,
        soilMoistureStates: siteSoilMoistureStates,
        marker: marker,
      } as ISiteMarker;

      this.siteMarkers.push(siteMarker);

      const siteAsset = {
        assetClassName: 'site',
        assetId: site.siteId,
        assetName: site.siteName,
        infoWindow: siteInfoWindow,
        latitude: site.latitude,
        longitude: site.longitude,
        status: site.status,
      } as ISiteAndSensor;

      if (site.shape) {
        if (site.status != 'Active') {
          siteAsset.assetName += ` (${site.status})`;
        }

        this.siteAndSensors.push(siteAsset);
      } else {
        // not push un-mapped site to list
        siteAsset.assetName += ' (not setting)';
      }
    });
  }

  public zoomToAccount() {
    const latLngBounds = new google.maps.LatLngBounds();

    this.accountSites.forEach((site) => {
      const siteLatlng = new google.maps.LatLng(site.latitude, site.longitude);

      if (this.dashboardParams.archivedSites == 'Show' || site.status == StatusEnum.Active) {
        //for active sites, or if archived enabled, include archived sites
        latLngBounds.extend(siteLatlng);

        const siteShape = this.siteShapes.find((a) => a.siteName == site.siteName);

        if (siteShape?.polygon) {
          siteShape.polygon.getPath().forEach((e, i) => {
            latLngBounds.extend(e);
          }); //Use site shape to extend the zoom bounds
        }
      }
    });

    const siteCount = this.accountSites.filter(
      (s) => this.dashboardParams.archivedSites == 'Show' || s.status == StatusEnum.Active,
    ).length;

    if (siteCount > 1) {
      this.map.fitBounds(latLngBounds);
    } else if (siteCount == 1) {
      const siteShape = this.siteShapes.find((a) => a.siteName == this.accountSites[0].siteName);

      if (siteShape?.polygon) {
        this.map.fitBounds(latLngBounds);
      } else {
        this.map.setZoom(16); //Site has not been drawn yet, so default to 16
      }
    } else {
      //Set the lat/long to the account default
      const defaultLatlng = new google.maps.LatLng(this.dashboardAccountInfo.defaultLatitude, this.dashboardAccountInfo.defaultLongitude);

      latLngBounds.extend(defaultLatlng);
      this.map.setZoom(10);

      this.overlayType = this.overlayTypes.None.value; //disable ndvi layer if there is no site in account
    }

    const center = latLngBounds.getCenter();

    this.map.setCenter(center); // account default (as set above) if no markers
    // map info will record in idle event
  }

  public setMapExtentsAndZoom() {
    const account = this._localStorageService.getAccount();

    if (account.mapLatitude == null || account.mapLongitude == null || account.mapZoom == null) {
      const latlngbounds = new google.maps.LatLngBounds();

      this.accountSites.forEach((site) => {
        const siteLatlng = new google.maps.LatLng(site.latitude, site.longitude);

        if (this.dashboardParams.archivedSites == 'Show' || site.status == StatusEnum.Active) {
          //for active sites, or if archived enabled, include archived sites
          latlngbounds.extend(siteLatlng);

          const siteShape = this.siteShapes.find((a) => a.siteName == site.siteName);

          if (siteShape?.polygon) {
            siteShape.polygon.getPath().forEach((e, i) => {
              latlngbounds.extend(e);
            }); //Use site shape to extend the zoom bounds
          }
        }
      });

      const siteCount = this.accountSites.filter(
        (s) => this.dashboardParams.archivedSites == 'Show' || s.status == StatusEnum.Active,
      ).length;

      if (siteCount > 1) {
        this.map.fitBounds(latlngbounds);
      } else if (siteCount == 1) {
        const siteShape = this.siteShapes.find((a) => a.siteName == this.accountSites[0].siteName);

        if (siteShape?.polygon) {
          this.map.fitBounds(latlngbounds);
        } else {
          this.map.setZoom(16); //Site has not been drawn yet, so default to 16
        }
      } else {
        //Set the lat/long to the account default
        const defaultLatlng = new google.maps.LatLng(this.dashboardAccountInfo.defaultLatitude, this.dashboardAccountInfo.defaultLongitude);

        latlngbounds.extend(defaultLatlng);

        this.map.setZoom(10);
        this.overlayType = this.overlayTypes.None.value; //disable ndvi layer if there is no site in account
      }

      const center = latlngbounds.getCenter();

      this.map.setCenter(center); // account default (as set above) if no markers
      // map info will record in idle event
    } else {
      this.map.setCenter(new google.maps.LatLng(account.mapLatitude, account.mapLongitude));
      this.map.setZoom(account.mapZoom);
    }
  }

  private getNgisDashboardEquipmentSummary(): angular.IPromise<void> {
    if (!this.apf.hasSiteView) {
      return;
    }

    const defer = this._q.defer<void>();

    this._http.get(CommonHelper.getApiUrl('user/AccountEquipmentSummary'), {
      params: { accountId: this.accountId },
      timeout: this.httpCanceller.promise,
    }).then((response) => {
      if (!response.data) {
        this._languageService.whoops();
      } else {
        this.accountSensors = [];
        const siteAssets = (response.data as fuse.accountEquipmentSummaryModel).sensors;

        siteAssets.forEach((asset) => {
          const sensor = {} as fuse.dashboardEquipmentDto;

          sensor.id = asset.id;
          sensor.name = asset.name;
          sensor.assetclassid = asset.assetclassid;
          sensor.siteId = asset.siteId;
          sensor.latitude = asset.latitude;
          sensor.longitude = asset.longitude;
          sensor.shape = asset.shape;
          sensor.parentAssets = asset.parentassets;
          sensor.status = asset.status;
          sensor.timezoneId = asset.timezoneId;

          const valueRanges = [] as fuse.valueRangeDto[];

          if (asset.valueRanges) {
            asset.valueRanges.forEach((assetValueRange) => {
              const valueRange = {} as fuse.valueRangeDto;
              valueRange.effectiveFrom = moment(assetValueRange.EffectiveFrom).toDate();
              valueRange.dayNumber = this._dayNumberService.convertDateToLocaleDayNumber(
                valueRange.effectiveFrom,
                sensor.timezoneId,
              );
              valueRange.attribute = assetValueRange.Attribute;
              valueRange.readingHigh = assetValueRange.ReadingHigh;
              valueRange.readingLow = assetValueRange.ReadingLow;
              valueRange.dataInputId = assetValueRange.dataInputId;
              valueRange.dataInputName = assetValueRange.dataInputName;
              valueRange.fromDayNumber = assetValueRange.fromDayNumber;
              valueRange.toDayNumber = assetValueRange.toDayNumber;
              valueRanges.push(valueRange);
            });
          }

          sensor.valueRanges = valueRanges;

          this.accountSensors.push(sensor);
        });
      }

      return defer.resolve();
    },
    (e) => {
      return defer.reject(e);
    });

    return defer.promise;
  }

  private siteMarkerClick(assetId: number): angular.IPromise<void> {
    const isInfoModalOpened = this._mdSidenav('infoModal').isOpen();
    const isHealthModalOpened = this._mdSidenav('healthChartModal').isOpen();

    if (isInfoModalOpened == false) {
      this.closeHealthChartModal();
      this._mdSidenav('mapSettingsModal').close();
      this.closeMapToolsModal();
    }

    const siteMarker = this.siteMarkers.find((a) => a.siteId == assetId);

    this.infoModal = {
      assetId: assetId,
      assetName: siteMarker.siteName,
      irrigationPlanId: siteMarker.irrigationPlanId,
      latitude: siteMarker.latitude,
      longitude: siteMarker.longitude,
    } as fuse.dashboardInfoModal;
    const irriFromDayNumber = this.adjustedTodayDayNumber - 3;
    const irriToDayNumber = this.adjustedTodayDayNumber + 7;
    const params = {
      siteId: assetId,
      fromDayNumber: irriFromDayNumber,
      toDayNumber: irriToDayNumber,
    };

    const defer = this._q.defer<void>();

    this._http.get(CommonHelper.getApiUrl('dashboard/getSiteIrrigationInfos'), { params }).then((response) => {
      if (!response.data) {
        this._languageService.whoops();

        defer.reject();
      } else {
        this.siteIrrigationInfos = response.data as fuse.siteIrrigationInfoDto[];

        this.siteIrrigationInfos.forEach((irrigationInfo) => {
          irrigationInfo.localeDate = this._dayNumberService.convertDayNumberToDate(irrigationInfo.dayNumber);
        });

        this._timeout(() => {
          if (!isInfoModalOpened && !isHealthModalOpened) {
            this.setMapCenter(siteMarker.latitude, siteMarker.longitude);
          }

          this.showInfoModal();

          defer.resolve();
        });
      }
    });

    return defer.promise;
  }

  private siteShapeClick(assetId: number) {
    this.siteMarkerClick(assetId);
  }

  public gotoSiteDetail(siteId: number) {
    LocalStorageUtils.updateContextData((context) => {
      context.siteId = siteId;
      context.assetId = siteId;
    });

    this._state.go('app.account.sites.detail', { id: siteId, viewSchedule: true });
  }

  public gotoEquipmentDetail(equipmentId: number) {
    LocalStorageUtils.updateContextData((context) => {
      context.assetId = equipmentId;
    });

    this._state.go('app.account.equipments.detail', { id: equipmentId, viewRange: true });
  }

  public gotoPlanDetail(planId: number) {
    LocalStorageUtils.updateContextData((context) => {
      context.planId = planId;
    });

    this._state.go('app.water.irrigation-plans.detail', { id: planId });
  }

  // User changed sensor selection
  public setNdviSensor() {
    this._localStorageService.set('dashboardParams', this.dashboardParams);
    this.ndviLayerInfo = '';
    this.setHealthIndexLayer();
  }

  // User changed cloudiness value
  public setNdviCloudinessDebounce = PerformanceUtils.debounce(() => this.setNdviCloudiness());

  public setNdviCloudiness() {
    // console.log('setNdviCloudiness', this.dashboardParams.healthIndexCloudValue);
    this._localStorageService.set('dashboardParams', this.dashboardParams);
    this.imageDates = [];
    this.ndviLayerInfo = '';
    this.setHealthIndexLayer();
    this.setHealthIconAndShapeColour();
  }

  public setNdviTimeSearch() {
    // Set the time search - default 90 days (3 months). Increments in 90 days up to 3 years
    this.setHealthIndexLayer();
  }

  public setNdviTheme() {
    // Set the theme
    this.setHealthIndexLayer();
  }

  public setHealthOverlayClip() {
    this._localStorageService.set('dashboardParams', this.dashboardParams);
    this.setHealthIndexLayer();
  }

  public setHealthDate() {
    if (this.siteHealthDate) {
      this.setHealthIndexLayer();
      this.loadMapHealthReadings().then(
        () => this.setHealthIconAndShapeColour(),
        (err) => console.error(err),
      );
      this.loadSoilMoisture().then(
        () => this.setSoilMoistureIconAndShapeColour(),
        (err) => console.error(err),
      );
    }
  }

  public setHealthCompareDate() {
    if (this.siteHealthCompareDate) {
      this.setHealthIndexLayer();
    }
  }

  // Layer Opacity
  public setHealthLayerOpacity() {
    if (this.eeLayer) {
      this.eeLayer.setOpacity(this.overlayOpacity);
    }
  }

  // Clear Health Index display layer
  public clearHealthIndexLayer() {
    if (this.eeLayer) {
      this.map.overlayMapTypes.clear(); // brutal
      this.eeLayer = null;
    }
  }

  private overlayClipped(provider?: string): boolean {
    const subscription = this.dashboardAccountInfo.dashboardDataProviderSubscriptions.find((m) => m.dataProviderName == (provider ?? this.provider));
    const clip = this.dashboardParams.clipToShape == 'Yes' || !subscription || subscription.clippedToSites;

    return clip;
  }

  // Primary GEE Map Tile Display based on user selected inputs
  private displayHealthIndexMapLayer(provider: string) {
    const subscription = this.dashboardAccountInfo.dashboardDataProviderSubscriptions.find((m) => m.dataProviderName == provider);
    const eeConfig = subscription.eeConfig;
    const clip = this.overlayClipped(provider);

    try {
      const siteShapes = this.siteShapes.filter((shape) => {
        const site = this.accountSites.find((site) => site.siteId == shape.siteId);

        return site && (this.dashboardParams.archivedSites == 'Show' || site.status == StatusEnum.Active);
      }).map((s) => {
        return {
          type: 'Polygon',
          coordinates: s.polygon
            .getPath()
            .getArray()
            .map((i) => {
              return [i.lng(), i.lat()];
            }),
        } as fuse.geometry;
      });

      const index = this.dashboardAccountInfo.dashboardDataInputs.find((i) => i.dataType == this.dashboardParams.mapHealthIndex);

      if (this.overlayType == this.overlayTypes.TemporalVariation.value) {
        const date1 = moment(this.siteHealthCompareDate);
        const date2 = moment(this.siteHealthDate);

        this._healthIndexService.createHealthIndexMapChangeLayer(
          eeConfig,
          this.dashboardParams.mapHealthIndex,
          this.dashboardParams.healthIndexCloudValue,
          clip,
          siteShapes,
          date1.toDate(),
          date2.toDate(),
          30,
          index.maximumValue,
        ).then((layer) => {
          if (this.eeLayer) {
            this.ndviLayerInfo = '';
            this.clearHealthIndexLayer();
          }

          //check we are still set on the temporal variation overlay
          if (this.overlayType == this.overlayTypes.TemporalVariation.value) {
            if (layer) {
              this.eeLayer = layer;
              layer.setOpacity(this.overlayOpacity);
              this.map.overlayMapTypes.push(layer);
            }
            this.getHealthOverlayImageInfo(provider);
          }
        },
        (e) => {
          this.clearHealthIndexLayer();
          this.ndviLayerInfo = e;
          this._languageService.error(e);
        })
        .catch((e) => {
          this.clearHealthIndexLayer();
          this.ndviLayerInfo = e;
          this._languageService.error(e);
        });
      } else if (this.overlayType == this.overlayTypes.SiteHealth.value) {
        this._healthIndexService.createHealthIndexMapLayer(
          eeConfig,
          this.dashboardParams.mapHealthIndex,
          this.dashboardParams.healthIndexCloudValue,
          clip,
          siteShapes,
          this.siteHealthDate,
          index.minimumValue,
          index.maximumValue,
        ).then((layer) => {
          // Check we are still set on the Site Health overlay
          if (this.overlayType == this.overlayTypes.SiteHealth.value) {
            if (this.eeLayer) {
              this.clearHealthIndexLayer();
            }

            if (layer) {
              layer.setOpacity(this.overlayOpacity);
              this.eeLayer = layer;
              this.map.overlayMapTypes.push(layer);
            }
            this.getHealthOverlayImageInfo(provider);
          }
        },
        (e) => {
          if (this.overlayType == this.overlayTypes.SiteHealth.value) {
            this.clearHealthIndexLayer();
            this.ndviLayerInfo = e;
          }
        })
        .catch((e) => {
          if (this.overlayType == this.overlayTypes.SiteHealth.value) {
            this.clearHealthIndexLayer();
            this._languageService.info('PROJ.IMAGERY_NOT_FOUND');
            this.ndviLayerInfo = this._languageService.instant('PROJ.IMAGERY_NOT_FOUND');
          }
        });
      } else if (this.overlayType == this.overlayTypes.ProviderRGB.value) {
        this._healthIndexService.createRGBMapLayer(eeConfig, this.dashboardParams.healthIndexCloudValue, clip, siteShapes, this.siteHealthDate).then((layer) => {
          // Check we are still set on the Provider RGB overlay
          if (this.overlayType == this.overlayTypes.ProviderRGB.value) {
            if (this.eeLayer) {
              this.clearHealthIndexLayer();
            }

            if (layer) {
              layer.setOpacity(this.overlayOpacity);
              this.eeLayer = layer;
              this.map.overlayMapTypes.push(layer);
            }

            this.getHealthOverlayImageInfo(provider);
          }
        },
        (e) => {
          if (this.overlayType == this.overlayTypes.ProviderRGB.value) {
            this.clearHealthIndexLayer();
            this.ndviLayerInfo = e;
          }
        })
        .catch((e) => {
          if (this.overlayType == this.overlayTypes.ProviderRGB.value) {
            this.clearHealthIndexLayer();
            this._languageService.info('PROJ.IMAGERY_NOT_FOUND');
            this.ndviLayerInfo = this._languageService.instant('PROJ.IMAGERY_NOT_FOUND');
          }
        });
      }
    } catch (e) {
      console.log(e);
      this._languageService.instant('PROJ.IMAGERY_NOT_FOUND');
    }
  }

  // Used to get latest tile Info at map center
  private getHealthOverlayImageInfo(provider: string) {
    const refreshMap = true;
    const eeConfig = this.dashboardAccountInfo.dashboardDataProviderSubscriptions.find((m) => m.dataProviderName == provider).eeConfig;

    if (this.eeLayer && refreshMap) {
      // Date location search criteria
      const mapBounds = this.map.getBounds();

      this._timeout.cancel(this.healthLayoutTimer);

      this.healthLayoutTimer = this._timeout(() => {
        const overlay = this.overlayType;

        if (this.overlayType == this.overlayTypes.SiteHealth.value || this.overlayType == this.overlayTypes.ProviderRGB.value) {
          this.imageInfo = this._languageService.instant('COMMON.LOADING');

          const promises = [] as angular.IPromise<any>[];

          promises.push(
            this._healthIndexService.getImageInfo(
              eeConfig,
              this.dashboardParams.healthIndexCloudValue,
              mapBounds.getSouthWest().lat(),
              mapBounds.getSouthWest().lng(),
              mapBounds.getNorthEast().lat(),
              mapBounds.getNorthEast().lng(),
              this.siteHealthDate,
              this.dateSearchDuration,
            ),
          );

          this._q.all(promises).then((results) => {
            const imageInfo = results[0] as ImageInfo;

            // Check we haven't changed overlay
            if (this.overlayType == overlay) {
              let latestDate: Date;

              if (imageInfo) {
                latestDate = this._dayNumberService.convertBrowserDateToLocaleDate(imageInfo.date, this.account.timezoneId);
                this.imageInfo =
                  provider +
                  (this.overlayType == this.overlayTypes.SiteHealth.value ? ' | ' + this.dashboardParams.mapHealthIndex : '') +
                  ' | ' +
                  DateUtils.Locale.asDateMedium(latestDate) +
                  ' | ' +
                  this._languageService.instant('COMMON.CLOUD') +
                  ' (' +
                  Math.floor(imageInfo.cloud) +
                  '%)';
                if (latestDate < this.siteHealthDate) {
                  this.ndviLayerInfo = this._languageService.instant('PROJ.NO_IMAGE_FOR_DATE', {
                    date: DateUtils.Locale.asDateMedium(this.siteHealthDate),
                  });
                } else {
                  this.ndviLayerInfo = '';
                }
              } else {
                let msg: string = this._languageService.instant('PROJ.NO_IMAGES_WITHIN', { x: this.dateSearchDuration });
                if (this.dashboardParams.healthIndexCloudValue < 100) {
                  msg += '<br>';
                  msg += this._languageService.instant('PROJ.NO_IMAGES_TRY', { x: this.dateSearchDuration });
                }
                this.imageInfo = this._languageService.instant('PROJ.NO_IMAGES');
                this.ndviLayerInfo = msg;
              }

              if (latestDate) {
                const imageDateString = latestDate.toString('dd-MMM-y');
                this.loadedImageDateString = imageDateString;

                if (
                  !this.imageDates.some(
                    (d) => d.provider == provider && d.date.toString('dd-MMM-y') == this.siteHealthDate.toString('dd-MMM-y') && d.imageDate.toString('dd-MMM-y') == imageDateString,
                  )
                ) {
                  this.imageDates.push({
                    provider: provider,
                    date: this.siteHealthDate,
                    imageDate: new Date(imageDateString),
                  });
                }
              }
            }
          },
          (reject) => {
            if (this.overlayType == overlay) {
              this.ndviLayerInfo = this._languageService.instant('COMMON.ERROR') + ' ' + reject;
              this.imageInfo = this._languageService.instant('PROJ.NO_IMAGERY_AVAILABLE');
            }
          })
          .catch((error) => {
            if (this.overlayType == overlay) {
              this.ndviLayerInfo = this._languageService.instant('COMMON.ERROR') + ' ' + error;
              this.imageInfo = this._languageService.instant('PROJ.NO_IMAGERY_AVAILABLE');
            }
          });
        } else if (this.overlayType == this.overlayTypes.TemporalVariation.value) {
          this.imageInfo = this._languageService.instant('COMMON.LOADING');

          const promises = [] as angular.IPromise<any>[];

          promises.push(
            this._healthIndexService.getImageInfo(
              eeConfig,
              this.dashboardParams.healthIndexCloudValue,
              mapBounds.getSouthWest().lat(),
              mapBounds.getSouthWest().lng(),
              mapBounds.getNorthEast().lat(),
              mapBounds.getNorthEast().lng(),
              this.siteHealthCompareDate,
              30,
            ),
          );

          promises.push(
            this._healthIndexService.getImageInfo(
              eeConfig,
              this.dashboardParams.healthIndexCloudValue,
              mapBounds.getSouthWest().lat(),
              mapBounds.getSouthWest().lng(),
              mapBounds.getNorthEast().lat(),
              mapBounds.getNorthEast().lng(),
              this.siteHealthDate,
              30,
            ),
          );

          this._q.all(promises).then((results) => {
            const info1 = results[0] as ImageInfo;
            const info2 = results[1] as ImageInfo;

            // Check we haven't changed overlay type
            if (this.overlayType == this.overlayTypes.TemporalVariation.value) {
              if (info1 && info2) {
                if (info1.date < this.siteHealthCompareDate || info2.date < this.siteHealthDate) {
                  this.ndviLayerInfo = this._languageService.instant('PROJ.NO_IMAGES_NEAREST');
                }

                this.imageInfo = this._languageService.instant('PROJ.IMAGERY_SUMMARY', {
                  provider: provider,
                  index: this.dashboardParams.mapHealthIndex,
                  date1: DateUtils.Locale.asDateMedium(info1.date),
                  cloud1: Math.floor(info1.cloud),
                  date2: DateUtils.Locale.asDateMedium(info2.date),
                  cloud2: Math.floor(info2.cloud),
                });
              } else {
                let msg = '';

                if (!info1 && !info2) {
                  msg = this._languageService.instant('PROJ.NO_IMAGES_FOR_DATES');
                } else if (!info1) {
                  msg = this._languageService.instant('PROJ.NO_IMAGE_SUITABLE') + DateUtils.Locale.asDateMedium(this.siteHealthCompareDate);
                } else {
                  msg = this._languageService.instant('PROJ.NO_IMAGE_SUITABLE') + DateUtils.Locale.asDateMedium(this.siteHealthDate);
                }

                if (this.dashboardParams.healthIndexCloudValue < 100) {
                  msg += '<br>';
                  msg += this._languageService.instant('PROJ.NO_IMAGES_TRY_DATE');
                }

                this.imageInfo = msg;
              }
            }
          },
          (reject) => {
            if (this.overlayType == this.overlayTypes.TemporalVariation.value) {
              this.ndviLayerInfo = this._languageService.instant('PROJ.COMPARISON_FAILED_ERROR') + reject;
              this.imageInfo = this._languageService.instant('PROJ.COMPARISON_FAILED');
            }
          })
          .catch((error) => {
            if (this.overlayType == this.overlayTypes.TemporalVariation.value) {
              this.ndviLayerInfo = this._languageService.instant('PROJ.COMPARISON_FAILED_ERROR') + error;
              this.imageInfo = this._languageService.instant('PROJ.COMPARISON_FAILED');
            }
          });
        }
      }, 500);
    }
  }

  private checkRefreshMap(provider: string) {
    if (!(this.overlayType == this.overlayTypes.SiteHealth.value || this.overlayType == this.overlayTypes.ProviderRGB.value)) {
      return true;
    }

    if (provider === this.loadedProvider && this.loadedImageDateString !== '') {
      //check first if you can see same date
      const data = this.imageDates.find((d) => d.provider == provider && d.date == this.siteHealthDate);

      if (data) {
        if (data.imageDate.toString('dd-MMM-y') === this.loadedImageDateString) {
          return false;
        }
      }

      if (
        this.imageDates.some(
          (d) =>
            d.provider == provider &&
            d.date >  this.siteHealthDate &&
            d.imageDate <= this.siteHealthDate &&
            d.imageDate.toString('dd-MMM-y') == this.loadedImageDateString,
        )
      ) {
        this.imageDates.push({
          provider: provider,
          date: this.siteHealthDate,
          imageDate: new Date(this.loadedImageDateString),
        });

        return false;
      }

      // if requesting next dates
      if (
        this.imageDates.some(
          (d) =>
            d.provider == provider &&
            d.date > this.siteHealthDate &&
            this.siteHealthDate >= d.imageDate &&
            d.imageDate.toString('dd-MMM-y') == this.loadedImageDateString,
        )
      ) {
        this.imageDates.push({
          provider: provider,
          date: this.siteHealthDate,
          imageDate: new Date(this.loadedImageDateString),
        });

        return false;
      }
    } else {
      this.loadedProvider = provider;
    }

    return true;
  }

  public handleFocusDatePicker(): void {
    UiHelper.handleFocusForMdDatePickerFullScreen(this._datePickerPanes);
  };

  public handleChangeHealthDate(days: number): void {
    if (days != 0) {
      this.siteHealthDate = this.siteHealthDate.clone().addDays(days);

      if (this.siteHealthDate > this.maxSiteHealthDate) {
        this.siteHealthDate = this.maxSiteHealthDate.clone();
      }
    }

    if (!this.checkRefreshMap(this.provider)) {
      return;
    }

    this.setHealthMinDate();
    this.setHealthDate();
  }

  private setHealthMinDate() {
    this.minSiteHealthDate = this.siteHealthDate.clone();
    this.minSiteHealthDate.addDays(-this.healthIndexMaximumAge);
  }

  private defineHealthChart() {
    this.healthChartOption = {
      dataSets: [] as AmCharts.DataSet[],
      panels: [] as AmCharts.StockPanel[],
      type: 'stock',
      chartScrollbarSettings: {
        color: 'white',
        backgroundColor: '#e5e5e5',
        selectedBackgroundColor: 'lightgray',
        height: 16,
      } as AmCharts.ChartScrollbarSettings,
      chartCursorSettings: {
        valueBalloonsEnabled: true,
        cursorAlpha: 0.3,
        categoryBalloonEnabled: true,
        categoryBalloonDateFormats: [
          { period: 'YYYY', format: 'MMM DD' },
          { period: 'MM', format: 'MMM DD' },
          { period: 'WW', format: 'MMM DD' },
          { period: 'DD', format: 'MMM DD' },
        ],
        cursorPosition: 'mouse',
      } as AmCharts.ChartCursorSettings,
      valueAxesSettings: {
        //inside: false,
      } as AmCharts.ValueAxesSettings,
      categoryAxesSettings: {
        maxSeries: 0,
        dateFormats: [
          { period: 'YYYY', format: 'MMM' },
          { period: 'MM', format: 'MMM' },
          { period: 'WW', format: 'MMM DD' },
          { period: 'DD', format: 'MMM DD' },
        ],
      } as AmCharts.CategoryAxesSettings,
      panelsSettings: {
        //plotAreaFillColors: '#333',
        //plotAreaFillAlphas: 1,
        //marginLeft: 60,
        marginTop: 0,
        marginBottom: 0,
      } as AmCharts.PanelsSettings,
      stockEventsSettings: {},
    } as any as AmCharts.AmStockChart;

    const dataSet = {
      dataProvider: this.healthChartDataSets.FilteredHealths,
      categoryField: 'localeDate',
      fieldMappings: [
        {
          fromField: 'quality',
          toField: 'quality',
        },
        {
          fromField: 'variability',
          toField: 'variability',
        },
        {
          fromField: 'readingTargetLow',
          toField: 'readingTargetLow',
        },
        {
          fromField: 'readingTargetHigh',
          toField: 'readingTargetHigh',
        },
        {
          fromField: 'readingTargetMaximum',
          toField: 'readingTargetMaximum',
        },
        {
          fromField: 'variabilityTargetLow',
          toField: 'variabilityTargetLow',
        },
        {
          fromField: 'variabilityTargetHigh',
          toField: 'variabilityTargetHigh',
        },
        {
          fromField: 'variabilityTargetMaximum',
          toField: 'variabilityTargetMaximum',
        },
      ],
    } as AmCharts.DataSet;

    for (let i = 0; i < 4; i++) {
      for (let j = 0; j < 8; j++) {
        if (j == 0) {
          const readingMapping = {
            fromField: 'source' + i + 'Reading',
            toField: 'source' + i + 'Reading',
          };
          dataSet.fieldMappings.push(readingMapping);
          const unreliableReadingMapping = {
            fromField: 'source' + i + 'Reading_unreliable',
            toField: 'source' + i + 'Reading_unreliable',
          };
          dataSet.fieldMappings.push(unreliableReadingMapping);
          const readingQualityMapping = {
            fromField: 'source' + i + 'ReadingQuality',
            toField: 'source' + i + 'ReadingQuality',
          };
          dataSet.fieldMappings.push(readingQualityMapping);
          const variabilityMapping = {
            fromField: 'source' + i + 'Variability',
            toField: 'source' + i + 'Variability',
          };
          dataSet.fieldMappings.push(variabilityMapping);
        } else {
          const readingMapping = {
            fromField: 'source' + i + 'ReadingPrevious' + j,
            toField: 'source' + i + 'ReadingPrevious' + j,
          };
          const unreliableReadingMapping = {
            fromField: 'source' + i + 'ReadingPrevious' + j + '_unreliable',
            toField: 'source' + i + 'ReadingPrevious' + j + '_unreliable',
          };
          dataSet.fieldMappings.push(unreliableReadingMapping);
          dataSet.fieldMappings.push(readingMapping);
          const readingQualityMapping = {
            fromField: 'source' + i + 'ReadingPrevious' + j + 'Quality',
            toField: 'source' + i + 'ReadingPrevious' + j + 'Quality',
          };
          dataSet.fieldMappings.push(readingQualityMapping);
          const variabilityMapping = {
            fromField: 'source' + i + 'VariabilityPrevious' + j,
            toField: 'source' + i + 'VariabilityPrevious' + j,
          };
          dataSet.fieldMappings.push(variabilityMapping);
        }
      }
    }

    this.healthChartOption.dataSets.push(dataSet);

    // #region reading panel
    const readingPanel = {
      stockGraphs: [] as AmCharts.StockGraph[],
      valueAxes: [] as AmCharts.ValueAxis[],
      chartCursor: {
        //categoryBalloonDateFormat: 'MMM DD',
        //cursorPosition: 'mouse',
        oneBalloonOnly: true,
      } as AmCharts.ChartCursor,
      balloon: {},
    } as AmCharts.StockPanel;

    readingPanel.title = this._languageService.instant('COMMON.READING');
    readingPanel.marginTop = 0;
    readingPanel.showCategoryAxis = false;
    readingPanel.percentHeight = 70;
    readingPanel.stockLegend = {
      showEntries: false,
      valueTextRegular: ' ',
      horizontalGap: 0,
      markerType: 'none',
      //useGraphSettings: true,
      textClickEnabled: true,
      marginTop: 0,
    } as AmCharts.StockLegend;

    const readingAxe = {
      maximum: this.healthChartReadingMax,
    } as AmCharts.ValueAxis;

    readingPanel.valueAxes.push(readingAxe);
    this._colourSetService.reset();

    for (let i = 0; i < 4; i++) {
      for (let j = 0; j < 8; j++) {
        if (j == 0) {
          if (this.healthChartDataSets.FilteredHealths.some((a) => a['source' + i + 'Reading'] != null)) {
            const providerName = this.dashboardHealthIndexSubscriptions[i].dataProviderName;
            const colorIndex = this.healthChartSubscriptionIndexes.find(
              (a) => a.dataProviderName == providerName && a.year == this.currentYear - j,
            ).index;
            const readingGraph = {
              id: this.dashboardHealthIndexSubscriptions[i].dataProviderName + ' ' + (this.currentYear - j),
              descriptionField: this.dashboardHealthIndexSubscriptions[i].dataProviderName,
              title: (this.currentYear - j).toString(),
              valueField: 'source' + i + 'Reading',
              bullet: 'none',
              bulletBorderAlpha: 1,
              bulletSize: 1,
              bulletColor: this._colourSetService.getColorByIndex(colorIndex),
              lineColor: this._colourSetService.getColorByIndex(colorIndex),
              lineAlpha: 1,
              lineThickness: 2,
              useDataSetColors: false,
              hidden: !this.dashboardHealthIndexSubscriptions[i].years.some((a) => a.year == this.currentYear && a.selected),
              balloonFunction: (graphDataItem, graph: AmCharts.AmGraph) => {
                const val = graphDataItem.dataContext as any;
                if (val) {
                  const date = (val.localeDate as Date).clone().addYears(-j);
                  const balloonText = `${graph.descriptionField}  ${DateUtils.Locale.asDateMedium(date)}<br>${
                    val['source' + i + 'ReadingClose']
                  }, ${100 - val['source' + i + 'ReadingQualityClose']}% ${this._languageService.instant('COMMON.cloud')}`;
                  //balloonText += `<br><button class="default-button" onclick="clickDefault('test')">Default</button>`;
                  return balloonText;
                } else {
                  return '';
                }
              },
              balloonColor: this._colourSetService.getColorByIndex(colorIndex),
            } as AmCharts.StockGraph;

            readingPanel.stockGraphs.push(readingGraph);
          }

          if (this.healthChartDataSets.FilteredHealths.some((a) => a['source' + i + 'Reading_unreliable'] != null)) {
            const providerName = this.dashboardHealthIndexSubscriptions[i].dataProviderName;
            const colorIndex = this.healthChartSubscriptionIndexes.find(
              (a) => a.dataProviderName == providerName && a.year == this.currentYear - j,
            ).index;
            const unreliableReadingGraph = {
              id: this.dashboardHealthIndexSubscriptions[i].dataProviderName + ' ' + (this.currentYear - j) + ' unreliable',
              descriptionField: this.dashboardHealthIndexSubscriptions[i].dataProviderName,
              title: (this.currentYear - j).toString(),
              valueField: 'source' + i + 'Reading_unreliable',
              bullet: 'round',
              bulletBorderAlpha: 1,
              bulletSize: 2,
              bulletColor: this._colourSetService.getColorByIndex(colorIndex),
              lineAlpha: 0,
              useDataSetColors: false,
              hidden: !this.dashboardHealthIndexSubscriptions[i].years.some((a) => a.year == this.currentYear && a.selected),
              balloonFunction: (graphDataItem, graph: AmCharts.AmGraph) => {
                const val = graphDataItem.dataContext as any;
                if (val) {
                  const date = (val.localeDate as Date).clone().addYears(-j);
                  const balloonText = `${graph.descriptionField}  ${DateUtils.Locale.asDateMedium(date)}<br>${
                    val['source' + i + 'Reading_unreliableClose']
                  }, ${100 - val['source' + i + 'ReadingQualityClose']}% ${this._languageService.instant('COMMON.cloud')}`;
                  return balloonText;
                } else {
                  return '';
                }
              },
              balloonColor: this._colourSetService.getColorByIndex(colorIndex),
            } as AmCharts.StockGraph;

            readingPanel.stockGraphs.push(unreliableReadingGraph);
          }
        } else {
          if (this.healthChartDataSets.FilteredHealths.some((a) => a['source' + i + 'ReadingPrevious' + j] != null)) {
            const providerName = this.dashboardHealthIndexSubscriptions[i].dataProviderName;
            const colorIndex = this.healthChartSubscriptionIndexes.find(
              (a) => a.dataProviderName == providerName && a.year == this.currentYear - j,
            ).index;
            const readingGraph = {
              id: this.dashboardHealthIndexSubscriptions[i].dataProviderName + ' ' + (this.currentYear - j),
              descriptionField: this.dashboardHealthIndexSubscriptions[i].dataProviderName,
              title: (this.currentYear - j).toString(),
              valueField: 'source' + i + 'ReadingPrevious' + j,
              bullet: 'none',
              bulletBorderAlpha: 1,
              bulletSize: 1,
              bulletColor: this._colourSetService.getColorByIndex(colorIndex),
              lineColor: this._colourSetService.getColorByIndex(colorIndex),
              lineAlpha: 0.8,
              lineThickness: 2,
              useDataSetColors: false,
              hidden: !this.dashboardHealthIndexSubscriptions[i].years.some((a) => a.year == this.currentYear - j && a.selected),
              balloonFunction: (graphDataItem, graph: AmCharts.AmGraph) => {
                const val = graphDataItem.dataContext as any;
                if (val) {
                  const date = (val.localeDate as Date).clone().addYears(-j);
                  const balloonText = `${graph.descriptionField}  ${DateUtils.Locale.asDateMedium(date)}<br>${
                    val['source' + i + 'ReadingPrevious' + j + 'Close']
                  }, ${100 - val['source' + i + 'ReadingPrevious' + j + 'QualityClose']}% ${this._languageService.instant(
                    'COMMON.cloud',
                  )}`;
                  return balloonText;
                } else {
                  return '';
                }
              },
              balloonColor: this._colourSetService.getColorByIndex(colorIndex),
            } as AmCharts.StockGraph;

            readingPanel.stockGraphs.push(readingGraph);
          }

          if (
            this.healthChartDataSets.FilteredHealths.some((a) => a['source' + i + 'ReadingPrevious' + j + '_unreliable'] != null)
          ) {
            const providerName = this.dashboardHealthIndexSubscriptions[i].dataProviderName;
            const colorIndex = this.healthChartSubscriptionIndexes.find(
              (a) => a.dataProviderName == providerName && a.year == this.currentYear - j,
            ).index;
            const unreliableReadingGraph = {
              id: this.dashboardHealthIndexSubscriptions[i].dataProviderName + ' ' + (this.currentYear - j) + 'unreliable',
              descriptionField: this.dashboardHealthIndexSubscriptions[i].dataProviderName,
              title: (this.currentYear - j).toString(),
              valueField: 'source' + i + 'ReadingPrevious' + j + '_unreliable',
              bullet: 'round',
              bulletBorderAlpha: 1,
              bulletSize: 2,
              bulletColor: this._colourSetService.getColorByIndex(colorIndex),
              lineAlpha: 0,
              useDataSetColors: false,
              hidden: !this.dashboardHealthIndexSubscriptions[i].years.some((a) => a.year == this.currentYear - j && a.selected),
              balloonFunction: (graphDataItem, graph: AmCharts.AmGraph) => {
                const val = graphDataItem.dataContext as any;
                if (val) {
                  const date = (val.localeDate as Date).clone().addYears(-j);
                  const balloonText = `${graph.descriptionField}  ${DateUtils.Locale.asDateMedium(date)}<br>${
                    val['source' + i + 'ReadingPrevious' + j + '_unreliableClose']
                  }, ${100 - val['source' + i + 'ReadingPrevious' + j + 'QualityClose']}% ${this._languageService.instant(
                    'COMMON.cloud',
                  )}`;
                  return balloonText;
                } else {
                  return '';
                }
              },
              balloonColor: this._colourSetService.getColorByIndex(colorIndex),
            } as AmCharts.StockGraph;

            readingPanel.stockGraphs.push(unreliableReadingGraph);
          }
        }
      }
    }

    const readingTargetLowGraph = {
      id: 'readingTargetLow',
      valueField: 'readingTargetLow',
      fillColors: ['#FFC107', '#FFFFFF'] as any,
      fillAlphas: 0.2,
      lineAlpha: 0,
      useDataSetColors: false,
      showBalloon: false,
    } as AmCharts.StockGraph;

    readingPanel.stockGraphs.push(readingTargetLowGraph);

    const readingTargetHighGraph = {
      id: 'readingTargetHigh',
      valueField: 'readingTargetHigh',
      fillColors: '#00FF00',
      fillAlphas: 0.2,
      fillToGraph: readingTargetLowGraph as AmCharts.AmGraph,
      lineAlpha: 0,
      useDataSetColors: false,
      showBalloon: false,
    } as AmCharts.StockGraph;

    readingPanel.stockGraphs.push(readingTargetHighGraph);

    const readingTargerMaximumGraph = {
      id: 'readingTargetMaximum',
      valueField: 'readingTargetMaximum',
      fillColors: ['#FFFFFF', '#0084CA'] as any,
      fillAlphas: 0.2,
      lineAlpha: 0,
      fillToGraph: readingTargetHighGraph as AmCharts.AmGraph,
      useDataSetColors: false,
      showBalloon: false,
    } as AmCharts.StockGraph;

    readingPanel.stockGraphs.push(readingTargerMaximumGraph);
    this.healthChartOption.panels.push(readingPanel);
    // #endregion

    // #region variability panel
    const variabilityPanel = {
      stockGraphs: [] as AmCharts.StockGraph[],
      valueAxes: [] as AmCharts.ValueAxis[],
      categoryAxis: {
        dateFormats: [
          { period: 'YYYY', format: 'MMM' },
          { period: 'MM', format: 'MMM' },
          { period: 'WW', format: 'MMM DD' },
          { period: 'DD', format: 'MMM DD' },
        ],
      },
      chartCursor: {
        //categoryBalloonDateFormat: 'MMM DD',
        cursorPosition: 'mouse',
      },
    } as AmCharts.StockPanel;

    variabilityPanel.title = this._languageService.instant('COMMON.VARIABILITY');
    variabilityPanel.marginTop = 0;
    variabilityPanel.showCategoryAxis = true;
    variabilityPanel.percentHeight = 30;
    variabilityPanel.gridAboveGraphs = false;
    //variabilityPanel.plotAreaFillColors = '#F27341';
    //variabilityPanel.plotAreaFillAlphas = 0.2;
    variabilityPanel.stockLegend = {
      showEntries: false,
      valueTextRegular: ' ',
      markerType: 'none',
      marginTop: 0,
    } as AmCharts.StockLegend;

    const variabilityAxe = {
      maximum: this.healthChartVariabilityMax,
      minimum: 0,
      strictMinMax: true,
    } as AmCharts.ValueAxis;

    variabilityPanel.valueAxes.push(variabilityAxe);

    this._colourSetService.reset();

    for (let i = 0; i < 4; i++) {
      for (let j = 0; j < 8; j++) {
        if (j == 0) {
          if (this.healthChartDataSets.FilteredHealths.some((a) => a['source' + i + 'Variability'] != null)) {
            const providerName = this.dashboardHealthIndexSubscriptions[i].dataProviderName;
            const colorIndex = this.healthChartSubscriptionIndexes.find(
              (a) => a.dataProviderName == providerName && a.year == this.currentYear - j,
            ).index;
            const variabilityGraph = {
              id: this.dashboardHealthIndexSubscriptions[i].dataProviderName + (this.currentYear - j),
              descriptionField: this.dashboardHealthIndexSubscriptions[i].dataProviderName,
              title: (this.currentYear - j).toString(),
              valueField: 'source' + i + 'Variability',
              lineColor: this._colourSetService.getColorByIndex(colorIndex),
              lineAlpha: 1,
              lineThickness: 1,
              useDataSetColors: false,
              hidden: !this.dashboardHealthIndexSubscriptions[i].years.some((a) => a.year == this.currentYear - j && a.selected),
              balloonFunction: (graphDataItem, graph: AmCharts.AmGraph) => {
                const val = graphDataItem.dataContext as any;
                if (val) {
                  const date = (val.localeDate as Date).clone().addYears(-j);
                  const balloonText = `${graph.descriptionField}  ${DateUtils.Locale.asDateMedium(date)} - ${
                    val['source' + i + 'VariabilityClose']
                  }`;
                  return balloonText;
                } else {
                  return '';
                }
              },
            } as AmCharts.StockGraph;

            variabilityPanel.stockGraphs.push(variabilityGraph);
          }
        } else {
          if (this.healthChartDataSets.FilteredHealths.some((a) => a['source' + i + 'VariabilityPrevious' + j] != null)) {
            const providerName = this.dashboardHealthIndexSubscriptions[i].dataProviderName;
            const colorIndex = this.healthChartSubscriptionIndexes.find(
              (a) => a.dataProviderName == providerName && a.year == this.currentYear - j,
            ).index;
            const variabilityGraph = {
              id: this.dashboardHealthIndexSubscriptions[i].dataProviderName + (this.currentYear - j),
              descriptionField: this.dashboardHealthIndexSubscriptions[i].dataProviderName,
              title: (this.currentYear - j).toString(),
              valueField: 'source' + i + 'VariabilityPrevious' + j,
              lineColor: this._colourSetService.getColorByIndex(colorIndex),
              lineAlpha: 1,
              lineThickness: 1,
              useDataSetColors: false,
              hidden: !this.dashboardHealthIndexSubscriptions[i].years.some((a) => a.year == this.currentYear - j && a.selected),
              balloonFunction: (graphDataItem, graph: AmCharts.AmGraph) => {
                const val = graphDataItem.dataContext as any;
                if (val) {
                  const date = (val.localeDate as Date).clone().addYears(-j);
                  const balloonText = `${graph.descriptionField}  ${DateUtils.Locale.asDateMedium(date)} - ${
                    val['source' + i + 'VariabilityPrevious' + j + 'Close']
                  }`;
                  return balloonText;
                } else {
                  return '';
                }
              },
            } as AmCharts.StockGraph;

            variabilityPanel.stockGraphs.push(variabilityGraph);
          }
        }
      }
    }

    const variabilityTargetLowGraph = {
      id: 'variabilityTargetLow',
      valueField: 'variabilityTargetLow',
      fillColors: '#00FF00',
      fillAlphas: 0.2,
      lineAlpha: 0,
      useDataSetColors: false,
      showBalloon: false,
    } as AmCharts.StockGraph;

    variabilityPanel.stockGraphs.push(variabilityTargetLowGraph);

    const variabilityTargetHighGraph = {
      id: 'variabilityTargetHigh',
      valueField: 'variabilityTargetHigh',
      fillColors: '#FFE69E',
      fillAlphas: 0.2,
      fillToGraph: variabilityTargetLowGraph as AmCharts.AmGraph,
      lineAlpha: 0,
      useDataSetColors: false,
      showBalloon: false,
    } as AmCharts.StockGraph;

    variabilityPanel.stockGraphs.push(variabilityTargetHighGraph);

    const variabilityTargerMaximumGraph = {
      id: 'variabilityTargetMaximum',
      valueField: 'variabilityTargetMaximum',
      fillColors: '#F27341',
      fillAlphas: 0.2,
      lineAlpha: 1,
      lineColor: '#d9d9d9',
      fillToGraph: variabilityTargetHighGraph as AmCharts.AmGraph,
      useDataSetColors: false,
      showBalloon: false,
    } as AmCharts.StockGraph;

    variabilityPanel.stockGraphs.push(variabilityTargerMaximumGraph);
    this.healthChartOption.panels.push(variabilityPanel);
    // #endregion
  }

  private addStockChartGuide(stockChart: AmCharts.AmStockChart) {
    if (!stockChart) {
      return;
    }

    const guideLine1: AmCharts.Guide = new AmCharts.Guide();
    guideLine1.date = this._dayNumberService.convertBrowserDateToLocaleDate(new Date(), this.account.timezoneId);
    guideLine1.above = true;
    guideLine1.lineColor = '#444444';
    guideLine1.lineAlpha = 0.4;
    guideLine1.inside = true;
    guideLine1.label = DateUtils.Locale.asDateDayAndMonth(guideLine1.date);
    guideLine1.labelRotation = 90;
    guideLine1.position = 'top';
    guideLine1.dashLength = 1;

    if (stockChart.panels[0].categoryAxis.addGuide !== undefined) {
      // remove any existing guides
      for (let idx = stockChart.panels[0].categoryAxis.guides.length - 1; idx >= 0; idx--) {
        stockChart.panels[0].categoryAxis.removeGuide(stockChart.panels[0].categoryAxis.guides[idx]);
      }

      stockChart.panels[0].categoryAxis.addGuide(guideLine1);
    } else {
      //  console.log('check categoryAxis');
    }

    const guideLine2: AmCharts.Guide = new AmCharts.Guide();
    guideLine2.date = this._dayNumberService.convertBrowserDateToLocaleDate(new Date(), this.account.timezoneId);
    guideLine2.above = true;
    guideLine2.lineColor = '#444444';
    guideLine2.lineAlpha = 0.4;
    guideLine2.inside = true;
    guideLine2.dashLength = 1;

    if (stockChart.panels[1].categoryAxis.addGuide !== undefined) {
      // remove any existing guides
      for (let idx = stockChart.panels[1].categoryAxis.guides.length - 1; idx >= 0; idx--) {
        stockChart.panels[1].categoryAxis.removeGuide(stockChart.panels[1].categoryAxis.guides[idx]);
      }

      stockChart.panels[1].categoryAxis.addGuide(guideLine2);
    } else {
      //  console.log('check categoryAxis');
    }

    stockChart.validateNow();
  }

  private createHealthChartDataSets(sensorId: number) {
    const dataSets = {} as IHealthChartDataSets;
    const sensor = this.accountSensors.find((a) => a.id == sensorId);
    const obsHealthReadings = this.assetObsHealthIndexes;

    let healthItems = [] as fuse.dashboardHealthChartItem[];

    if (obsHealthReadings) {
      obsHealthReadings.forEach((obsHealthReading) => {
        const healthReading = {} as fuse.dashboardHealthChartItem;
        const localeDate = moment(obsHealthReading.dateTime).toDate();
        healthReading.dayNumber = this._dayNumberService.convertLocaleDateToLocaleDayNumber(localeDate);
        healthReading.localeDate = localeDate;
        healthReading.quality = obsHealthReading.qualityFactor;
        healthReading.reading = obsHealthReading.reading;
        healthReading.reliable = obsHealthReading.reliable;
        healthReading.deemedReliable = obsHealthReading.deemedReliable;
        healthReading.variability = obsHealthReading.variability;
        healthReading.dataInputId = obsHealthReading.dataInputId;
        healthReading.dataProviderId = obsHealthReading.dataProviderId;
        healthItems.push(healthReading);
      });
    }

    const obsReadingTargetRanges = sensor.valueRanges.filter(
      (a) => a.dataInputId == this.dashboardParams.chartHealthIndex && a.attribute == 'Reading',
    );

    if (obsReadingTargetRanges) {
      obsReadingTargetRanges.forEach((obsReadingTarget) => {
        const localeDate = moment(obsReadingTarget.effectiveFrom).toDate();
        const dayNumber = this._dayNumberService.convertDateToLocaleDayNumber(localeDate, sensor.timezoneId);
        let readingTarget = healthItems.find((a) => a.dayNumber == dayNumber && a.dataInputId == obsReadingTarget.dataInputId);

        if (!readingTarget) {
          readingTarget = {} as fuse.dashboardHealthChartItem;
          readingTarget.dayNumber = dayNumber;
          readingTarget.localeDate = localeDate;
          readingTarget.readingTargetHigh = obsReadingTarget.readingHigh;
          readingTarget.readingTargetLow = obsReadingTarget.readingLow;
          readingTarget.dataInputId = obsReadingTarget.dataInputId;
          healthItems.push(readingTarget);
        } else {
          readingTarget.readingTargetHigh = obsReadingTarget.readingHigh;
          readingTarget.readingTargetLow = obsReadingTarget.readingLow;
        }
      });
    }

    const obsVariabilityTargetRanges = sensor.valueRanges.filter(
      (a) => a.dataInputId == this.dashboardParams.chartHealthIndex && a.attribute == 'Variability',
    );

    if (obsVariabilityTargetRanges) {
      obsVariabilityTargetRanges.forEach((obsVariabilityTarget) => {
        const localeDate = moment(obsVariabilityTarget.effectiveFrom).toDate();
        const dayNumber = this._dayNumberService.convertDateToLocaleDayNumber(localeDate, sensor.timezoneId);
        let variabilityTarget = healthItems.find(
          (a) => a.dayNumber == obsVariabilityTarget.dayNumber && a.dataInputId == obsVariabilityTarget.dataInputId,
        );

        if (!variabilityTarget) {
          variabilityTarget = {} as fuse.dashboardHealthChartItem;
          variabilityTarget.dayNumber = dayNumber;
          variabilityTarget.localeDate = localeDate;
          variabilityTarget.variabilityTargetHigh = obsVariabilityTarget.readingHigh;
          variabilityTarget.variabilityTargetLow = obsVariabilityTarget.readingLow;
          variabilityTarget.dataInputId = obsVariabilityTarget.dataInputId;
          healthItems.push(variabilityTarget);
        } else {
          variabilityTarget.variabilityTargetHigh = obsVariabilityTarget.readingHigh;
          variabilityTarget.variabilityTargetLow = obsVariabilityTarget.readingLow;
          variabilityTarget.dataInputId = obsVariabilityTarget.dataInputId;
        }
      });
    }

    healthItems = ArrayUtils.sortByNumber(healthItems, (a) => a.dayNumber);

    dataSets.Healths = healthItems;
    this.healthChartDataSets = dataSets;
  }

  private showHealthChart() {
    this.healthChart = AmCharts.makeChart('site-health-chart', this.healthChartOption) as any;
    this.healthChart.zoom(this.healthChartZoomStartDate, this.healthChartZoomEndDate);

    this.healthChart.addListener(
      'zoomed',
      (e: {
        /** Always "zoomed". */
        type: string;
        startDate: Date;
        endDate: Date;
        period: string;
        chart: AmCharts.AmStockChart;
      }) => {
        this.healthChartZoomStartDate = e.startDate;
        this.healthChartZoomEndDate = e.endDate;
      },
    );

    this.addStockChartGuide(this.healthChart);
  }

  public minimumHealthQualityChanged() {
    this.dashboardParams.minimumHealthQuality = 1 - this.maximumCloudCover;
    this._localStorageService.set('dashboardParams', this.dashboardParams);
    this.filterHealthItems();
    this.defineHealthChart();
    this.showHealthChart();
  }

  private filterHealthItems() {
    const minimumHealthQuality = this.dashboardParams.minimumHealthQuality;

    if (!this.healthChartDataSets?.Healths) {
      return;
    }

    //check for the end date
    let filteredHealths = angular.copy(this.healthChartDataSets.Healths);

    filteredHealths.forEach((healthItem) => {
      for (let i = 0; i < 3; i++) {
        for (let j = 0; j < 8; j++) {
          if (j == 0) {
            healthItem['source' + i + 'Reading'] = null;
            healthItem['source' + i + 'Reading_unreliable'] = null;
            healthItem['source' + i + 'Variability'] = null;
          } else {
            healthItem['source' + i + 'ReadingPrevious' + j] = null;
            healthItem['source' + i + 'ReadingPrevious' + j + '_unreliable'] = null;
            healthItem['source' + i + 'VariabilityPrevious' + j] = null;
          }
        }
      }
    });

    filteredHealths = filteredHealths.filter((a) => a.dataInputId == this.dashboardParams.chartHealthIndex);

    filteredHealths.forEach((filterHealth) => {
      if (filterHealth.quality != null && filterHealth.quality < minimumHealthQuality * 100) {
        filterHealth.quality = null;
        filterHealth.reading = null;
        filterHealth.variability = null;
      }
    });

    //#region start & end of chart
    const maximumReading = Math.max(...filteredHealths.filter((a) => a.reading != null).map((a) => a.reading));
    const maximumReadingTargetHigh = Math.max(
      ...filteredHealths.filter((a) => a.readingTargetHigh != null).map((a) => a.readingTargetHigh),
    );

    this.healthChartReadingMax = Math.ceil(Math.max(maximumReading, maximumReadingTargetHigh + 0.01) * 10) / 10;

    const maximumVariability = Math.max(...filteredHealths.filter((a) => a.variability != null).map((a) => a.variability));
    const maximumVariabilityTargetHigh = Math.max(
      ...filteredHealths.filter((a) => a.variabilityTargetHigh != null).map((a) => a.variabilityTargetHigh),
    );

    this.healthChartVariabilityMax = Math.ceil(Math.max(maximumVariability, maximumVariabilityTargetHigh + 0.01) * 10) / 10;

    let endReadingTarget = filteredHealths.find((a) => a.dayNumber == this.healthChartEndDayNumber && a.readingTargetHigh != null);

    if (!endReadingTarget) {
      const afterEndReadingTarget = filteredHealths.find(
        (a) => a.dayNumber > this.healthChartEndDayNumber && a.readingTargetHigh != null,
      );
      const beforeEndReadingTarget = filteredHealths
        .slice()
        .reverse()
        .find((a) => a.dayNumber < this.healthChartEndDayNumber && a.readingTargetHigh != null);

      if (!afterEndReadingTarget && !beforeEndReadingTarget) {
      } else if (afterEndReadingTarget && !beforeEndReadingTarget) {
        endReadingTarget = {
          dayNumber: this.healthChartEndDayNumber,
          localeDate: this.healthChartEndDate.clone(),
          readingTargetHigh: afterEndReadingTarget.readingTargetHigh,
          readingTargetLow: afterEndReadingTarget.readingTargetLow,
          readingTargetMaximum: this.healthChartReadingMax,
        } as fuse.dashboardHealthChartItem;

        filteredHealths.push(endReadingTarget);
      } else if (!afterEndReadingTarget && beforeEndReadingTarget) {
        endReadingTarget = {
          dayNumber: this.healthChartEndDayNumber,
          localeDate: this.healthChartEndDate.clone(),
          readingTargetHigh: beforeEndReadingTarget.readingTargetHigh,
          readingTargetLow: beforeEndReadingTarget.readingTargetLow,
          readingTargetMaximum: this.healthChartReadingMax,
        } as fuse.dashboardHealthChartItem;

        filteredHealths.push(endReadingTarget);
      } else if (afterEndReadingTarget && beforeEndReadingTarget) {
        endReadingTarget = {
          dayNumber: this.healthChartEndDayNumber,
          localeDate: this.healthChartEndDate.clone(),
          readingTargetHigh: NumberUtils.round(
            beforeEndReadingTarget.readingTargetHigh +
              ((afterEndReadingTarget.readingTargetHigh - beforeEndReadingTarget.readingTargetHigh) *
                (this.healthChartEndDayNumber - beforeEndReadingTarget.dayNumber)) /
                (afterEndReadingTarget.dayNumber - beforeEndReadingTarget.dayNumber),
            2,
          ),
          readingTargetLow: NumberUtils.round(
            beforeEndReadingTarget.readingTargetLow +
              ((afterEndReadingTarget.readingTargetLow - beforeEndReadingTarget.readingTargetLow) *
                (this.healthChartEndDayNumber - beforeEndReadingTarget.dayNumber)) /
                (afterEndReadingTarget.dayNumber - beforeEndReadingTarget.dayNumber),
            2,
          ),
          readingTargetMaximum: this.healthChartReadingMax,
        } as fuse.dashboardHealthChartItem;

        filteredHealths.push(endReadingTarget);
      }
    }

    let endVariabilityTarget = filteredHealths.find(
      (a) => a.dayNumber == this.healthChartEndDayNumber && a.variabilityTargetHigh != null,
    );

    if (!endVariabilityTarget) {
      const afterEndVariabilityTarget = filteredHealths.find(
        (a) => a.dayNumber > this.healthChartEndDayNumber && a.variabilityTargetHigh != null,
      );
      const beforeEndVariabilityTarget = filteredHealths
        .slice()
        .reverse()
        .find((a) => a.dayNumber < this.healthChartEndDayNumber && a.variabilityTargetHigh != null);

      if (!afterEndVariabilityTarget && !beforeEndVariabilityTarget) {
      } else if (afterEndVariabilityTarget && !beforeEndVariabilityTarget) {
        endVariabilityTarget = filteredHealths.find((a) => a.dayNumber == this.healthChartEndDayNumber);

        if (!endReadingTarget) {
          endVariabilityTarget = {
            dayNumber: this.healthChartEndDayNumber,
            localeDate: this.healthChartEndDate.clone(),
            variabilityTargetHigh: afterEndVariabilityTarget.variabilityTargetHigh,
            variabilityTargetLow: afterEndVariabilityTarget.variabilityTargetLow,
            variabilityTargetMaximum: this.healthChartVariabilityMax,
          } as fuse.dashboardHealthChartItem;

          filteredHealths.push(endVariabilityTarget);
        } else {
          endReadingTarget.variabilityTargetHigh = afterEndVariabilityTarget.variabilityTargetHigh;
          endReadingTarget.variabilityTargetLow = afterEndVariabilityTarget.variabilityTargetLow;
          endReadingTarget.variabilityTargetMaximum = this.healthChartVariabilityMax;
        }
      } else if (!afterEndVariabilityTarget && beforeEndVariabilityTarget) {
        endVariabilityTarget = filteredHealths.find((a) => a.dayNumber == this.healthChartEndDayNumber);

        if (!endReadingTarget) {
          endVariabilityTarget = {
            dayNumber: this.healthChartEndDayNumber,
            localeDate: this.healthChartEndDate.clone(),
            variabilityTargetHigh: beforeEndVariabilityTarget.variabilityTargetHigh,
            variabilityTargetLow: beforeEndVariabilityTarget.variabilityTargetLow,
            variabilityTargetMaximum: this.healthChartVariabilityMax,
          } as fuse.dashboardHealthChartItem;

          filteredHealths.push(endVariabilityTarget);
        } else {
          endReadingTarget.variabilityTargetHigh = beforeEndVariabilityTarget.variabilityTargetHigh;
          endReadingTarget.variabilityTargetLow = beforeEndVariabilityTarget.variabilityTargetLow;
          endReadingTarget.variabilityTargetMaximum = this.healthChartVariabilityMax;
        }
      } else if (afterEndVariabilityTarget && beforeEndVariabilityTarget) {
        endVariabilityTarget = filteredHealths.find((a) => a.dayNumber == this.healthChartEndDayNumber);

        if (!endReadingTarget) {
          endVariabilityTarget = {
            dayNumber: this.healthChartEndDayNumber,
            localeDate: this.healthChartEndDate.clone(),
            variabilityTargetHigh: NumberUtils.round(
              beforeEndVariabilityTarget.variabilityTargetHigh +
                ((afterEndVariabilityTarget.variabilityTargetHigh - beforeEndVariabilityTarget.variabilityTargetHigh) *
                  (this.healthChartEndDayNumber - beforeEndVariabilityTarget.dayNumber)) /
                  (afterEndVariabilityTarget.dayNumber - beforeEndVariabilityTarget.dayNumber),
              2,
            ),
            variabilityTargetLow: NumberUtils.round(
              beforeEndVariabilityTarget.variabilityTargetLow +
                ((afterEndVariabilityTarget.variabilityTargetLow - beforeEndVariabilityTarget.variabilityTargetLow) *
                  (this.healthChartEndDayNumber - beforeEndVariabilityTarget.dayNumber)) /
                  (afterEndVariabilityTarget.dayNumber - beforeEndVariabilityTarget.dayNumber),
              2,
            ),
            variabilityTargetMaximum: this.healthChartVariabilityMax,
          } as fuse.dashboardHealthChartItem;
          filteredHealths.push(endVariabilityTarget);
        } else {
          endReadingTarget.variabilityTargetHigh = NumberUtils.round(
            beforeEndVariabilityTarget.variabilityTargetHigh +
              ((afterEndVariabilityTarget.variabilityTargetHigh - beforeEndVariabilityTarget.variabilityTargetHigh) *
                (this.healthChartEndDayNumber - beforeEndVariabilityTarget.dayNumber)) /
                (afterEndVariabilityTarget.dayNumber - beforeEndVariabilityTarget.dayNumber),
            2,
          );
          endReadingTarget.variabilityTargetLow = NumberUtils.round(
            beforeEndVariabilityTarget.variabilityTargetLow +
              ((afterEndVariabilityTarget.variabilityTargetLow - beforeEndVariabilityTarget.variabilityTargetLow) *
                (this.healthChartEndDayNumber - beforeEndVariabilityTarget.dayNumber)) /
                (afterEndVariabilityTarget.dayNumber - beforeEndVariabilityTarget.dayNumber),
            2,
          );
          endReadingTarget.variabilityTargetMaximum = this.healthChartVariabilityMax;
        }
      }
    }

    let startReadingTarget = filteredHealths.find(
      (a) => a.dayNumber == this.healthChartStartDayNumber && a.readingTargetHigh != null,
    );

    if (!startReadingTarget) {
      const afterStartReadingTarget = filteredHealths.find(
        (a) => a.dayNumber > this.healthChartStartDayNumber && a.readingTargetHigh != null,
      );
      const beforeStartReadingTarget = filteredHealths
        .slice()
        .reverse()
        .find((a) => a.dayNumber < this.healthChartStartDayNumber && a.readingTargetHigh != null);

      if (!afterStartReadingTarget && !beforeStartReadingTarget) {
      } else if (afterStartReadingTarget && !beforeStartReadingTarget) {
        startReadingTarget = {
          dayNumber: this.healthChartStartDayNumber,
          localeDate: this.healthChartStartDate.clone(),
          readingTargetHigh: afterStartReadingTarget.readingTargetHigh,
          readingTargetLow: afterStartReadingTarget.readingTargetLow,
          readingTargetMaximum: this.healthChartReadingMax,
        } as fuse.dashboardHealthChartItem;

        filteredHealths.push(startReadingTarget);
      } else if (!afterStartReadingTarget && beforeStartReadingTarget) {
        startReadingTarget = {
          dayNumber: this.healthChartStartDayNumber,
          localeDate: this.healthChartStartDate.clone(),
          readingTargetHigh: beforeStartReadingTarget.readingTargetHigh,
          readingTargetLow: beforeStartReadingTarget.readingTargetLow,
          readingTargetMaximum: this.healthChartReadingMax,
        } as fuse.dashboardHealthChartItem;

        filteredHealths.push(startReadingTarget);
      } else if (afterStartReadingTarget && beforeStartReadingTarget) {
        startReadingTarget = {
          dayNumber: this.healthChartStartDayNumber,
          localeDate: this.healthChartStartDate.clone(),
          readingTargetHigh: NumberUtils.round(
            beforeStartReadingTarget.readingTargetHigh +
              ((afterStartReadingTarget.readingTargetHigh - beforeStartReadingTarget.readingTargetHigh) *
                (this.healthChartStartDayNumber - beforeStartReadingTarget.dayNumber)) /
                (afterStartReadingTarget.dayNumber - beforeStartReadingTarget.dayNumber),
            2,
          ),
          readingTargetLow: NumberUtils.round(
            beforeStartReadingTarget.readingTargetLow +
              ((afterStartReadingTarget.readingTargetLow - beforeStartReadingTarget.readingTargetLow) *
                (this.healthChartStartDayNumber - beforeStartReadingTarget.dayNumber)) /
                (afterStartReadingTarget.dayNumber - beforeStartReadingTarget.dayNumber),
            2,
          ),
          readingTargetMaximum: this.healthChartReadingMax,
        } as fuse.dashboardHealthChartItem;

        filteredHealths.push(startReadingTarget);
      }
    }

    let startVariabilityTarget = filteredHealths.find(
      (a) => a.dayNumber == this.healthChartStartDayNumber && a.variabilityTargetHigh != null,
    );

    if (!startVariabilityTarget) {
      const afterStartVariabilityTarget = filteredHealths.find(
        (a) => a.dayNumber > this.healthChartStartDayNumber && a.variabilityTargetHigh != null,
      );
      const beforeStartVariabilityTarget = filteredHealths
        .slice()
        .reverse()
        .find((a) => a.dayNumber < this.healthChartStartDayNumber && a.variabilityTargetHigh != null);

      if (!afterStartVariabilityTarget && !beforeStartVariabilityTarget) {
      } else if (afterStartVariabilityTarget && !beforeStartVariabilityTarget) {
        startVariabilityTarget = filteredHealths.find((a) => a.dayNumber == this.healthChartStartDayNumber);

        if (!startVariabilityTarget) {
          startVariabilityTarget = {
            dayNumber: this.healthChartStartDayNumber,
            localeDate: this.healthChartStartDate.clone(),
            variabilityTargetHigh: afterStartVariabilityTarget.variabilityTargetHigh,
            variabilityTargetLow: afterStartVariabilityTarget.variabilityTargetLow,
            variabilityTargetMaximum: this.healthChartVariabilityMax,
          } as fuse.dashboardHealthChartItem;
          filteredHealths.push(startVariabilityTarget);
        } else {
          startReadingTarget.variabilityTargetHigh = afterStartVariabilityTarget.variabilityTargetHigh;
          startReadingTarget.variabilityTargetLow = afterStartVariabilityTarget.variabilityTargetLow;
          startReadingTarget.variabilityTargetMaximum = this.healthChartVariabilityMax;
        }
      } else if (!afterStartVariabilityTarget && beforeStartVariabilityTarget) {
        startVariabilityTarget = filteredHealths.find((a) => a.dayNumber == this.healthChartStartDayNumber);

        if (!startVariabilityTarget) {
          startVariabilityTarget = {
            dayNumber: this.healthChartStartDayNumber,
            localeDate: this.healthChartStartDate.clone(),
            variabilityTargetHigh: beforeStartVariabilityTarget.variabilityTargetHigh,
            variabilityTargetLow: beforeStartVariabilityTarget.variabilityTargetLow,
            variabilityTargetMaximum: this.healthChartVariabilityMax,
          } as fuse.dashboardHealthChartItem;

          filteredHealths.push(startVariabilityTarget);
        } else {
          startReadingTarget.variabilityTargetHigh = beforeStartVariabilityTarget.variabilityTargetHigh;
          startReadingTarget.variabilityTargetLow = beforeStartVariabilityTarget.variabilityTargetLow;
          startReadingTarget.variabilityTargetMaximum = this.healthChartVariabilityMax;
        }
      } else if (afterStartVariabilityTarget && beforeStartVariabilityTarget) {
        startVariabilityTarget = filteredHealths.find((a) => a.dayNumber == this.healthChartStartDayNumber);

        if (!startVariabilityTarget) {
          startVariabilityTarget = {
            dayNumber: this.healthChartStartDayNumber,
            localeDate: this.healthChartStartDate.clone(),
            variabilityTargetHigh: NumberUtils.round(
              beforeStartVariabilityTarget.variabilityTargetHigh +
                ((afterStartVariabilityTarget.variabilityTargetHigh - beforeStartVariabilityTarget.variabilityTargetHigh) *
                  (this.healthChartStartDayNumber - beforeStartVariabilityTarget.dayNumber)) /
                  (afterStartVariabilityTarget.dayNumber - beforeStartVariabilityTarget.dayNumber),
              2,
            ),
            variabilityTargetLow: NumberUtils.round(
              beforeStartVariabilityTarget.variabilityTargetLow +
                ((afterStartVariabilityTarget.variabilityTargetLow - beforeStartVariabilityTarget.variabilityTargetLow) *
                  (this.healthChartStartDayNumber - beforeStartVariabilityTarget.dayNumber)) /
                  (afterStartVariabilityTarget.dayNumber - beforeStartVariabilityTarget.dayNumber),
              2,
            ),
            variabilityTargetMaximum: this.healthChartVariabilityMax,
          } as fuse.dashboardHealthChartItem;

          filteredHealths.push(startVariabilityTarget);
        } else {
          startVariabilityTarget.variabilityTargetHigh = NumberUtils.round(
            beforeStartVariabilityTarget.variabilityTargetHigh +
              ((afterStartVariabilityTarget.variabilityTargetHigh - beforeStartVariabilityTarget.variabilityTargetHigh) *
                (this.healthChartStartDayNumber - beforeStartVariabilityTarget.dayNumber)) /
                (afterStartVariabilityTarget.dayNumber - beforeStartVariabilityTarget.dayNumber),
            2,
          );
          startVariabilityTarget.variabilityTargetLow = NumberUtils.round(
            beforeStartVariabilityTarget.variabilityTargetLow +
              ((afterStartVariabilityTarget.variabilityTargetLow - beforeStartVariabilityTarget.variabilityTargetLow) *
                (this.healthChartStartDayNumber - beforeStartVariabilityTarget.dayNumber)) /
                (afterStartVariabilityTarget.dayNumber - beforeStartVariabilityTarget.dayNumber),
            2,
          );
          startVariabilityTarget.variabilityTargetMaximum = this.healthChartVariabilityMax;
        }
      }
    }

    filteredHealths = ArrayUtils.sortByNumber(filteredHealths, (x) => x.dayNumber);
    //#endregion

    for (let i = 0; i < this.dashboardHealthIndexSubscriptions.length; i++) {
      const dataProvider = this.dashboardHealthIndexSubscriptions[i];
      const providerHealths = filteredHealths.filter((a) => a.dataProviderId == dataProvider.dataProviderId);
      //catalog healthindex according the year. maximum for 8 years
      for (let j = 0; j < 8; j++) {
        const year = this.currentYear - j;
        const month = this.dashboardAccountInfo.irrigationSeasonStartMonth - 1;
        const startDate = new Date(year, month);
        const startDayNumber = this._dayNumberService.convertLocaleDateToLocaleDayNumber(startDate);
        const endDate = startDate.clone().addMonths(12).addDays(-1);
        const endDayNumber = this._dayNumberService.convertLocaleDateToLocaleDayNumber(endDate);
        const providerYearHealths = providerHealths.filter((a) => a.dayNumber >= startDayNumber && a.dayNumber <= endDayNumber);

        if (providerYearHealths.length) {
          const subscriptionYear = dataProvider.years.find((a) => a.year == year);

          subscriptionYear.available = true;

          providerYearHealths.forEach((providerHealth) => {
            const month = providerHealth.localeDate.getMonth();
            const date = new Date(
              this.currentYear + (month >= this.dashboardAccountInfo.irrigationSeasonStartMonth - 1 ? 0 : 1),
              providerHealth.localeDate.getMonth(),
              providerHealth.localeDate.getDate(),
            );

            const dayNumber = this._dayNumberService.convertLocaleDateToLocaleDayNumber(date);
            let dayNumberHealth = filteredHealths.find((a) => a.dayNumber == dayNumber);

            if (!dayNumberHealth) {
              dayNumberHealth = {
                localeDate: date,
                dayNumber: dayNumber,
              } as fuse.dashboardHealthChartItem;

              filteredHealths.push(dayNumberHealth);

              filteredHealths = ArrayUtils.sortByNumber(filteredHealths, (x) => x.dayNumber)
            }

            if (dayNumberHealth.readingTargetHigh == null) {
              const beforeReadingTarget = filteredHealths
                .slice()
                .reverse()
                .find((a) => a.dayNumber < dayNumber && a.readingTargetHigh != null);
              const afterReadingTarget = filteredHealths.find((a) => a.dayNumber > dayNumber && a.readingTargetHigh != null);

              if (beforeReadingTarget) {
                dayNumberHealth.readingTargetHigh =
                  beforeReadingTarget.readingTargetHigh +
                  ((afterReadingTarget.readingTargetHigh - beforeReadingTarget.readingTargetHigh) *
                    (dayNumber - beforeReadingTarget.dayNumber)) /
                    (afterReadingTarget.dayNumber - beforeReadingTarget.dayNumber);

                dayNumberHealth.readingTargetLow =
                  beforeReadingTarget.readingTargetLow +
                  ((afterReadingTarget.readingTargetLow - beforeReadingTarget.readingTargetLow) *
                    (dayNumber - beforeReadingTarget.dayNumber)) /
                    (afterReadingTarget.dayNumber - beforeReadingTarget.dayNumber);

                dayNumberHealth.readingTargetMaximum = this.healthChartReadingMax;
              }
            }

            if (dayNumberHealth.variabilityTargetHigh == null) {
              const beforeVariabilityTarget = filteredHealths
                .slice()
                .reverse()
                .find((a) => a.dayNumber < dayNumber && a.variabilityTargetHigh != null);

              const afterVariabilityTarget = filteredHealths.find(
                (a) => a.dayNumber > dayNumber && a.variabilityTargetHigh != null,
              );

              if (beforeVariabilityTarget) {
                dayNumberHealth.variabilityTargetHigh =
                  beforeVariabilityTarget.variabilityTargetHigh +
                  ((afterVariabilityTarget.variabilityTargetHigh - beforeVariabilityTarget.variabilityTargetHigh) *
                    (dayNumber - beforeVariabilityTarget.dayNumber)) /
                    (afterVariabilityTarget.dayNumber - beforeVariabilityTarget.dayNumber);

                dayNumberHealth.variabilityTargetLow =
                  beforeVariabilityTarget.variabilityTargetLow +
                  ((afterVariabilityTarget.variabilityTargetLow - beforeVariabilityTarget.variabilityTargetLow) *
                    (dayNumber - beforeVariabilityTarget.dayNumber)) /
                    (afterVariabilityTarget.dayNumber - beforeVariabilityTarget.dayNumber);

                dayNumberHealth.variabilityTargetMaximum = this.healthChartVariabilityMax;
              }
            }

            let isReliable = providerHealth.reliable;

            if (!isReliable) {
              // if providerHealth.reliable is null, the isReliable is determined by deemedReliabled
              // let upperReliableThreshold = dayNumberHealth.readingTargetHigh + (dataInputMaximum - dayNumberHealth.readingTargetHigh) * upperReliablePercent;
              // let lowerReliableThreshold = dayNumberHealth.readingTargetLow - (dayNumberHealth.readingTargetLow - dataInputMinimum) * lowerReliablePercent;
              // if (lowerReliableThreshold <= providerHealth.reading && providerHealth.reading <= upperReliableThreshold) {
              //    isReliable = true;
              // } else {
              //    isReliable = false;
              // }
              isReliable = providerHealth.deemedReliable;
            }

            if (j == 0) {
              if (isReliable == true) {
                dayNumberHealth['source' + i + 'Reading'] = providerHealth.reading;
              } else {
                dayNumberHealth['source' + i + 'Reading_unreliable'] = providerHealth.reading;
              }

              dayNumberHealth['source' + i + 'ReadingQuality'] = providerHealth.quality;
              dayNumberHealth['source' + i + 'Variability'] = providerHealth.variability;
            } else {
              if (isReliable) {
                dayNumberHealth['source' + i + 'ReadingPrevious' + j] = providerHealth.reading;
              } else {
                dayNumberHealth['source' + i + 'ReadingPrevious' + j + '_unreliable'] = providerHealth.reading;
              }

              dayNumberHealth['source' + i + 'ReadingPrevious' + j + 'Quality'] = providerHealth.quality;
              dayNumberHealth['source' + i + 'VariabilityPrevious' + j] = providerHealth.variability;
            }
          });
        }
      }

      dataProvider.selectedYearsString = dataProvider.years
        .filter((a) => a.selected)
        .map((a) => a.year)
        .join(',');
    }
    //#endregion

    //need fill no-data days to make chart cursor move smoothly
    //need high/low value, otherwise the fill-area may be error
    filteredHealths = ArrayUtils.sortByNumber(filteredHealths, (x) => x.dayNumber)

    const amendHealths = [] as fuse.dashboardHealthChartItem[];

    for (let i = this.healthChartStartDayNumber; i <= this.healthChartEndDayNumber; i++) {
      const filterHealth = filteredHealths.find((a) => a.dayNumber == i);

      if (!filterHealth) {
        const beforeReadingTarget = filteredHealths
          .slice()
          .reverse()
          .find((a) => a.dayNumber < i && a.readingTargetHigh != null);
        const afterReadingTarget = filteredHealths.find((a) => a.dayNumber > i && a.readingTargetHigh != null);
        const beforeVariabilityTarget = filteredHealths
          .slice()
          .reverse()
          .find((a) => a.dayNumber < i && a.variabilityTargetHigh != null);
        const afterVariabilityTarget = filteredHealths.find((a) => a.dayNumber > i && a.variabilityTargetHigh != null);

        if (beforeReadingTarget && beforeVariabilityTarget) {
          const dayHealth = {
            localeDate: this._dayNumberService.convertDayNumberToDate(i),
            dayNumber: i,
            readingTargetHigh:
              beforeReadingTarget.readingTargetHigh +
              ((afterReadingTarget.readingTargetHigh - beforeReadingTarget.readingTargetHigh) *
                (i - beforeReadingTarget.dayNumber)) /
                (afterReadingTarget.dayNumber - beforeReadingTarget.dayNumber),
            readingTargetLow:
              beforeReadingTarget.readingTargetLow +
              ((afterReadingTarget.readingTargetLow - beforeReadingTarget.readingTargetLow) *
                (i - beforeReadingTarget.dayNumber)) /
                (afterReadingTarget.dayNumber - beforeReadingTarget.dayNumber),
            readingTargetMaximum: this.healthChartReadingMax,
            variabilityTargetHigh:
              beforeVariabilityTarget.variabilityTargetHigh +
              ((afterVariabilityTarget.variabilityTargetHigh - beforeVariabilityTarget.variabilityTargetHigh) *
                (i - beforeVariabilityTarget.dayNumber)) /
                (afterVariabilityTarget.dayNumber - beforeVariabilityTarget.dayNumber),
            variabilityTargetLow:
              beforeVariabilityTarget.variabilityTargetLow +
              ((afterVariabilityTarget.variabilityTargetLow - beforeVariabilityTarget.variabilityTargetLow) *
                (i - beforeVariabilityTarget.dayNumber)) /
                (afterVariabilityTarget.dayNumber - beforeVariabilityTarget.dayNumber),
            variabilityTargetMaximum: this.healthChartVariabilityMax,
          } as fuse.dashboardHealthChartItem;

          amendHealths.push(dayHealth);
        }
      }
    }

    filteredHealths.push(...amendHealths);

    filteredHealths = filteredHealths.filter(
      (a) => a.dayNumber >= this.healthChartStartDayNumber && a.dayNumber <= this.healthChartEndDayNumber,
    );

    filteredHealths = ArrayUtils.sortByNumber(filteredHealths, (x) => x.dayNumber)

    filteredHealths.forEach((filteredHealth) => {
      if (filteredHealth.readingTargetHigh != null && filteredHealth.readingTargetMaximum == null) {
        filteredHealth.readingTargetMaximum = this.healthChartReadingMax;
      }

      if (filteredHealth.variabilityTargetHigh != null && filteredHealth.variabilityTargetMaximum == null) {
        filteredHealth.variabilityTargetMaximum = this.healthChartVariabilityMax;
      }
    });

    this.healthChartDataSets.FilteredHealths = filteredHealths;
  }

  public clickChartHealthIndexButton(dataInputId: number) {
    if (this.isTableHealthIndexChanged) {
      return;
    }

    this.dashboardParams.chartHealthIndex = dataInputId;
    this._localStorageService.set('dashboardParams', this.dashboardParams);
    this.healthChartSubscriptionIndexesCopy = angular.copy(this.healthChartSubscriptionIndexes);

    this.createDashboardIndexSources();
    this.createSubscriptionYearListboxItems();

    this.getAssetObsHealthIndexes(this.currentSensorId, this.dashboardParams.chartHealthIndex).then(() => {
      if (this.isHealthIndexTableShown) {
        const dataProvider = this.healthChartSubscriptionIndexes.find((a) => a.selected);
        const filteredAssetObsHealthIndexes = this.assetObsHealthIndexes.filter(
          (a) =>
            a.dataInputId == this.dashboardParams.chartHealthIndex &&
            a.dataProviderId == dataProvider.dataProviderId &&
            a.dayNumber >= dataProvider.seasonStartDayNumber &&
            a.dayNumber <= dataProvider.seasonEndDayNumber,
        );
        this.healthIndexTableDataSource = angular.copy(filteredAssetObsHealthIndexes);
      } else {
        this.createHealthChartDataSets(this.currentSensorId);
        this.filterHealthItems();
        this.defineHealthChart();
        this.showHealthChart();
      }
    });
  }

  private showHealthChartModal() {
    this._mdSidenav('healthChartModal').open();
    this.setSiteHealthMarkerAndShapes();
  }

  public closeHealthChartModal() {
    this._mdSidenav('healthChartModal').close();
    this.setSiteHealthMarkerAndShapes();
  }

  private clickHealthShape(sensorId: number) {
    this.clickHealthMarker(sensorId);
  }

  private clickHealthMarker(sensorId: number) {
    this.healthChartSubscriptionIndexesCopy = angular.copy(this.healthChartSubscriptionIndexes);

    const isHealthModalOpened = this._mdSidenav('healthChartModal').isOpen();
    const isInfoModalOpened = this._mdSidenav('infoModal').isOpen();
    if (!isHealthModalOpened) {
      this._mdSidenav('infoModal').close();
      this._mdSidenav('mapSettingsModal').close();
      this.closeMapToolsModal();
      this.showHealthChartModal();
    }

    if (sensorId == this.currentSensorId) {
      return;
    }

    if (!isHealthModalOpened && !isInfoModalOpened) {
      const sensor = this.accountSensors.find((a) => a.id == sensorId);
      this.setMapCenter(sensor.latitude, sensor.longitude);
    }
    this.setSelectedHealthChart(sensorId);
  }

  private setMapCenter(lat: number, lng: number) {
    this.map.setCenter(new google.maps.LatLng(lat, lng));

    const bound = this.map.getBounds();

    if (!bound) {
      return;
    }

    const x1 = bound.getNorthEast().lng() - lng;
    const x2 = (bound.getNorthEast().lng() - bound.getSouthWest().lng()) / 4;

    if (x1 > x2) {
      const deltaX = x1 - x2;
      const newCenter = new google.maps.LatLng(lat, lng - deltaX);

      this.map.setCenter(newCenter);
    }
  }

  private setMapRightCenter(lat: number, lng: number) {
    this.map.setCenter(new google.maps.LatLng(lat, lng));
    this.map.setZoom(16);

    const bound = this.map.getBounds();

    if (!bound) {
      return;
    }

    const deltaX = (bound.getNorthEast().lng() - bound.getSouthWest().lng()) / 4;
    const newCenter = new google.maps.LatLng(lat, lng - deltaX);

    this.map.setCenter(newCenter);
  }

  private setSelectedHealthChart(sensorId: number) {
    if (this.assetObsHealthIndexes?.find((a) => a.sensorId == sensorId)) {
      return;
    }

    //first step: get available data type and year and data
    this.getAssetHealthIndexes(sensorId, this.dashboardParams.chartHealthIndex).then(() => {
      if (!this.indexYears?.length) {
        if (this.healthChart) {
          this.healthChart.clear();
          this.healthChart = null;
        }
      } else {
        //if no data, call the function
        const promises = [] as angular.IPromise<any>[];

        if (!this.assetObsHealthIndexes) {
          promises.push(this.getAssetObsHealthIndexes(sensorId, this.dashboardParams.chartHealthIndex));
        }

        this._q.all(promises).then(() => {
          this.createHealthChartDataSets(sensorId);
          this.filterHealthItems();
          this.defineHealthChart();

          if (this.isHealthIndexTableShown) {
            const dataProvider = this.healthChartSubscriptionIndexes.find((a) => a.selected);
            const filteredAssetObsHealthIndexes = this.assetObsHealthIndexes.filter(
              (a) =>
                a.dataInputId == this.dashboardParams.chartHealthIndex &&
                a.dataProviderId == dataProvider.dataProviderId &&
                a.dayNumber >= dataProvider.seasonStartDayNumber &&
                a.dayNumber <= dataProvider.seasonEndDayNumber,
            );

            this.healthIndexTableDataSource = angular.copy(filteredAssetObsHealthIndexes);
          } else {
            this.showHealthChart();
          }
        });
      }
    });
  }

  private getAssetHealthIndexes(sensorId: number, dataInputId: number): angular.IPromise<void> {
    const defer = this._q.defer<void>();
    const params = { sensorId: sensorId, dataInputId: dataInputId };

    this._http.get(CommonHelper.getApiUrl('dashboard/getAssetHealthIndexes'), { params: params }).then(
      (response) => {
        if (!response.data) {
          this._languageService.whoops();
        } else {
          const result = response.data as fuse.dashboardAssetIndexesDto;

          this.currentSensorId = sensorId;

          const sensor = this.accountSensors.find((a) => a.id == sensorId);

          this.healthChartSensorName = sensor.name;
          this.indexYears = ArrayUtils.sortByNumber(result.indexYears, (x) => x.sequence);

          if (this.indexYears?.length) {
            this.dashboardDataInputs = this.indexYears.filter(
              (value, index, self) => self.findIndex((a) => a.dataType == value.dataType) === index,
            );

            if (!this.dashboardDataInputs.some((a) => a.dataInputId == this.dashboardParams.chartHealthIndex)) {
              this.dashboardParams.chartHealthIndex = this.dashboardDataInputs[0].dataInputId;
              this._localStorageService.set('dashboardParams', this.dashboardParams);
            }

            this.createDashboardIndexSources();
            this.createSubscriptionYearListboxItems();
          } else {
            this.dashboardDataInputs = [];
            this.dashboardHealthIndexSubscriptions = [];
            this.healthChartSubscriptionIndexes = [];
          }

          if (result.values?.length) {
            this.assetObsHealthIndexes = result.values;
            this.assetObsHealthIndexes.forEach((healthIndex) => {
              healthIndex.dateTime = this._dayNumberService.convertDayNumberToDate(healthIndex.dayNumber);
            });

            this.createHealthIndexCsvFile();
          } else {
            this.assetObsHealthIndexes = null;
          }
        }

        return defer.resolve();
      },
      () => {
        return defer.reject();
      },
    );

    return defer.promise;
  }

  private getAssetObsHealthIndexes(sensorId: number, dataInputId: number): angular.IPromise<void> {
    const defer = this._q.defer<void>();
    const params = { sensorId: sensorId, dataInputId: dataInputId };

    this._http.get(CommonHelper.getApiUrl('dashboard/getAssetObsHealthIndexes'), { params: params }).then(
      (response) => {
        if (!response.data) {
          this._languageService.whoops();
        } else {
          this.assetObsHealthIndexes = response.data as fuse.dashboardAssetObsHealthIndexDto[];

          this.assetObsHealthIndexes.forEach((healthIndex) => {
            healthIndex.dateTime = this._dayNumberService.convertDayNumberToDate(healthIndex.dayNumber);
          });

          this.createHealthIndexCsvFile();
        }

        return defer.resolve();
      },
      () => {
        return defer.reject();
      },
    );

    return defer.promise;
  }

  private createDashboardIndexSources(): void {
    let index = 0;

    this.dashboardHealthIndexSubscriptions = [];

    this.indexYears.filter((a) => a.dataInputId == this.dashboardParams.chartHealthIndex).forEach((indexYear) => {
      let indexSubscription = this.dashboardHealthIndexSubscriptions.find((a) => a.dataProviderId == indexYear.dataProviderId);

      if (!indexSubscription) {
        indexSubscription = {
          index: index++,
          dataProviderId: indexYear.dataProviderId,
          dataProviderName: indexYear.dataProvider,
          dataProviderPriority: indexYear.priority,
          years: [],
        } as fuse.dashboardDataProviderSubscriptionDto;
        const subscriptionYear = {
          year: indexYear.year,
          season: indexYear.season,
          seasonStartDayNumber: indexYear.seasonStartDayNumber,
          seasonEndDayNumber: indexYear.seasonEndDayNumber,
          selected: true,
        } as fuse.subscriptionYearDto;

        indexSubscription.years.push(subscriptionYear);
        this.dashboardHealthIndexSubscriptions.push(indexSubscription);
      } else {
        const subscriptionYear = {
          year: indexYear.year,
          season: indexYear.season,
          seasonStartDayNumber: indexYear.seasonStartDayNumber,
          seasonEndDayNumber: indexYear.seasonEndDayNumber,
          selected: true,
        } as fuse.subscriptionYearDto;

        indexSubscription.years.push(subscriptionYear);
      }
    });
  }

  private createHealthIndexCsvFile(): void {
    const dataInputName = this.dashboardDataInputs.find((a) => a.dataInputId == this.dashboardParams.chartHealthIndex).dataType;

    this.healthIndexCsvFileName = this.healthChartSensorName + '_' + dataInputName + '.csv';
    this.healthIndexCsv = [];

    this.healthChartSubscriptionIndexes.forEach((subscription) => {
      if (subscription.selected == true) {
        const provider = this.dashboardHealthIndexSubscriptions.find((a) => a.dataProviderName == subscription.dataProviderName);
        const month = this.dashboardAccountInfo.irrigationSeasonStartMonth - 1;
        const startDate = new Date(subscription.year, month);
        const startDayNumber = this._dayNumberService.convertLocaleDateToLocaleDayNumber(startDate);
        const endDate = startDate.clone().addMonths(12).addDays(-1);
        const endDayNumber = this._dayNumberService.convertLocaleDateToLocaleDayNumber(endDate);
        const healthIndexs = this.assetObsHealthIndexes.filter(
          (a) =>
            a.dataInputId == this.dashboardParams.chartHealthIndex &&
            a.dataProviderId == provider.dataProviderId &&
            a.dayNumber >= startDayNumber &&
            a.dayNumber <= endDayNumber,
        );

        healthIndexs.forEach((healthIndex) => {
          this.healthIndexCsv.push({
            date: healthIndex.dateTime.toString('yyyy-MM-dd'),
            Provider: provider.dataProviderName,
            value: healthIndex.reading,
            variability: healthIndex.variability,
            couldCover: 100 - healthIndex.qualityFactor,
          } as IHealthIndexCsv);
        });
      }
    });

    this.healthIndexCsv = ArrayUtils.sortByMultiple(this.healthIndexCsv, 
      { name: 'date' },
      { name: 'Provider' },
    );
  };

  public toggleSubscriptionYearListBox() {
    this.isSubscriptionYearListboxOpen = !this.isSubscriptionYearListboxOpen;
  }

  private createSubscriptionYearListboxItems() {
    this.healthChartSubscriptionIndexes = [];

    this.dashboardHealthIndexSubscriptions.forEach((subscription) => {
      subscription.years.forEach((year) => {
        const subscriptionIndex = {
          year: year.year,
          dataProviderId: subscription.dataProviderId,
          dataProviderName: subscription.dataProviderName,
          dataProviderPriority: subscription.dataProviderPriority,
          season: year.season,
          seasonStartDayNumber: year.seasonStartDayNumber,
          seasonEndDayNumber: year.seasonEndDayNumber,
        } as fuse.dashboardSubscriptionYearDto;
        const subscriptionIndexCopy = this.healthChartSubscriptionIndexesCopy.find(
          (a) => a.dataProviderName == subscriptionIndex.dataProviderName && a.year == subscriptionIndex.year,
        );

        if (subscriptionIndexCopy) {
          subscriptionIndex.selected = subscriptionIndexCopy.selected;
        }

        this.healthChartSubscriptionIndexes.push(subscriptionIndex);
      });
    });

    this.healthChartSubscriptionIndexes = ArrayUtils.sortByMultiple(this.healthChartSubscriptionIndexes,
      { name: 'year', sort: 'desc' },
      { name: 'dataProviderPriority' },
    );

    const isSubscriptionYearSelected = this.healthChartSubscriptionIndexes.some((a) => a.selected);
    let twoMostRecentlyYears = [] as number[];

    if (isSubscriptionYearSelected == false) {
      //select two most recently sensors, do't care about provider
      twoMostRecentlyYears = this.healthChartSubscriptionIndexes
        .map((a) => a.year)
        .filter((value, index, array) => array.indexOf(value) == index)
        .slice(0, 2);
    }

    let index = 0;

    this.healthChartSubscriptionIndexes.forEach((subscription) => {
      if (isSubscriptionYearSelected == false) {
        if (twoMostRecentlyYears.some((a) => a == subscription.year)) {
          subscription.selected = true;
        }
      }

      subscription.index = index++;

      const dataPrivider = this.dashboardHealthIndexSubscriptions.find((a) => a.dataProviderName == subscription.dataProviderName);
      const dataProviderYear = dataPrivider.years.find((a) => a.year == subscription.year);

      dataProviderYear.selected = subscription.selected;
    });

    this.isSingleHealthSource = this.healthChartSubscriptionIndexes.filter((a) => a.selected).length == 1;
    this.isNdmiAvailable = this.healthChartSubscriptionIndexes.some((a) => a.selected && a.dataProviderName == 'Sentinel-2');
  }

  public toggleSubscriptionYear(subscriptionName: string, year: number) {
    const subscriptionYear = this.healthChartSubscriptionIndexes.find((a) => a.dataProviderName == subscriptionName && a.year == year);

    subscriptionYear.selected = !subscriptionYear.selected;

    const dataPrivider = this.dashboardHealthIndexSubscriptions.find((a) => a.dataProviderName == subscriptionName);
    const dataProviderYear = dataPrivider.years.find((a) => a.year == year);

    dataProviderYear.selected = subscriptionYear.selected;
    this.isSingleHealthSource = this.healthChartSubscriptionIndexes.filter((a) => a.selected).length == 1;
    this.isNdmiAvailable = this.healthChartSubscriptionIndexes.some((a) => a.selected && a.dataProviderName == 'Sentinel-2');

    this.defineHealthChart();
    this.showHealthChart();
    this.createHealthIndexCsvFile();
  }

  public toggleSubscriptionYearByCheckbox(subscriptionName: string, year: number) {
    const subscriptionYear = this.healthChartSubscriptionIndexes.find(
      (a) => a.dataProviderName == subscriptionName && a.year == year,
    );
    const dataPrivider = this.dashboardHealthIndexSubscriptions.find((a) => a.dataProviderName == subscriptionName);
    const dataProviderYear = dataPrivider.years.find((a) => a.year == year);

    dataProviderYear.selected = subscriptionYear.selected;
    this.isSingleHealthSource = this.healthChartSubscriptionIndexes.filter((a) => a.selected).length == 1;
    this.isNdmiAvailable = this.healthChartSubscriptionIndexes.some((a) => a.selected && a.dataProviderName == 'Sentinel-2');

    this.defineHealthChart();
    this.showHealthChart();
    this.createHealthIndexCsvFile();
  }

  private getHealthIndexReadings(
    fromDayNuber: number,
    toDayNumber: number,
    sensorId?: number,
    dataProviderId?: number,
    dataInputType?: string,
  ): angular.IPromise<fuse.dashboardAssetObsHealthIndexDto[]> {
    const defer = this._q.defer<fuse.dashboardAssetObsHealthIndexDto[]>();

    const params = {
      accountId: this.accountId,
      fromDayNumber: fromDayNuber,
      toDayNumber: toDayNumber,
      sensorId: sensorId,
      dataProviderId: dataProviderId,
      dataInputType: dataInputType,
    };

    this._http.get(CommonHelper.getApiUrl('dashboard/getHealthIndexReadings'), { params: params }).then(
      (response) => {
        if (!response.data) {
          this._languageService.whoops();
          return defer.reject('No health readings');
        }
        return defer.resolve(response.data as fuse.dashboardAssetObsHealthIndexDto[]);
      },
      (r) => {
        return defer.reject(r);
      },
    );

    return defer.promise;
  }

  private getSoilMoisture(fromDayNumber: number, toDayNumber: number): angular.IPromise<fuse.dashboardAssetSoilMoistureDto[]> {
    const defer = this._q.defer<fuse.dashboardAssetSoilMoistureDto[]>();
    const params = {
      accountId: this.accountId,
      fromDayNumber: fromDayNumber,
      toDayNumber: toDayNumber,
    };

    this._http.get(CommonHelper.getApiUrl('dashboard/getSoilMoisture'), { params: params }).then(
      (response) => {
        if (!response.data) {
          this._languageService.whoops();
          return defer.reject('No soil moisture values');
        } else {
          const result = response.data as fuse.dashboardAssetSoilMoistureDto[];

          result.forEach((a) => {
            a.dateTime = this._dayNumberService.convertDayNumberToLocaleDate(a.dayNumber);
          });

          return defer.resolve(result);
        }
      },
      (r) => {
        return defer.reject(r);
      },
    );

    return defer.promise;
  }

  public overlayTypeDescription(): string {
    for (const o in this.overlayTypes) {
      if (this.overlayTypes[o].value == this.overlayType) {
        return this.overlayTypes[o].description;
      }
    }

    return 'None';
  }

  public currentHealthColourScheme(): healthIndexColourScheme {
    if (!this._colourSchemeService.healthThemes) {
      return null;
    }

    let index = this.dashboardParams.mapHealthIndex;

    if (this.overlayType == this.overlayTypes.TemporalVariation.value) {
      index = 'TEMPORALVARIATION';
    }

    const scheme = this._colourSchemeService.healthThemes.find((h) => h.healthIndex == index);

    return scheme ?? null;
  }

  public openDashboardHelpDialog(key: string, title: string) {
    this._mdDialog.show({
      controller: DashboardHelpDialogController,
      controllerAs: 'vm',
      templateUrl: 'src/app/pages/account/views/dashboard/dashboard-help-dialog.html',
      parent: angular.element(document.body),
      clickOutsideToClose: false,
      multiple: true,
      locals: {
        key: key,
        title: title,
      },
    });
  }

  public gotoIrrigationPlan(planId: number) {
    LocalStorageUtils.updateContextData((context) => {
      context.planId = planId;
    });

    this._state.go('app.water.irrigation-plans.detail', { id: planId });
  }

  private defineSoilMoistureChart() {
    this.soilMoistureChartOption = {
      type: 'serial',
      theme: 'light',
      dataProvider: this.soilMoistureDataProvider,
      marginTop: 20,
      valueAxes: [
        {
          stackType: '100% stacked',
          axisAlpha: 0,
          labelsEnabled: false,
          position: 'right',
          title: this._languageService.instant('COMMON.NUMBER_OF_SITES'),
          gridAlpha: 0,
        },
      ] as AmCharts.ValueAxis[],
      legend: {
        useGraphSettings: true,
        position: 'top',
        valueWidth: 20,
      },
      startDuration: 0,
      graphs: [
        {
          balloonText: '',
          title: this._languageService.instant('COMMON.DRY'),
          labelText: '[[dry]]',
          valueField: 'dry',
          type: 'column',
          fillAlphas: 1,
          fillColors: '#FFC107',
          lineAlpha: 0.2,
        },
        {
          balloonText: '',
          title: this._languageService.instant('COMMON.ON_TARGET'),
          labelText: '[[onTarget]]',
          valueField: 'onTarget',
          type: 'column',
          fillAlphas: 1,
          fillColors: '#00C853',
          lineAlpha: 0.2,
        },
        {
          balloonText: '',
          title: this._languageService.instant('COMMON.WET'),
          labelText: '[[over]]',
          valueField: 'over',
          type: 'column',
          fillAlphas: 1,
          fillColors: '#90CAF9',
          lineAlpha: 0.2,
        },
      ],
      chartCursor: {
        categoryBalloonEnabled: true,
        cursorAlpha: 0,
        zoomable: false,
      },
      categoryField: 'localeDate',
      categoryAxis: {
        gridPosition: 'start',
        labelRotation: 0,
        position: 'bottom',
        gridAlpha: 0,
        parseDates: true,
        dateFormats: [
          { period: 'DD', format: 'EEE DD' },
          { period: 'WW', format: 'EEE DD' },
          { period: 'MM', format: 'EEE DD' },
          { period: 'YYYY', format: 'EEE DD' },
        ],
        fontSize: '10',
        minorGridEnabled: true,
        autoGridCount: false,
        gridCount: 10,
        //minPeriod: 'DD',
        //twoLineMode: true,
        //equalSpacing: true,
        //startOnAxis: true
      },
    } as AmCharts.AmSerialChart;
  }

  private displaySoilMoistureChart() {
    if (this.soilMoistureDataProvider?.length) {
      this.soilMoistureChart = AmCharts.makeChart('soil-moisture-chart', this.soilMoistureChartOption) as AmCharts.AmSerialChart;
      this.addSoilMoistureChartGuide(this.soilMoistureChart);
    } else {
      if (this.soilMoistureChart) {
        this.soilMoistureChart.clear();
        this.soilMoistureChart = null;
      }
    }
  }

  private addSoilMoistureChartGuide(chart: AmCharts.AmSerialChart) {
    if (!chart) {
      return;
    }

    const guideLine: AmCharts.Guide = new AmCharts.Guide();

    guideLine.date = this._dayNumberService.convertDayNumberToLocaleDate(this.adjustedTodayDayNumber);
    guideLine.above = true;
    guideLine.lineColor = 'red';
    guideLine.lineAlpha = 0.6;
    guideLine.lineThickness = 2;
    guideLine.inside = true;
    guideLine.dashLength = 2;

    if (chart.categoryAxis.addGuide !== undefined) {
      // remove any existing guides
      for (let idx = chart.categoryAxis.guides.length - 1; idx >= 0; idx--) {
        chart.categoryAxis.removeGuide(chart.categoryAxis.guides[idx]);
      }
      chart.categoryAxis.addGuide(guideLine);
    } else {
      //  console.log('check categoryAxis');
    }

    chart.validateNow();
  }

  public toggleWeatherFormat(): void {
    this._localStorageService.set('dashboardParams', this.dashboardParams);
  }

  public toggleSearchSiteModal() {
    this._mdSidenav('mapSearchSiteModal').toggle();
  }

  public selectAsset(asset: ISiteAndSensor) {
    this.currentAsset = asset;

    this.closeInfoWindows();
    this.setMapRightCenter(asset.latitude, asset.longitude);
    this.currentAsset.infoWindow.setPosition(new google.maps.LatLng(asset.latitude, asset.longitude));
    this.currentAsset.infoWindow.open(this.map);

    if (asset.assetClassName == 'site') {
      this.siteMarkerClick(asset.assetId);
    } else {
      this.clickHealthMarker(asset.assetId);
    }
  }

  public closeMapSearchSiteModal() {
    this._mdSidenav('mapSearchSiteModal').close();
  }

  private getHealthIndexTooltip(healthIndexType: string): string {
    const healthIndex = this.dashboardAccountInfo.dashboardDataInputs.find((a) => a.dataType == healthIndexType);

    return `${healthIndex.dataType} - ${healthIndex.name}`;
  }

  public toggleHealthIndexTable(): void {
    this.isHealthIndexTableShown = !this.isHealthIndexTableShown;

    if (this.isHealthIndexTableShown) {
      const dataProvider = this.healthChartSubscriptionIndexes.find((a) => a.selected);
      const filteredAssetObsHealthIndexes = this.assetObsHealthIndexes.filter(
        (a) =>
          a.dataInputId == this.dashboardParams.chartHealthIndex &&
          a.dataProviderId == dataProvider.dataProviderId &&
          a.dayNumber >= dataProvider.seasonStartDayNumber &&
          a.dayNumber <= dataProvider.seasonEndDayNumber,
      );

      this.healthIndexTableDataSource = angular.copy(filteredAssetObsHealthIndexes);
    } else {
      this.createHealthChartDataSets(this.currentSensorId);
      this.filterHealthItems();
      this.defineHealthChart();
      this.showHealthChart();
    }
  }

  public onReloadHealthIndexTable(isSaved: boolean, changedObs: fuse.dashboardAssetObsHealthIndexDto[]): void {
    if (isSaved == false) {
      // reject
      const dataProvider = this.healthChartSubscriptionIndexes.find((a) => a.selected);
      const filteredAssetObsHealthIndexes = this.assetObsHealthIndexes.filter(
        (a) =>
          a.dataInputId == this.dashboardParams.chartHealthIndex &&
          a.dataProviderId == dataProvider.dataProviderId &&
          a.dayNumber >= dataProvider.seasonStartDayNumber &&
          a.dayNumber <= dataProvider.seasonEndDayNumber,
      );

      this.healthIndexTableDataSource = angular.copy(filteredAssetObsHealthIndexes);
    } else {
      // save
      changedObs.forEach((obs) => {
        const obsHealthIndex = this.assetObsHealthIndexes.find((a) => a.id == obs.id);

        obsHealthIndex.reliable = obs.reliable;
      });

      this.createHealthChartDataSets(this.currentSensorId);
      this.filterHealthItems();
      this.defineHealthChart();
    }

    this.isTableHealthIndexChanged = false;
    // all marker and shapes should be clickable
    this.setMarkerAndShapeClickable(true);
  }

  public onTableHealthIndexChanged(): void {
    this.isTableHealthIndexChanged = true;
    // all marker and shapes should be unclickable
    this.setMarkerAndShapeClickable(false);
  }

  private setMarkerAndShapeClickable(isClickable: boolean) {
    this.siteMarkers.forEach((sitemaker) => {
      sitemaker.marker.setClickable(isClickable);
    });

    this.siteShapes.forEach((siteShape) => {
      siteShape.polygon.setOptions({ clickable: isClickable });
    });

    this.healthMarkers.forEach((healthMarker) => {
      healthMarker.marker.setClickable(isClickable);
    });

    this.healthShapes.forEach((healthShape) => {
      healthShape.polygon.setOptions({ clickable: isClickable });
    });
  }

  setHealthInitialViewBool() {
    if (this._state.params.viewSiteHealth == true) {
      this.isHealthIndexInitialView = true;
    }
  }
}

angular.module('app.account').controller('DashboardController', DashboardController);
