import * as angular from 'angular';
import { ApplicationPrivileges } from '@common/ApplicationPrivileges';
import { AssetGroupController } from './views/group/asset-group.controller';
import { DashboardController } from './views/dashboard/dashboard.controller';
import { EquipmentDetailController } from './views/equipment/equipment.controller';
import { EquipmentListController } from './views/equipments/equipments.controller';
import { GroupController } from './views/group/group.controller';
import { GroupsController } from './views/groups/groups.controller';
import { InfrastructureController } from './views/infrastructure/infrastructure.controller';
import { InfrastructuresController } from './views/infrastructures/infrastructures.controller';
import { SiteController } from './views/site/site.controller';
import { SitesController } from './views/sites/sites.controller';

const accountConfig = (
  $stateProvider: angular.ui.IStateProvider,
) => {
  $stateProvider.state('app.account', {
    abstract: true,
    url: '',
  });

  $stateProvider.state('app.account.assetgroups', {
    url: '/assetgroups',
    views: {
      'content@app': {
        templateUrl: 'src/app/pages/account/views/groups/groups.html',
        controller: GroupsController,
        controllerAs: 'vm',
      },
    },
    data: { permission: ApplicationPrivileges.GroupView },
    resolve: {},
  });

  $stateProvider.state('app.account.assetgroups.detail', {
    url: '/:id',
    views: {
      'content@app': {
        templateUrl: 'src/app/pages/account/views/group/asset-group.html',
        controller: AssetGroupController,
        controllerAs: 'vm',
      },
    },
    data: { permission: ApplicationPrivileges.GroupView },
    resolve: {},
  });

  $stateProvider.state('app.account.dashboard', {
    url: '/home',
    params: {
      viewSiteHealth: null,
      sensorId: null,
    },
    views: {
      'content@app': {
        templateUrl: 'src/app/pages/account/views/dashboard/dashboard.html',
        controller: DashboardController,
        controllerAs: 'vm',
      },
    },
    resolve: {},
  });

  $stateProvider.state('app.account.equipments', {
    url: '/equipment',
    views: {
      'content@app': {
        templateUrl: 'src/app/pages/account/views/equipments/equipments.html',
        controller: EquipmentListController,
        controllerAs: 'vm',
      },
    },
    data: { permission: ApplicationPrivileges.EquipmentView },
    resolve: {},
  });

  $stateProvider.state('app.account.equipments.detail', {
    url: '/:id',
    params: {
      isSwitchEquipment: null,
      viewRange: null,
      viewSettings: null,
    },
    data: { permission: ApplicationPrivileges.EquipmentView },
    views: {
      'content@app': {
        templateUrl: 'src/app/pages/account/views/equipment/equipment.html',
        controller: EquipmentDetailController,
        controllerAs: 'vm',
      },
    },
  });

  $stateProvider.state('app.account.groups', {
    url: '/groups',
    views: {
      'content@app': {
        templateUrl: 'src/app/pages/account/views/groups/groups.html',
        controller: GroupsController,
        controllerAs: 'vm',
      },
    },
    data: { permission: ApplicationPrivileges.GroupView },
    resolve: {},
  });

  $stateProvider.state('app.account.groups.detail', {
    url: '/:id',
    views: {
      'content@app': {
        templateUrl: 'src/app/pages/account/views/group/group.html',
        controller: GroupController,
        controllerAs: 'vm',
      },
    },
    data: { permission: ApplicationPrivileges.GroupView },
    resolve: {},
  });

  $stateProvider.state('app.account.infrastructures', {
    url: '/infrastructures',
    views: {
      'content@app': {
        templateUrl: 'src/app/pages/account/views/infrastructures/infrastructures.html',
        controller: InfrastructuresController,
        controllerAs: 'vm',
      },
    },
    resolve: {},
  });

  $stateProvider.state('app.account.infrastructures.detail', {
    url: '/:id',
    params: {
      name: null,
      assetClassId: null,
    },
    views: {
      'content@app': {
        templateUrl: 'src/app/pages/account/views/infrastructure/infrastructure.html',
        controller: InfrastructureController,
        controllerAs: 'vm',
      },
    },
  });

  $stateProvider.state('app.account.sites', {
    url: '/sites',
    views: {
      'content@app': {
        templateUrl: 'src/app/pages/account/views/sites/sites.html',
        controller: SitesController,
        controllerAs: 'vm',
      },
    },
    data: { permission: ApplicationPrivileges.SiteView },
    resolve: {},
    /* Enable for React app redirects. */
    // onEnter: () => {
    //   location.replace('/react/migrated-page?redirect=sites')
    // }
  });

  $stateProvider.state('app.account.sites.detail', {
    url: '/:id',
    params: {
      viewSchedule: false,
      viewMap: false,
    },
    views: {
      'content@app': {
        templateUrl: 'src/app/pages/account/views/site/site.html',
        controller: SiteController,
        controllerAs: 'vm',
      },
    },
    data: { permission: ApplicationPrivileges.SiteView },
    resolve: {},
  });
}

angular.module('app.account').config(['$stateProvider', accountConfig]);
