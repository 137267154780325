import { Asset } from 'src/app/_DBContext/Asset';
import { IIdNameItem, IStringDropListItem } from '@common/models/interfaces';

export interface ISiteCropCoeff {
  id: number;
  name: string;
  value: number;
  duration: number;
  slopeOption: string;
  phaseDates: string;
}

export interface SelectedSamplePointRow {
  Percentage: number;
  SamplePoint: Asset;
}

export interface IWaterDays {
  day: string;
  isSelected: boolean;
}

export class AccountConstants {
  public static schedularFrequencies = [
    { id: 'hour', name: 'Hourly' },
    { id: 'day', name: 'Daily' },
    { id: 'month', name: 'Monthly' },
  ] as IStringDropListItem[];

  public static valueRangeAttributes = [
    { id: 'Reading', name: 'Value' },
    { id: 'Variability', name: 'Variability' },
  ] as IStringDropListItem[];

  public static distributeMethods = [
    { id: 1, name: 'FERTWEIGHT_UNIT' },
    { id: 2, name: 'FERTWEIGHTAREA_UNIT' },
    { id: 3, name: 'COMMON.DISTRIBUTE_PROPORTIONAL_TO_AREA' },
  ] as IIdNameItem[];

  public static applyFertiliserMethods = [
    {
      id: 'Overwritten',
      name: 'Overwrite existing values',
      description: 'If the Planned amount is blank, existing Applied amounts will be retained',
    },
    { id: 'Retained', name: 'Retain existing values', description: '' },
    { id: 'Added', name: 'Add amount to existing values', description: '' },
  ] as IIdNameItem[];
}
