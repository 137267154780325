// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.91.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.91.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#add-site-fertiliser-dialog {
  width: 800px;
  overflow: auto;
  height: 550px;
}
#add-site-fertiliser-dialog md-dialog-content {
  height: 450px;
}
#add-site-fertiliser-dialog md-dialog-content md-select {
  width: 300px;
}
#add-site-fertiliser-dialog md-dialog-content md-input-container .md-errors-spacer {
  min-height: 0px !important;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/account/components/add-site-fertiliser-dialog.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,cAAA;EACA,aAAA;AACJ;AAAI;EACI,aAAA;AAER;AADQ;EACI,YAAA;AAGZ;AAAY;EAEI,0BAAA;AAChB","sourcesContent":["#add-site-fertiliser-dialog {\r\n    width: 800px;\r\n    overflow: auto;\r\n    height: 550px;\r\n    md-dialog-content {\r\n        height: 450px;\r\n        md-select {\r\n            width: 300px;\r\n        }\r\n        md-input-container {\r\n            .md-errors-spacer {\r\n                //display: inline;\r\n                min-height: 0px !important;\r\n            }\r\n        }\r\n    }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
