import * as angular from 'angular';
import { unitSizes } from '@common/enums';
import { CommonHelper } from '@common/helpers/CommonHelper';
import { DataEntityService } from '@services/data-entity.service';
import { LanguageService } from '@services/language.service';
import { UnitOfMeasureService } from '@services/unit-of-measure.service';
import { AccountConstants } from '../AccountConstants';
import { SiteWeekInfo, WeekFertiliser } from './nutrient-application.component';
import { PermissionService } from '@services/permission.service';
import { SiteWeeklyFertiliser } from 'src/app/_DBContext/SiteWeeklyFertiliser';
import { BaseController } from 'src/app/base.controller';

//different from AddFertiliserDialogController
export class AddSiteFertiliserDialogController extends BaseController {
  private _mdDialog: angular.material.IDialogService;
  private _http: angular.IHttpService;
  private _timeout: angular.ITimeoutService;
  private _languageService: LanguageService;
  private _unitOfMeasureService: UnitOfMeasureService;

  public fertiliserProfiles: fuse.fertiliserProfileDto[];
  public distributeMethods = angular.copy(AccountConstants.distributeMethods);
  public applyFertiliserMethods = AccountConstants.applyFertiliserMethods;
  public siteWeeks: SiteWeekInfo[];
  public fertiliserId = null as number;
  public distributeMethod = null as number;
  public plannedAmountMethod: string;
  public appliedAmountMethod: string;
  public isAddToCell = false;
  public isUpdateInCell = false;
  public isSetPlannedAmount = false;
  public isSetAppliedAmount = false;
  public plannedAmount: number;
  public appliedAmount: number;
  public unitType: string;
  public unitLabel: string;
  private fertiliserUnitType: string;
  public anyPastSiteWeeks: boolean;
  public isSpinning = false;

  constructor(
    $http: angular.IHttpService,
    $mdDialog: angular.material.IDialogService,
    $scope: angular.IScope,
    $timeout: angular.ITimeoutService,
    DataEntityService: DataEntityService,
    LanguageService: LanguageService,
    PermissionService: PermissionService,
    UnitOfMeasureService: UnitOfMeasureService,
    siteWeeks: SiteWeekInfo[],
  ) {
    super(
      $scope,
      PermissionService,
    );

    this._http = $http;
    this._mdDialog = $mdDialog;
    this._timeout = $timeout;
    this._languageService = LanguageService;
    this._unitOfMeasureService = UnitOfMeasureService;

    this.entityManager = DataEntityService.manager;
    this.siteWeeks = siteWeeks;
    this.anyPastSiteWeeks = !siteWeeks.every((a) => a.isFutureSunday);
  }

  $onInit() {
    this._http.get(CommonHelper.getApiUrl('site/getFertiliserProfiles')).then((response) => {
      if (response.data == null) {
        console.error(this._languageService.whoops());
      } else {
        this.fertiliserProfiles = response.data as fuse.fertiliserProfileDto[];
      }
    });
  }

  public closeDialog() {
    this._mdDialog.hide();
  }

  public changeFertiliser(): void {
    if (this.fertiliserId != null) {
      const fertiliser = this.fertiliserProfiles.find((a) => a.assetId == this.fertiliserId);
      this.fertiliserUnitType = fertiliser.unit == 'kg' ? 'Weight' : 'FertVolume';
      const fertiliserUnitTypeByArea = `${this.fertiliserUnitType}/Area`;
      const fertWeightLabel = this._unitOfMeasureService.getUnitLabel(this.fertiliserUnitType, unitSizes.normal);
      const fertWeightByAreaLabel = this._unitOfMeasureService.getUnitLabel(fertiliserUnitTypeByArea, unitSizes.normal);
      this.distributeMethods.forEach((method) => {
        if (method.name.indexOf('FERTWEIGHT_UNIT') >= 0) {
          method.name = fertWeightLabel + ' ' + this._languageService.instant('COMMON.PER_SITE_WEEK');
        } else if (method.name.indexOf('FERTWEIGHTAREA_UNIT') >= 0) {
          method.name = method.name.replace('FERTWEIGHTAREA_UNIT', fertWeightByAreaLabel);
        } else {
          method.name = this._languageService.instant(method.name);
        }
      });
    }
    this.isAddToCell = false;
    this.isUpdateInCell = false;
    this.distributeMethod = null;
    this.plannedAmountMethod = null;
    this.appliedAmountMethod = null;
    this.isSetPlannedAmount = false;
    this.isSetAppliedAmount = false;
    this.plannedAmount = null;
    this.appliedAmount = null;
    this.unitType = null;
  }

  public changeDistributeMethod(): void {
    if (this.distributeMethod == null) {
      this.plannedAmountMethod = null;
      this.appliedAmountMethod = null;
      this.isSetPlannedAmount = false;
      this.isSetAppliedAmount = false;
      this.unitType = null;
    } else {
      if (this.distributeMethod == 1) {
        // Volume or Weight per site/week
        this.unitType = this.fertiliserUnitType;
      } else if (this.distributeMethod == 2) {
        // Volume or Weight per area
        this.unitType = `${this.fertiliserUnitType}/Area`;
      } else if (this.distributeMethod == 3) {
        // Distribute gross amount proportional to area
        this.unitType = this.fertiliserUnitType;
      }
      this.unitLabel = this._unitOfMeasureService.getUnitLabel(this.unitType, unitSizes.normal);
    }
  }

  private getTotalPlannedArea(): number {
    let totalPlannedArea = 0;
    if (this.isSetPlannedAmount) {
      this.siteWeeks.forEach((siteWeek) => {
        const siteWeekFertiliser = siteWeek.siteWeekFertilisers.find((a) => a.fertiliserId == this.fertiliserId);
        if (this.isAddToCell && siteWeekFertiliser == null) {
          totalPlannedArea += siteWeek.siteArea;
        }
        if (this.isUpdateInCell && siteWeekFertiliser != null) {
          if (this.plannedAmountMethod == 'Retained' && siteWeekFertiliser.plannedValue != null) {
          } else {
            totalPlannedArea += siteWeek.siteArea;
          }
        }
      });
    }
    return totalPlannedArea;
  }

  private getTotalAppliedArea(): number {
    let totalAppliedArea = 0;
    if (this.isSetAppliedAmount) {
      this.siteWeeks
        .filter((a) => !a.isFutureSunday)
        .forEach((siteWeek) => {
          const siteWeekFertiliser = siteWeek.siteWeekFertilisers.find((a) => a.fertiliserId == this.fertiliserId);
          if (this.isAddToCell && siteWeekFertiliser == null) {
            totalAppliedArea += siteWeek.siteArea;
          }
          if (this.isUpdateInCell && siteWeekFertiliser != null) {
            if (this.appliedAmountMethod == 'Retained' && siteWeekFertiliser.appliedValue != null) {
            } else {
              totalAppliedArea += siteWeek.siteArea;
            }
          }
        });
    }
    return totalAppliedArea;
  }

  public setClose(): void {
    const totalPlannedArea = this.getTotalPlannedArea();
    const totalAppliedArea = this.getTotalAppliedArea();
    if (this.distributeMethod == 3) {
      // if select distribute gross and ratain, need check the valid site week area is not null
      let message: string;
      if (this.isSetPlannedAmount && !this.isSetAppliedAmount) {
        if (totalPlannedArea == 0) {
          message = 'NUTR.FERT.CANT_DISTRIBUTE_PLANNED';
        }
      } else if (!this.isSetPlannedAmount && this.isSetAppliedAmount) {
        if (totalAppliedArea == 0) {
          message = 'NUTR.FERT.CANT_DISTRIBUTE_APPLIED';
        }
      } else {
        if (totalPlannedArea == 0 && totalPlannedArea == 0) {
          message = 'NUTR.FERT.CANT_DISTRIBUTE_BOTH';
        }
      }
      if (message != null) {
        this._languageService.error(message);
        return;
      }
    }
    this.isSpinning = true;
    this._timeout(() => {
      this.isSpinning = false;
      this.setSiteWeeks();
      this._mdDialog.hide(true);
    }, 500);
  }

  private setSiteWeeks(): void {
    const fertiliser = this.fertiliserProfiles.find((a) => a.assetId == this.fertiliserId);
    let isAnyChanged = false;
    const totalPlannedArea = this.getTotalPlannedArea();
    const totalAppliedArea = this.getTotalAppliedArea();
    this.siteWeeks.forEach((siteWeek) => {
      let siteWeekFertiliser = siteWeek.siteWeekFertilisers.find((a) => a.fertiliserId == this.fertiliserId);
      if (siteWeekFertiliser == null) {
        if (this.isAddToCell) {
          siteWeekFertiliser = {
            fertiliserId: this.fertiliserId,
            fertiliserName: fertiliser.name,
            unitType: this.fertiliserUnitType,
            unitByAreaType: `${this.fertiliserUnitType}/Area`,
            unitLabel: this._unitOfMeasureService.getUnitLabel(this.fertiliserUnitType, unitSizes.normal),
            unitByAreaLabel: this._unitOfMeasureService.getUnitLabel(`${this.fertiliserUnitType}/Area`, unitSizes.normal),
          } as WeekFertiliser;
          if (this.distributeMethod == 1) {
            // Volume or Weight per site/week
            if (this.isSetAppliedAmount && !siteWeek.isFutureSunday) {
              siteWeekFertiliser.appliedValue = this.appliedAmount;
              siteWeekFertiliser.appliedValueByArea = this.appliedAmount / siteWeek.siteArea;
            }
            if (this.isSetPlannedAmount) {
              siteWeekFertiliser.plannedValue = this.plannedAmount;
              siteWeekFertiliser.plannedValueByArea = this.plannedAmount / siteWeek.siteArea;
            }
          }
          if (this.distributeMethod == 2) {
            // Volume or Weight per area
            if (this.isSetAppliedAmount && !siteWeek.isFutureSunday) {
              siteWeekFertiliser.appliedValueByArea = this.appliedAmount;
              siteWeekFertiliser.appliedValue = this.appliedAmount * siteWeek.siteArea;
            }
            if (this.isSetPlannedAmount) {
              siteWeekFertiliser.plannedValueByArea = this.plannedAmount;
              siteWeekFertiliser.plannedValue = this.plannedAmount * siteWeek.siteArea;
            }
          }
          if (this.distributeMethod == 3) {
            // Distribute gross amount proportional to area
            if (this.isSetAppliedAmount && !siteWeek.isFutureSunday) {
              const appliedAmount = this.appliedAmount / totalAppliedArea;
              siteWeekFertiliser.appliedValueByArea = appliedAmount;
              siteWeekFertiliser.appliedValue = appliedAmount * siteWeek.siteArea;
            }
            if (this.isSetPlannedAmount) {
              const plannedAmount = this.plannedAmount / totalPlannedArea;
              siteWeekFertiliser.plannedValueByArea = plannedAmount;
              siteWeekFertiliser.plannedValue = plannedAmount * siteWeek.siteArea;
            }
          }
          siteWeek.siteWeekFertilisers.push(siteWeekFertiliser);
          isAnyChanged = true;
          this.updateEntity(
            siteWeek.siteId,
            siteWeek.dayNumber,
            this.fertiliserId,
            siteWeekFertiliser.plannedValueByArea,
            siteWeekFertiliser.appliedValueByArea,
          );
        }
        if (this.isUpdateInCell) {
          // the updateInCell is checked, but there is no the fertiliser in the siteWeekFertiliser,
          // we will not create a new siteWeekFertiliser
        }
      } else {
        if (this.isAddToCell) {
          // the addToCell is checked, but there is the fertiliser in the siteWeekFertiliser,
          // we will not update the siteWeekFertiliser                        }
        }
        if (this.isUpdateInCell) {
          if (this.distributeMethod == 1) {
            // Volume or Weight per site/week
            if (this.isSetAppliedAmount && !siteWeek.isFutureSunday) {
              if (this.appliedAmountMethod == 'Retained') {
                if (siteWeekFertiliser.appliedValue == null) {
                  siteWeekFertiliser.appliedValue = this.appliedAmount;
                  siteWeekFertiliser.appliedValueByArea = this.appliedAmount / siteWeek.siteArea;
                }
              }
              if (this.appliedAmountMethod == 'Overwritten') {
                siteWeekFertiliser.appliedValue = this.appliedAmount;
                siteWeekFertiliser.appliedValueByArea = this.appliedAmount / siteWeek.siteArea;
              }
              if (this.appliedAmountMethod == 'Added') {
                siteWeekFertiliser.appliedValue += this.appliedAmount;
                siteWeekFertiliser.appliedValueByArea += this.appliedAmount / siteWeek.siteArea;
              }
            }
            if (this.isSetPlannedAmount) {
              if (this.plannedAmountMethod == 'Retained') {
                if (siteWeekFertiliser.plannedValue == null) {
                  siteWeekFertiliser.plannedValue = this.plannedAmount;
                  siteWeekFertiliser.plannedValueByArea = this.plannedAmount / siteWeek.siteArea;
                }
              }
              if (this.plannedAmountMethod == 'Overwritten') {
                siteWeekFertiliser.plannedValue = this.plannedAmount;
                siteWeekFertiliser.plannedValueByArea = this.plannedAmount / siteWeek.siteArea;
              }
              if (this.plannedAmountMethod == 'Added') {
                siteWeekFertiliser.plannedValue += this.plannedAmount;
                siteWeekFertiliser.plannedValueByArea += this.plannedAmount / siteWeek.siteArea;
              }
            }
          }
          if (this.distributeMethod == 2) {
            // Volume or Weight per area
            if (this.isSetAppliedAmount && !siteWeek.isFutureSunday) {
              if (this.appliedAmountMethod == 'Retained') {
                if (siteWeekFertiliser.appliedValueByArea == null) {
                  siteWeekFertiliser.appliedValueByArea = this.appliedAmount;
                  siteWeekFertiliser.appliedValue = this.appliedAmount * siteWeek.siteArea;
                }
              }
              if (this.appliedAmountMethod == 'Overwritten') {
                siteWeekFertiliser.appliedValueByArea = this.appliedAmount;
                siteWeekFertiliser.appliedValue = this.appliedAmount * siteWeek.siteArea;
              }
              if (this.appliedAmountMethod == 'Added') {
                siteWeekFertiliser.appliedValueByArea += this.appliedAmount;
                siteWeekFertiliser.appliedValue += this.appliedAmount * siteWeek.siteArea;
              }
            }
            if (this.isSetPlannedAmount) {
              if (this.plannedAmountMethod == 'Retained') {
                if (siteWeekFertiliser.plannedValueByArea == null) {
                  siteWeekFertiliser.plannedValueByArea = this.plannedAmount;
                  siteWeekFertiliser.plannedValue = this.plannedAmount * siteWeek.siteArea;
                }
              }
              if (this.plannedAmountMethod == 'Overwritten') {
                siteWeekFertiliser.plannedValueByArea = this.plannedAmount;
                siteWeekFertiliser.plannedValue = this.plannedAmount * siteWeek.siteArea;
              }
              if (this.plannedAmountMethod == 'Added') {
                siteWeekFertiliser.plannedValueByArea += this.plannedAmount;
                siteWeekFertiliser.plannedValue += this.plannedAmount * siteWeek.siteArea;
              }
            }
          }
          if (this.distributeMethod == 3) {
            // Distribute gross amount proportional to area
            if (this.isSetAppliedAmount && !siteWeek.isFutureSunday) {
              const appliedAmountByArea = this.appliedAmount / totalAppliedArea;
              if (this.appliedAmountMethod == 'Retained') {
                if (siteWeekFertiliser.appliedValueByArea == null) {
                  siteWeekFertiliser.appliedValueByArea = appliedAmountByArea;
                  siteWeekFertiliser.appliedValue = appliedAmountByArea * siteWeek.siteArea;
                }
              }
              if (this.appliedAmountMethod == 'Overwritten') {
                siteWeekFertiliser.appliedValueByArea = appliedAmountByArea;
                siteWeekFertiliser.appliedValue = appliedAmountByArea * siteWeek.siteArea;
              }
              if (this.appliedAmountMethod == 'Added') {
                siteWeekFertiliser.appliedValueByArea += appliedAmountByArea;
                siteWeekFertiliser.appliedValue += appliedAmountByArea * siteWeek.siteArea;
              }
            }
            if (this.isSetPlannedAmount) {
              const plannedAmountByArea = this.plannedAmount / totalPlannedArea;
              if (this.plannedAmountMethod == 'Retained') {
                if (siteWeekFertiliser.plannedValueByArea == null) {
                  siteWeekFertiliser.plannedValueByArea = plannedAmountByArea;
                  siteWeekFertiliser.plannedValue = plannedAmountByArea * siteWeek.siteArea;
                }
              }
              if (this.plannedAmountMethod == 'Overwritten') {
                siteWeekFertiliser.plannedValueByArea = plannedAmountByArea;
                siteWeekFertiliser.plannedValue = plannedAmountByArea * siteWeek.siteArea;
              }
              if (this.plannedAmountMethod == 'Added') {
                siteWeekFertiliser.plannedValueByArea += plannedAmountByArea;
                siteWeekFertiliser.plannedValue += plannedAmountByArea * siteWeek.siteArea;
              }
            }
          }
          //update entity
          isAnyChanged = true;
          this.updateEntity(
            siteWeek.siteId,
            siteWeek.dayNumber,
            this.fertiliserId,
            siteWeekFertiliser.plannedValueByArea,
            siteWeekFertiliser.appliedValueByArea,
          );
        }
      }
    });
  }

  private updateEntity(
    siteId: number,
    dayNumber: number,
    fertiliserId: number,
    plannedValueByArea: number,
    appliedValueByArea: number,
  ): void {
    const swfQuery = breeze.EntityQuery.from('SiteWeeklyFertilisers').toType('SiteWeeklyFertiliser');
    const swfEntities = this.entityManager.executeQueryLocally(swfQuery) as SiteWeeklyFertiliser[]; // query the cache (synchronous)
    const swfEntity = swfEntities.find((a) => a.AssetId == siteId && a.dayNumber == dayNumber && a.FertiliserId == fertiliserId);
    if (swfEntity != null) {
      swfEntity.FertiliserPlanned = plannedValueByArea;
      swfEntity.FertiliserActual = appliedValueByArea;
    } else {
      const swf = {
        AssetId: siteId,
        dayNumber: dayNumber,
        FertiliserId: fertiliserId,
        FertiliserPlanned: plannedValueByArea,
        FertiliserActual: appliedValueByArea,
      } as SiteWeeklyFertiliser;

      const swfType = this.entityManager.metadataStore.getEntityType('SiteWeeklyFertiliser') as breeze.EntityType;
      const swfEntity = swfType.createEntity(swf) as SiteWeeklyFertiliser;

      this.entityManager.addEntity(swfEntity);
    }
  }

  public isButtonEnabled(): boolean {
    if (this.isSetPlannedAmount == false && this.isSetAppliedAmount == false) return false;
    if (this.isSetPlannedAmount) {
      if (this.plannedAmount == null) return false;
      if (this.isUpdateInCell && this.plannedAmountMethod == null) return false;
    }
    if (this.isSetAppliedAmount) {
      if (this.appliedAmount == null) return false;
      if (this.isUpdateInCell && this.appliedAmountMethod == null) return false;
    }
    return true;
  }
}

angular.module('app.account').controller('AddSiteFertiliserDialogController', AddSiteFertiliserDialogController);
