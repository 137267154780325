import * as angular from 'angular';
import { PermissionService } from '@services/permission.service';
import { BaseController } from 'src/app/base.controller';

export class DashboardHelpDialogController extends BaseController {
  public key: string;
  public title: string;

  private _mdDialog: angular.material.IDialogService;

  constructor(
    $mdDialog: angular.material.IDialogService,
    $scope: angular.IScope,
    PermissionService: PermissionService,
    key: string,
    title: string,
  ) {
    super(
      $scope,
      PermissionService,
    );

    this._mdDialog = $mdDialog;

    this.key = key;
    this.title = title;
  }

  public close() {
    this._mdDialog.hide();
  }
}

angular.module('app.account').controller('DashboardHelpDialogController', DashboardHelpDialogController);
