import * as angular from 'angular';
import { PermissionService } from '@services/permission.service';
import { NutrientService } from '@services/nutrients/nutrient.service';
import { BaseController } from 'src/app/base.controller';

export class RemoveFertiliserDialogController extends BaseController {
  private _mdDialog: angular.material.IDialogService;
  private _nutrientService: NutrientService;

  public selectedSiteWeeks: fuse.siteWeek[];
  public counter: number;

  constructor(
    $mdDialog: angular.material.IDialogService,
    $scope: angular.IScope,
    PermissionService: PermissionService,
    NutrientService: NutrientService,
    counter: number,
    selectedSiteWeeks: fuse.siteWeek[],
  ) {
    super(
      $scope,
      PermissionService,
    );

    this._mdDialog = $mdDialog;
    this._nutrientService = NutrientService;

    this.counter = counter;
    this.selectedSiteWeeks = selectedSiteWeeks;
  }

  public closeDialog() {
    this._mdDialog.hide();
  }

  public remove(): void {
    this._nutrientService.removeUnused(this.selectedSiteWeeks).then(() => {
      this._mdDialog.hide(true);
    });
  }
}

angular.module('app.account').controller('RemoveFertiliserController', RemoveFertiliserDialogController);
