import * as angular from 'angular';
import { ApplicationPrivileges } from '@common/ApplicationPrivileges';
import { SWANConstants } from '@common/SWANConstants';
import { unitSizes } from '@common/enums';
import { AddSiteFertiliserDialogController } from './add-site-fertiliser-dialog.controller';
import { ApplyFertiliserDialogController } from './apply-fertiliser-dialog.controller';
import { ChangeDateConfirmDialogController } from './change-date-confirm-dialog.controller';
import { RemoveFertiliserDialogController } from './remove-fertiliser-dialog.controller';
import { DataEntityService } from '@services/data-entity.service';
import { DayNumberService } from '@services/day-number.service';
import { LanguageService } from '@services/language.service';
import { NotifyEvents, NotifyingService } from '@services/notifying.service';
import { PermissionService } from '@services/permission.service';
import { UnitOfMeasureService } from '@services/unit-of-measure.service';
import { SiteSettingsNutrients } from 'src/app/_DBContext/SiteSettingsNutrients';
import { SiteWeeklyFertiliser } from 'src/app/_DBContext/SiteWeeklyFertiliser';
import { BaseController } from 'src/app/base.controller';

class NutrientApplicationComponent implements angular.IComponentOptions {
  bindings = {
    assetId: '<',
    assetType: '<',
    reloadCounter: '<',
    saveCounter: '<',
  };
  controller = NutrientApplicationController;
  controllerAs = 'vm';
  templateUrl = 'src/app/pages/account/components/nutrient-application.html';
}

interface WeekInfo {
  index: number;
  dayNumber: number;
  dayDisplayYMD: string;
  sundayDate: Date;
  isCurrentSunday: boolean;
  isPreviousSunday: boolean;
  isFutureSunday: boolean;
  isSelected: boolean | null;
  isPartialSelected: boolean;
  cssStyle: string;
  weekFertilisers: WeekFertiliser[];
}

export interface WeekFertiliser {
  fertiliserId: number;
  fertiliserName: string;
  unitType: string;
  unitByAreaType: string;
  unitLabel: string;
  unitByAreaLabel: string;
  plannedValue: number;
  plannedValueByArea: number;
  appliedValue: number;
  appliedValueByArea: number;
  isDeleted: boolean;
}

export interface SiteWeekInfo {
  siteId: number;
  siteArea: number;
  week: number;
  dayNumber: number;
  dayDisplayYMD: string;
  isFutureSunday: boolean;
  isSelected: boolean;
  description: string;
  anyEmptyFeritliser: boolean;
  anyAppliableFertiliser: boolean;
  siteWeekFertilisers: WeekFertiliser[];
}

interface SiteInfo {
  id: number;
  name: string;
  isSelected: boolean;
  isPartialSelected: boolean;
  isExpanded: boolean;
}

class NutrientApplicationController extends BaseController {
  public assetId: number;
  public sites = [] as SiteInfo[];
  public assetType: string;
  public weeklyFertilisers: SiteWeeklyFertiliser[];
  public groupedWeeklyFertilisers: SiteWeekInfo[];
  public settingsNutrients = [] as SiteSettingsNutrients[];
  public byArea = true;
  public unitLabel: string;

  public minDate = SWANConstants.MinDate;
  //public maxDate: Date;
  public startDate: Date;
  public endDate: Date;
  private localeCurrentSundayDayNumber: number;
  public weekInfos: WeekInfo[];
  public isDataReady = false;
  public isSelectAllSiteWeeks = false;
  public selectedSiteWeekCount = 0;
  public selectedSiteCount = 0;
  public selectedWeekCount = 0;
  public isAllExpanded = true;
  public isRemoveButtonEnabled = false;
  public isApplyButtonEnabled = false;
  public isSetButtonEnabled = false;
  public isDateChanged = false;
  public isSpinning = true;

  private _mdDialog: angular.material.IDialogService;
  private _q: angular.IQService;
  private _timeout: angular.ITimeoutService;
  private _dataEntityService: DataEntityService;
  private _dayNumberService: DayNumberService;
  private _languageService: LanguageService;
  private _notifyingService: NotifyingService;
  private _unitOfMeasureService: UnitOfMeasureService;

  private fertWeightNormalUnitName: string;
  private fertVolumeNormalUnitName: string;
  private fertVolumeAreaNormalUnitName: string;
  private fertWeightAreaNormalUnitName: string;
  private previousStartDate: Date;
  private previousEndDate: Date;

  constructor(
    $mdDialog: angular.material.IDialogService,
    $q: angular.IQService,
    $scope: angular.IScope,
    $timeout: angular.ITimeoutService,
    DataEntityService: DataEntityService,
    DayNumberService: DayNumberService,
    LanguageService: LanguageService,
    NotifyingService: NotifyingService,
    PermissionService: PermissionService,
    UnitOfMeasureService: UnitOfMeasureService,
  ) {
    super(
      $scope,
      PermissionService,
    );
    this.setEditPermission(ApplicationPrivileges.GroupNutrientApplicationFull);

    this._mdDialog = $mdDialog;
    this._q = $q;
    this._timeout = $timeout;
    this._dataEntityService = DataEntityService;
    this._dayNumberService = DayNumberService;
    this._languageService = LanguageService;
    this._notifyingService = NotifyingService;
    this._unitOfMeasureService = UnitOfMeasureService;

    this.entityManager = DataEntityService.manager;
    this.fertVolumeNormalUnitName = this._unitOfMeasureService.getUnitLabel('FertVolume', unitSizes.normal);
    this.fertVolumeAreaNormalUnitName = this._unitOfMeasureService.getUnitLabel('FertVolume/Area', unitSizes.normal);
    this.fertWeightNormalUnitName = this._unitOfMeasureService.getUnitLabel('Weight', unitSizes.normal);
    this.fertWeightAreaNormalUnitName = this._unitOfMeasureService.getUnitLabel('Weight/Area', unitSizes.normal);

    const localeToday = this._dayNumberService.convertBrowserDateTimeToLocaleDate();

    let localeCurrentSunday: Date;
    if (localeToday.getDay() == 0) {
      localeCurrentSunday = localeToday.clone();
    } else {
      localeCurrentSunday = localeToday.clone().moveToDayOfWeek(0, -1);
    }

    this.localeCurrentSundayDayNumber = this._dayNumberService.convertLocaleDateToLocaleDayNumber(localeCurrentSunday);
    this.startDate = localeCurrentSunday.clone().addWeeks(-4);
    this.endDate = localeCurrentSunday.clone().addWeeks(+4);
    this.unitLabel = this.byArea
      ? `${this.fertWeightAreaNormalUnitName} ${this._languageService.instant('COMMON.OR')} ${this.fertVolumeAreaNormalUnitName}`
      : `${this._languageService.instant('COMMON.TOTAL')} ${this.fertWeightNormalUnitName} ${this._languageService.instant(
          'COMMON.OR',
        )} ${this.fertVolumeNormalUnitName} ${this._languageService.instant('NUTR.APPLIED.FOR_WHOLE')}`;

    this.setWeekInfos();
  }

  public get hasDataChanges(): boolean {
    return this._dataEntityService.hasDataChanges;
  }

  $onChanges(changes) {
    if (changes.reloadCounter?.currentValue) {
      this.initialise();
    } else if (changes.saveCounter?.currentValue) {
      this.groupedWeeklyFertilisers.forEach((siteWeek) => {
        siteWeek.isSelected = false;
      });

      this.checkSiteWeeks();
    } else {
      // change is assetId or assetType
      // only both of them has beed set, we build weekly fertilisers
      if (this.assetId && this.assetType) {
        this.initialise();
      }
    }
  }

  public datepickerOpen() {
    this.previousStartDate = this.startDate.clone();
    this.previousEndDate = this.endDate.clone();
  }

  public changeDate(type: string): void {
    const promises = [] as angular.IPromise<void>[];

    if (this.hasDataChanges) {
      promises.push(this.openChangeDateConfirmDialog());
    }

    this._q.all(promises).then(
      () => {
        const duration = this.endDate.getTime() - this.startDate.getTime();

        if (duration < 0 || duration >= 9 * 7 * 24 * 60 * 60 * 1000) {
          if (type == 'start') {
            this.endDate = this.startDate.clone().addWeeks(8);
          }

          if (type == 'end') {
            this.startDate = this.endDate.clone().addWeeks(-8);
          }
        }

        this._dataEntityService.clear('SiteWeeklyFertiliser');
        this.setWeekInfos();
        this.initialise();
      },
      () => {
        this.startDate = this.previousStartDate;
        this.endDate = this.previousEndDate;
      },
    );
  }

  public setUnitLabel() {
    this.isSpinning = true;
    this._timeout(() => {
      this.isSpinning = false;
      this.byArea = !this.byArea;
      this.unitLabel = this.byArea
        ? `${this.fertWeightAreaNormalUnitName} ${this._languageService.instant('COMMON.OR')} ${this.fertVolumeAreaNormalUnitName}`
        : `${this._languageService.instant('COMMON.TOTAL')} ${this.fertWeightNormalUnitName} ${this._languageService.instant(
            'COMMON.OR',
          )} ${this.fertVolumeNormalUnitName} ${this._languageService.instant('NUTR.COMMON.FOR_THE_WHOLE_SITE')}`;
    }, 500);
  }

  private setWeekInfos() {
    const startDayNumber = this._dayNumberService.convertLocaleDateToLocaleDayNumber(this.startDate);
    const ms = this.endDate.getTime() - this.startDate.getTime();
    const numOfWeeks = Math.floor(ms / (7 * 24 * 60 * 60 * 1000));

    this.weekInfos = [];

    for (let i = 0; i <= numOfWeeks; i++) {
      const isCurrentSunday = startDayNumber + i * 7 == this.localeCurrentSundayDayNumber ? true : false;
      const isPreviousSunday = startDayNumber + (i - 1) * 7 == this.localeCurrentSundayDayNumber ? true : false;
      const isFutureSunday = startDayNumber + i * 7 > this.localeCurrentSundayDayNumber ? true : false;
      const weekInfo = {
        index: i,
        sundayDate: this.startDate.clone().addDays(i * 7),
        dayNumber: startDayNumber + i * 7,
        dayDisplayYMD: this._dayNumberService.convertDayNumberToYMD(startDayNumber + i * 7),
        isCurrentSunday: isCurrentSunday,
        isPreviousSunday: isPreviousSunday,
        isFutureSunday: isFutureSunday,
        cssStyle: isCurrentSunday ? 'currentSunday' : isFutureSunday ? 'futureSunday' : 'previousSunday',
        isSelected: false,
      } as WeekInfo;

      this.weekInfos.push(weekInfo);
    }
  }

  private initialise(preseveSelection: boolean = false): void {
    this.isSpinning = true;
    this.isDataReady = false;

    const promises = [] as angular.IPromise<void>[];

    if (this.assetType == 'group') {
      promises.push(this.getSites());
    } else {
      promises.push(this.getSite());
    }

    promises.push(this.fetchData());

    this._q
      .all(promises)
      .then(() => {
        this.getNutrientSettings().then(() => {
          this.rebuildWeeklyFertilisers(preseveSelection);
          this.createWeekTotalFertilisers();
          this.updateSiteInfoDescription();
          this.checkSiteWeeks();
          this.isDataReady = true;
        });
      })
      .finally(() => {
        this.isSpinning = false;
      });
  }

  private getSites(): angular.IPromise<void> {
    const tempSites = angular.copy(this.sites);
    const defer = this._q.defer<void>();
    const pred = breeze.Predicate.create('ParentAssetId', breeze.FilterQueryOp.Equals, this.assetId).and(
      breeze.Predicate.create('ChildAsset.Status', breeze.FilterQueryOp.Equals, 'Active'),
    );

    breeze.EntityQuery.from('SiteAssets')
      .withParameters({ accountId: this.accountId })
      .where(pred)
      .select('ChildAsset.AssetId, ChildAsset.Name')
      .using(this.entityManager)
      .execute()
      .then(
        (data: breeze.QueryResult) => {
          this.sites = [];
          data.results.forEach((siteAsset: any) => {
            const tempSite = tempSites.find((a) => a.id == siteAsset.ChildAsset_AssetId);
            this.sites.push({
              id: siteAsset.ChildAsset_AssetId,
              name: siteAsset.ChildAsset_Name,
              isExpanded: tempSite ? tempSite.isExpanded : true,
            } as SiteInfo);
          });
          defer.resolve();
        },
        () => {
          defer.reject();
        },
      );

    return defer.promise;
  }

  private getSite(): angular.IPromise<void> {
    const defer = this._q.defer<void>();

    breeze.EntityQuery.from('Asset')
      .withParameters({ assetId: this.assetId })
      .select('AssetId, Name')
      .using(this.entityManager)
      .execute()
      .then(
        (data: breeze.QueryResult) => {
          this.sites = [];
          data.results.forEach((result: any) => {
            this.sites.push({
              id: result.AssetId,
              name: result.Name,
              isExpanded: true,
            } as SiteInfo);
          });
          defer.resolve();
        },
        () => {
          defer.reject();
        },
      );

    return defer.promise;
  }

  private fetchData(): angular.IPromise<void> {
    const defer = this._q.defer<void>();
    const startDayNumber = this._dayNumberService.convertLocaleDateToLocaleDayNumber(this.startDate);
    const endDayNumber = this._dayNumberService.convertLocaleDateToLocaleDayNumber(this.endDate);

    breeze.EntityQuery.from('SiteWeeklyFertilisers')
      .withParameters({ assetId: this.assetId, type: this.assetType, startDayNum: startDayNumber, endDayNum: endDayNumber })
      .expand('Fertiliser, Fertiliser.Fertiliser')
      .orderBy('dayNumber', true)
      .using(this.entityManager)
      .execute(
        (data: breeze.QueryResult) => {
          this.weeklyFertilisers = data.results as SiteWeeklyFertiliser[];
          defer.resolve();
        },
        () => {
          defer.reject();
        },
      );

    return defer.promise;
  }

  private getNutrientSettings(): angular.IPromise<void> {
    const defer = this._q.defer<void>();

    const assetIds = this.assetType == 'group' ? [this.assetId] : this.sites.map((a) => a.id);

    breeze.EntityQuery.from('EffectiveSiteSettingsNutrient')
      .withParameters({ assetIds: assetIds, type: this.assetType })
      .using(this.entityManager)
      .execute(
        (data: breeze.QueryResult) => {
          this.settingsNutrients = data.results as SiteSettingsNutrients[];
          defer.resolve();
        },
        () => {
          defer.reject();
        },
      );

    return defer.promise;
  }

  private rebuildWeeklyFertilisers(preserveSelection: boolean = false) {
    const selectedSiteWeeks =
      preserveSelection && this.groupedWeeklyFertilisers
        ? this.groupedWeeklyFertilisers
            .filter((a) => a.isSelected)
            .map((a) => {
              return {
                assetId: a.siteId,
                startDateYMD: a.dayDisplayYMD,
              } as fuse.siteWeek;
            })
        : [];

    this.groupedWeeklyFertilisers = [];

    this.sites.forEach((site) => {
      this.weekInfos.forEach((weekinfo) => {
        const siteNutrientSetting = this.settingsNutrients.find(
          (a) => a.AssetId == site.id && a.EffectiveFromDay <= weekinfo.dayNumber && weekinfo.dayNumber <= a.EffectiveToDay,
        );

        this.groupedWeeklyFertilisers.push({
          siteId: site.id,
          siteArea: siteNutrientSetting.CropNutrientArea,
          week: weekinfo.index,
          dayNumber: weekinfo.dayNumber,
          dayDisplayYMD: weekinfo.dayDisplayYMD,
          isFutureSunday: weekinfo.isFutureSunday,
          siteWeekFertilisers: [],
          isSelected:
            selectedSiteWeeks.find((a) => a.assetId == site.id && a.startDateYMD == weekinfo.dayDisplayYMD) !== undefined,
        } as SiteWeekInfo);
      });
    });

    this.weeklyFertilisers.forEach((swf) => {
      const siteWeekInfo = this.groupedWeeklyFertilisers.find((a) => a.siteId == swf.AssetId && a.dayNumber == swf.dayNumber);

      if (siteWeekInfo) {
        const unitType = swf.Fertiliser.Fertiliser.Units == 'kg' ? 'Weight' : 'FertVolume';
        const unitByAreaType = `${unitType}/Area`;
        siteWeekInfo.siteWeekFertilisers.push({
          fertiliserId: swf.FertiliserId,
          fertiliserName: swf.Fertiliser.Name,
          unitType: unitType,
          unitByAreaType: unitByAreaType,
          unitLabel: this._unitOfMeasureService.getUnitLabel(unitType, unitSizes.normal),
          unitByAreaLabel: this._unitOfMeasureService.getUnitLabel(unitByAreaType, unitSizes.normal),
          appliedValueByArea: swf.FertiliserActual,
          appliedValue: swf.FertiliserActual == null ? null : swf.FertiliserActual * siteWeekInfo.siteArea,
          plannedValueByArea: swf.FertiliserPlanned,
          plannedValue: swf.FertiliserPlanned == null ? null : swf.FertiliserPlanned * siteWeekInfo.siteArea,
        } as WeekFertiliser);
      }
    });
    this.updateSiteInfoDescription();
  }

  public getSiteWeekInfo(site: SiteInfo, weekInfo: WeekInfo): SiteWeekInfo {
    if (this.groupedWeeklyFertilisers) {
      return this.groupedWeeklyFertilisers.find((a) => a.siteId == site.id && a.dayNumber == weekInfo.dayNumber);
    }
  }

  private createWeekTotalFertilisers(): void {
    this.weekInfos.forEach((weekInfo) => {
      weekInfo.weekFertilisers = [];
    });

    this.groupedWeeklyFertilisers.forEach((siteWeek) => {
      const weekInfo = this.weekInfos.find((a) => a.dayNumber == siteWeek.dayNumber);
      siteWeek.siteWeekFertilisers.forEach((siteWeekFertiliser) => {
        let weekFertiliser = weekInfo.weekFertilisers.find((a) => a.fertiliserId == siteWeekFertiliser.fertiliserId);
        if (weekFertiliser == null) {
          weekFertiliser = {
            fertiliserId: siteWeekFertiliser.fertiliserId,
            fertiliserName: siteWeekFertiliser.fertiliserName,
            plannedValue: siteWeekFertiliser.plannedValue ?? 0,
            appliedValue: siteWeekFertiliser.appliedValue ?? 0,
            unitType: siteWeekFertiliser.unitType,
            unitLabel: siteWeekFertiliser.unitLabel,
          } as WeekFertiliser;
          weekInfo.weekFertilisers.push(weekFertiliser);
        } else {
          weekFertiliser.appliedValue += siteWeekFertiliser.appliedValue ?? 0;
          weekFertiliser.plannedValue += siteWeekFertiliser.plannedValue ?? 0;
        }
      });
    });
  }

  // to differentiate from openAddFertiliserDialog
  public openSiteAddFertiliserDialog() {
    this._mdDialog
      .show({
        controller: AddSiteFertiliserDialogController,
        controllerAs: 'vm',
        templateUrl: 'src/app/pages/account/components/add-site-fertiliser-dialog.html',
        parent: angular.element(document.body),
        clickOutsideToClose: false,
        locals: {
          siteWeeks: this.groupedWeeklyFertilisers.filter((a) => a.isSelected),
        },
      })
      .then((res) => {
        if (res) {
          this.isSpinning = true;
          this.createWeekTotalFertilisers();
          this.updateSiteInfoDescription();

          if (this.hasDataChanges) {
            this._dataEntityService
              .saveChanges()
              .then(() => {
                this.checkSiteWeeks();
                this._languageService.showSaveSuccess;
              })
              .catch((err) => {
                this._languageService.whoops();
              })
              .finally(() => {
                this.isSpinning = false;
              });
          } else {
            this.checkSiteWeeks();
            this.isSpinning = false;
          }
        }
      });
  }

  public openRemoveFertiliserDialog() {
    const selectedSiteWeeks = this.groupedWeeklyFertilisers
      .filter((a) => a.isSelected)
      .map((a) => {
        return {
          assetId: a.siteId,
          startDateYMD: a.dayDisplayYMD,
        } as fuse.siteWeek;
      });

    this._mdDialog
      .show({
        controller: RemoveFertiliserDialogController,
        controllerAs: 'vm',
        templateUrl: 'src/app/pages/account/components/remove-fertiliser-dialog.html',
        parent: angular.element(document.body),
        clickOutsideToClose: false,
        locals: {
          selectedSiteWeeks: selectedSiteWeeks,
          counter: this.selectedSiteWeekCount,
        },
      })
      .then((res) => {
        if (res) {
          this.initialise(true);
        }
      });
  }

  public openApplyFertiliserDialog() {
    const selectedSiteWeeks = this.groupedWeeklyFertilisers
      .filter((a) => a.isSelected && !a.isFutureSunday)
      .map((a) => {
        return {
          assetId: a.siteId,
          startDateYMD: a.dayDisplayYMD,
        } as fuse.siteWeek;
      });

    this._mdDialog
      .show({
        controller: ApplyFertiliserDialogController,
        controllerAs: 'vm',
        templateUrl: 'src/app/pages/account/components/apply-fertiliser-dialog.html',
        parent: angular.element(document.body),
        clickOutsideToClose: false,
        locals: {
          selectedSiteWeeks: selectedSiteWeeks,
          siteWeekCount: this.selectedSiteWeekCount,
          weekCount: this.selectedWeekCount,
          type: this.assetType,
        },
      })
      .then((res) => {
        if (res) {
          this.initialise(true);
        }
      });
  }

  public isSunday(date: Date) {
    return date.getDay() === 0;
  }

  public isSaturday(date: Date) {
    return date.getDay() === 6;
  }

  public selectAllSiteWeeks(): void {
    if (this.isSelectAllSiteWeeks) {
      this.weekInfos.forEach((weekInfo) => {
        weekInfo.isSelected = true;
      });

      this.sites.forEach((site) => {
        site.isSelected = true;
      });

      this.groupedWeeklyFertilisers.forEach((siteWeek) => {
        siteWeek.isSelected = true;
      });
    } else {
      this.weekInfos.forEach((weekInfo) => {
        weekInfo.isSelected = false;
      });

      this.sites.forEach((site) => {
        site.isSelected = false;
      });

      this.groupedWeeklyFertilisers.forEach((siteWeek) => {
        siteWeek.isSelected = false;
      });
    }

    this.checkSiteWeeks();
  }

  public selectWeek(weekInfo: WeekInfo): void {
    if (weekInfo.isSelected) {
      this.groupedWeeklyFertilisers
        .filter((a) => a.dayNumber == weekInfo.dayNumber)
        .forEach((siteWeek) => {
          siteWeek.isSelected = true;
        });
    } else {
      this.groupedWeeklyFertilisers
        .filter((a) => a.dayNumber == weekInfo.dayNumber)
        .forEach((siteWeek) => {
          siteWeek.isSelected = false;
        });
    }
    this.checkSiteWeeks();
  }

  public selectSite(siteInfo: SiteInfo): void {
    if (siteInfo.isSelected) {
      this.groupedWeeklyFertilisers
        .filter((a) => a.siteId == siteInfo.id)
        .forEach((siteWeek) => {
          siteWeek.isSelected = true;
        });
    } else {
      this.groupedWeeklyFertilisers
        .filter((a) => a.siteId == siteInfo.id)
        .forEach((siteWeek) => {
          siteWeek.isSelected = false;
        });
    }
    this.checkSiteWeeks();
  }

  public selectSiteWeek(): void {
    this.checkSiteWeeks();
  }

  private checkSiteWeeks(): void {
    this.isSelectAllSiteWeeks = this.groupedWeeklyFertilisers.every((a) => a.isSelected);

    if (this.groupedWeeklyFertilisers.length) {
      this.sites.forEach((site) => {
        const siteWeeks = this.groupedWeeklyFertilisers.filter((a) => a.siteId == site.id);

        site.isSelected = siteWeeks.every((b) => b.isSelected) ? true : siteWeeks.some((b) => b.isSelected) ? null : false;
      });

      this.weekInfos.forEach((weekInfo) => {
        const weeks = this.groupedWeeklyFertilisers.filter((a) => a.dayNumber == weekInfo.dayNumber);

        weekInfo.isSelected = weeks.every((b) => b.isSelected) ? true : weeks.some((b) => b.isSelected) ? null : false;
      });
    }

    const selectedSiteWeeks = this.groupedWeeklyFertilisers
      .filter((a) => a.isSelected)
      .map((a) => {
        return {
          siteId: a.siteId,
          dayNumber: a.dayNumber,
          anyAppliableFertiliser: !a.isFutureSunday && a.siteWeekFertilisers.some((b) => b.appliedValue != null || b.plannedValue != null),
          anyEmptyFeritliser: a.siteWeekFertilisers.some((b) => !b.appliedValue && !b.plannedValue),
        } as SiteWeekInfo;
      });

    this.isSetButtonEnabled = !this.hasDataChanges;
    this.isRemoveButtonEnabled = !this.hasDataChanges && selectedSiteWeeks.some((a) => a.anyEmptyFeritliser);
    this.isApplyButtonEnabled = !this.hasDataChanges && selectedSiteWeeks.some((a) => a.anyAppliableFertiliser);

    this.selectedSiteWeekCount = selectedSiteWeeks.length;
    this.selectedSiteCount = selectedSiteWeeks.filter((v, i, a) => a.findIndex((b) => b.siteId == v.siteId) == i).length;
    this.selectedWeekCount = selectedSiteWeeks.filter((v, i, a) => a.findIndex((b) => b.dayNumber == v.dayNumber) == i).length;
  }

  public changeValue(site: SiteInfo, weekInfo: WeekInfo, fertiliser: WeekFertiliser, type: string): void {
    let weekFertiliser: WeekFertiliser;

    if (this.byArea) {
      const siteWeek = this.groupedWeeklyFertilisers.find((a) => a.siteId == site.id && a.dayNumber == weekInfo.dayNumber);

      weekFertiliser = siteWeek.siteWeekFertilisers.find((a) => a.fertiliserId == fertiliser.fertiliserId);

      if (type == 'planned') {
        weekFertiliser.plannedValue =
          weekFertiliser.plannedValueByArea
            ? weekFertiliser.plannedValueByArea * siteWeek.siteArea
            : null;
      }

      if (type == 'applied') {
        weekFertiliser.appliedValue =
          weekFertiliser.appliedValueByArea
            ? weekFertiliser.appliedValueByArea * siteWeek.siteArea
            : null;
      }
    } else {
      const siteWeek = this.groupedWeeklyFertilisers.find((a) => a.siteId == site.id && a.dayNumber == weekInfo.dayNumber);

      weekFertiliser = siteWeek.siteWeekFertilisers.find((a) => a.fertiliserId == fertiliser.fertiliserId);

      if (type === 'planned') {
        weekFertiliser.plannedValueByArea =
          weekFertiliser.plannedValue
            ? weekFertiliser.plannedValue / siteWeek.siteArea
            : null;
      }

      if (type === 'applied') {
        weekFertiliser.appliedValueByArea =
          weekFertiliser.appliedValueByArea
            ? weekFertiliser.appliedValue / siteWeek.siteArea
            : null;
      }
    }

    //update entity
    this.updateEntity(
      site.id,
      weekInfo.dayNumber,
      fertiliser.fertiliserId,
      weekFertiliser.plannedValueByArea,
      weekFertiliser.appliedValueByArea,
    );

    this.createWeekTotalFertilisers();
    this.checkSiteWeeks();

    this._notifyingService.notify(NotifyEvents.Group.NutrientApplication);
  }

  private updateEntity(
    siteId: number,
    dayNumber: number,
    fertiliserId: number,
    plannedValueByArea: number,
    appliedValueByArea: number,
  ): void {
    const swfQuery = breeze.EntityQuery.from('SiteWeeklyFertilisers').toType('SiteWeeklyFertiliser');
    const swfEntities = this.entityManager.executeQueryLocally(swfQuery) as SiteWeeklyFertiliser[]; // query the cache (synchronous)
    const swfEntity = swfEntities.find((a) => a.AssetId == siteId && a.dayNumber == dayNumber && a.FertiliserId == fertiliserId);

    if (swfEntity != null) {
      swfEntity.FertiliserPlanned = plannedValueByArea;
      swfEntity.FertiliserActual = appliedValueByArea;
    } else {
      const swf = {
        AssetId: siteId,
        dayNumber: dayNumber,
        FertiliserId: fertiliserId,
        FertiliserPlanned: plannedValueByArea,
        FertiliserActual: appliedValueByArea,
      } as SiteWeeklyFertiliser;
      const swfType = this.entityManager.metadataStore.getEntityType('SiteWeeklyFertiliser') as breeze.EntityType;
      const swfEntity = swfType.createEntity(swf) as SiteWeeklyFertiliser;

      this.entityManager.addEntity(swfEntity);
    }
  }

  private deleteEntity(siteId: number, dayNumber: number, fertiliserId: number): void {
    const pred: breeze.Predicate = breeze.Predicate.create('AssetId', '==', siteId)
      .and('dayNumber', '==', dayNumber)
      .and('FertiliserId', '==', fertiliserId);
    const query = breeze.EntityQuery.from('SiteWeeklyFertiliser').where(pred).toType('SiteWeeklyFertiliser');
    const swfEntities: breeze.Entity[] = this.entityManager.executeQueryLocally(query) as SiteWeeklyFertiliser[];

    if (swfEntities.length == 1) {
      swfEntities[0].entityAspect.setDeleted();
    }
  }

  public expandAllSites(): void {
    this.isAllExpanded = !this.isAllExpanded;
    if (this.isAllExpanded) {
      this.sites.forEach((site) => {
        site.isExpanded = true;
      });
    } else {
      this.sites.forEach((site) => {
        site.isExpanded = false;
      });
    }
  }

  public expandSite(site: SiteInfo): void {
    site.isExpanded = !site.isExpanded;
    if (this.sites.every((a) => a.isExpanded)) {
      this.isAllExpanded = true;
    } else {
      this.isAllExpanded = false;
    }
  }

  private updateSiteInfoDescription(): void {
    this.groupedWeeklyFertilisers.forEach((site) => {
      if (!site.siteWeekFertilisers.length) {
        site.description = null;
      } else if (site.siteWeekFertilisers.length == 1) {
        site.description = site.siteWeekFertilisers[0].fertiliserName;
      } else if (site.siteWeekFertilisers.length > 1) {
        const fertiliser = site.siteWeekFertilisers.reduce((previous, current) => {
          if (previous.fertiliserName < current.fertiliserName) {
            return previous;
          } else {
            return current;
          }
        });
        site.description = this._languageService.instant('COMMON.AND_MORE', {
          first: fertiliser.fertiliserName,
          more: site.siteWeekFertilisers.length - 1,
        });
      }
    });
  }

  private openChangeDateConfirmDialog(): angular.IPromise<void> {
    const defer = this._q.defer<void>();

    this._mdDialog
      .show({
        controller: ChangeDateConfirmDialogController,
        controllerAs: 'vm',
        templateUrl: 'src/app/pages/account/components/change-date-confirm-dialog.html',
        parent: angular.element(document.body),
        clickOutsideToClose: false,
      })
      .then(
        (res) => {
          if (res) {
            defer.resolve();
          } else {
            defer.reject();
          }
        },
        () => {
          defer.reject();
        },
      );

    return defer.promise;
  }
}

angular.module('app.account').component('nutrientApplication', new NutrientApplicationComponent());
