import * as angular from 'angular';
import { LanguageService } from '@services/language.service';
import { SWANConstants } from '@common/SWANConstants';
import { CommonHelper } from '@common/helpers/CommonHelper';

class DashboardHealthIndexTableComponent implements angular.IComponentOptions {
  bindings = {
    healthIndexes: '<',
    reloadCount: '<',
    reload: '&',
    itemChanged: '&',
  };
  controller = DashboardHealthIndexTableController;
  controllerAs = 'vm';
  templateUrl = 'src/app/pages/account/views/dashboard/dashboard-health-index-table.html';
}

class DashboardHealthIndexTableController {
  private _http: angular.IHttpService;
  private _languageService: LanguageService;

  private reload: Function;
  private itemChanged: Function;
  public healthIndexes = [] as fuse.dashboardAssetObsHealthIndexDto[];
  public dataProviders: fuse.dataProviderDto[];
  public isAnyChange = false;

  constructor(
    $http: angular.IHttpService,
    LanguageService: LanguageService,
  ) {
    this._http = $http;
    this._languageService = LanguageService;
    this.dataProviders = SWANConstants.dataProviders;
  }

  $onChanges(changes) {
    if (!changes.healthIndexes?.currentValue.length) {
      this.isAnyChange = false;
    }
  }

  public changeReliability(item: fuse.dashboardAssetObsHealthIndexDto, status: string): void {
    item.isChanged = true;

    if (status == 'default') {
      item.reliable = null;
    } else if (status == 'reliable') {
      item.reliable = true;
    } else if (status == 'unreliable') {
      item.reliable = false;
    }

    this.isAnyChange = true;
    this.itemChanged();
  }

  public rejectChanges(): void {
    this.reload({ isSaved: false });
  }

  public saveChanges(): void {
    const changedObs = this.healthIndexes.filter((a) => a.isChanged);

    // Fetch group health readings, soil moisture and water usages
    this._http.post(CommonHelper.getApiUrl('dashboard/updateHealthIndexes'), JSON.stringify(changedObs)).then((res) => {
      if (res) {
        changedObs.forEach((a) => {
          a.isChanged = false;
        });

        this.isAnyChange = false;
        this._languageService.showSaveSuccess();
        this.reload({ isSaved: true, changedObs: changedObs });
      } else {
        this._languageService.whoops();
      }
    });
  }
}

angular.module('app.account').component('dashboardHealthIndexTable', new DashboardHealthIndexTableComponent());
